import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {catchError, finalize} from 'rxjs/operators';
import {EMPTY, Subscription} from 'rxjs';
import {RulesService} from '../../rules-module/rules.service';
import {IRule} from '../../rules-module/rules.models';
import {ActionQualifier, DeliveryStatuses, NotificationsFilter} from '../notifications.models';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-moost-notifications-filter',
  templateUrl: './moost-notifications-filter.component.html',
  styleUrls: ['./moost-notifications-filter.component.scss']
})

export class MoostNotificationsFilterComponent implements OnInit, OnDestroy {
  @Input() filter: NotificationsFilter;
  @Output() applyFilterChange: EventEmitter<NotificationsFilter> = new EventEmitter<NotificationsFilter>()
  filterForm: FormGroup;
  rules: IRule[];
  ruleIdsChoice: string[];
  deliveryStatusesChoice: string[] = Object.keys(DeliveryStatuses);
  interactionsChoice: string[] = Object.keys(ActionQualifier);
  isLoadingRules: boolean = false;
  maxDate: Date = moment().endOf('day').toDate();
  private loadRulesSubscription: Subscription;
  private valueChangesSubscription: Subscription;

  constructor(private rulesService: RulesService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.buildForm(this.filter);
    this.loadRules();
  }

  ngOnDestroy(): void {
    this.loadRulesSubscription?.unsubscribe();
    this.valueChangesSubscription?.unsubscribe();
  }

  clearFilter(): void {
    this.resetForm(this.filterForm);
  }

  protected getRule(ruleId: string): IRule {
    return this.rules.find(rule => rule.id === ruleId);
  }

  protected getRuleIdsSortedByName(): string[] {
    return this.rules
      ?.sort((a: IRule, b: IRule) => a.name.localeCompare(b.name))
      ?.map((rule: IRule) => rule.id);
  }

  private setFilter(filter: NotificationsFilter): void {
    this.applyFilterChange.emit(filter);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          s: filter.startTimestampMillis,
          e: filter.endTimestampMillis,
          building: filter.customerBuildingIds?.join(","),
          rule: filter.ruleIds?.join(","),
          deliveryStatus: filter.deliveryStatuses?.join(","),
          interaction: filter.interactions?.join(","),
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private loadRules(): void {
    this.filterForm.get("ruleIds").disable();
    this.isLoadingRules = true;
    this.loadRulesSubscription = this.rulesService.getAllRules().pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoadingRules = false)
    ).subscribe((rules: IRule[]): void => {
      this.rules = rules;
      this.ruleIdsChoice = rules.map(rule => rule.id);
      this.filterForm.get("ruleIds").enable();
    });
  }

  private buildForm(filter: NotificationsFilter): void {
    this.filterForm = new FormGroup({
      customerBuildingIds: new FormControl<string>(filter?.customerBuildingIds?.join(',')),
      ruleIds: new FormControl<string[]>(filter?.ruleIds),
      deliveryStatuses: new FormControl<string[]>(filter?.deliveryStatuses),
      interactions: new FormControl<string[]>(filter?.interactions),
      startTimestampMillis: new FormControl(new Date(Number(filter?.startTimestampMillis)), [Validators.required]),
      endTimestampMillis: new FormControl(new Date(Number(filter?.endTimestampMillis)), [Validators.required]),
    });
    this.filterForm.get("ruleIds").disable();
    this.valueChangesSubscription = this.filterForm.valueChanges.subscribe(() => {
      if (this.filterForm.valid && this.filterForm.get("ruleIds").enabled) {
        this.setFilter(this.buildNotificationsFilter(this.filterForm));
      }
    });
  }

  private resetForm(filterForm: FormGroup): void {
    filterForm.get("customerBuildingIds").reset();
    filterForm.get("ruleIds").reset();
    filterForm.get("deliveryStatuses").reset();
    filterForm.get("interactions").reset();
  }

  private buildNotificationsFilter(filterForm: FormGroup): NotificationsFilter {
    return new NotificationsFilter(
      filterForm.get("startTimestampMillis").value.valueOf(),
      moment(filterForm.get("endTimestampMillis").value.valueOf()).endOf('day').toDate().getTime(),
      filterForm.get("customerBuildingIds").value?.split(","),
      filterForm.get("ruleIds").value,
      this.rules,
      filterForm.get("deliveryStatuses").value,
      filterForm.get("interactions").value
    );
  }
}
