import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {AuthTokenService} from './auth-token-module/auth-token.service';
import {NgcCookieConsentService, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import {DOCUMENT} from '@angular/common';
import {environment} from '../environments/environment';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  private breakpointObserver = inject(BreakpointObserver);
  private authTokenService = inject(AuthTokenService);
  private cookieConsentService = inject(NgcCookieConsentService);
  private matIconReg = inject(MatIconRegistry);
  private document = inject<Document>(DOCUMENT);

  readonly title: string = 'MOOST Recommender Platform';
  cookieConsentStatusChangeSubscription: Subscription;
  isHandset$: Observable<boolean>;

  constructor() {
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(
        map((result: BreakpointState) => result.matches),
        shareReplay()
      );
  }

  isAuthenticated(): boolean {
    return this.authTokenService.isAuthenticated();
  }

  ngOnInit(): void {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
    if (this.cookieConsentService.hasConsented()) {
      this.initHotjar();
      this.initGoogleTagManager();
      this.cookieConsentService.toggleRevokeButton(false);
    }

    this.cookieConsentStatusChangeSubscription = this.cookieConsentService.statusChange$.subscribe({
      next: (event: NgcStatusChangeEvent): void => {
        if (event.status == "allow") {
          this.initHotjar();
          this.initGoogleTagManager();
          this.cookieConsentService.close(false);
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.cookieConsentStatusChangeSubscription?.unsubscribe();
  }

  private initGoogleTagManager(): void {
    const gaScript: HTMLScriptElement = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${environment.gtmId}`);

    const gaScript2: HTMLScriptElement = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${environment.gtmId}\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  private initHotjar(): void {
    ((h, o: Document, t: string, j: string, a, r): void => {
      h.hj =
        h.hj ||
        function (): void {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = {hjid: environment.hotjarId, hjsv: 6};
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window as any, this.document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
}
