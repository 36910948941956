import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MoostUserMenuComponent} from './moost-user-menu/moost-user-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {RouterModule} from '@angular/router';
import {MoostConfirmDialogComponent} from './moost-confirm-dialog/moost-confirm-dialog.component';
import {EnumSelectPipe} from './enum.pipe';
import {MatDialogModule} from '@angular/material/dialog';
import {MoostProgressIndicatorComponent} from './moost-progress-indicator/moost-progress-indicator.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AuthTokenModule} from "../auth-token-module/auth-token.module";
import {SharedRoutingModule} from './shared-routing.module';
import {MatTooltip} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    MoostUserMenuComponent,
    MoostConfirmDialogComponent,
    MoostProgressIndicatorComponent,
    EnumSelectPipe
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    AuthTokenModule,
    MatTooltip
  ],
  exports: [
    MoostUserMenuComponent,
    MoostProgressIndicatorComponent,
    RouterModule,
    EnumSelectPipe
  ]
})
export class SharedModule {
}
