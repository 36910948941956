import {TermStructure} from './term-structure';
import {TermItem} from './term-item';
import {TermType} from './term-type';

export class AttributeAccessor {
  static readonly NAMES: string[] = [];
  static readonly DEVICE_NAME: string = AttributeAccessor.register('DeviceName');
  static readonly DEVICE_ID: string = AttributeAccessor.register('DeviceId');
  static readonly TIMESTAMP: string = AttributeAccessor.register('Timestamp');
  static readonly FORECAST_TIMESTAMP: string = AttributeAccessor.register('ForecastTimestamp');
  static readonly VALUE: string = AttributeAccessor.register('Value');
  static readonly FIRST: string = AttributeAccessor.register('First');
  static readonly LAST: string = AttributeAccessor.register('Last');
  static readonly GROUP_KEY_DEVICE_NAME: string = AttributeAccessor.register('GroupKeyDeviceName');
  static readonly GROUP_KEY_DEVICE_ID: string = AttributeAccessor.register('GroupKeyDeviceId');
  static readonly GROUP_VALUE: string = AttributeAccessor.register('GroupValue');
  static readonly ID: string = AttributeAccessor.register('Id');
  static readonly REGISTER_TIMESTAMP: string = AttributeAccessor.register('RegistrationTimestamp');
  static readonly DEVICE_TYPES: string = AttributeAccessor.register('DeviceTypes');
  static readonly ECONOMICAL_MOTIVATION_SCORE: string = AttributeAccessor.register('EconomicalMotivationScore');
  static readonly ECOLOGICAL_MOTIVATION_SCORE: string = AttributeAccessor.register('EcologicalMotivationScore');
  static readonly SELF_SUFFICIENCY_MOTIVATION_SCORE: string = AttributeAccessor.register('SelfSufficiencyMotivationScore');
  static readonly MULTI_PERSON_SCORE: string = AttributeAccessor.register('MultiPersonScore');

  static register(name: string): string {
    AttributeAccessor.NAMES.push(name);
    return name;
  }

  static getAllowedAccessors(structure: TermStructure): string[] {
    switch (structure) {
      case TermStructure.SCALAR:
        return [
          AttributeAccessor.DEVICE_NAME,
          AttributeAccessor.DEVICE_ID,
          AttributeAccessor.TIMESTAMP,
          AttributeAccessor.FORECAST_TIMESTAMP,
          AttributeAccessor.VALUE,
          AttributeAccessor.ID,
          AttributeAccessor.REGISTER_TIMESTAMP,
          AttributeAccessor.DEVICE_TYPES,
          AttributeAccessor.ECONOMICAL_MOTIVATION_SCORE,
          AttributeAccessor.ECOLOGICAL_MOTIVATION_SCORE,
          AttributeAccessor.SELF_SUFFICIENCY_MOTIVATION_SCORE,
          AttributeAccessor.MULTI_PERSON_SCORE,
        ];
      case TermStructure.VECTOR:
        return [
          AttributeAccessor.DEVICE_NAME,
          AttributeAccessor.DEVICE_ID,
          AttributeAccessor.TIMESTAMP,
          AttributeAccessor.FORECAST_TIMESTAMP,
          AttributeAccessor.VALUE,
          AttributeAccessor.FIRST,
          AttributeAccessor.LAST,
        ];
      case TermStructure.GROUPED_SCALAR:
        return [
          AttributeAccessor.DEVICE_NAME,
          AttributeAccessor.DEVICE_ID,
          AttributeAccessor.TIMESTAMP,
          AttributeAccessor.FORECAST_TIMESTAMP,
          AttributeAccessor.VALUE,
          AttributeAccessor.GROUP_KEY_DEVICE_NAME,
          AttributeAccessor.GROUP_KEY_DEVICE_ID,
          AttributeAccessor.GROUP_VALUE,
        ];
      case TermStructure.GROUPED_VECTOR:
        return [
          AttributeAccessor.DEVICE_NAME,
          AttributeAccessor.DEVICE_ID,
          AttributeAccessor.TIMESTAMP,
          AttributeAccessor.FORECAST_TIMESTAMP,
          AttributeAccessor.VALUE,
          AttributeAccessor.GROUP_KEY_DEVICE_NAME,
          AttributeAccessor.GROUP_KEY_DEVICE_ID,
          AttributeAccessor.FIRST,
          AttributeAccessor.LAST,
        ];
      default:
        return [];
    }
  }

  static getResultingType(accessor: string, item: TermItem): TermType {
    switch (accessor) {
      case AttributeAccessor.DEVICE_NAME:
      case AttributeAccessor.DEVICE_ID:
      case AttributeAccessor.GROUP_KEY_DEVICE_NAME:
      case AttributeAccessor.GROUP_KEY_DEVICE_ID:
      case AttributeAccessor.ID:
      case AttributeAccessor.DEVICE_TYPES:
        return TermType.TEXT;
      case AttributeAccessor.TIMESTAMP:
      case AttributeAccessor.FORECAST_TIMESTAMP:
      case AttributeAccessor.REGISTER_TIMESTAMP:
        return TermType.TIME;
      case AttributeAccessor.VALUE:
      case AttributeAccessor.ECONOMICAL_MOTIVATION_SCORE:
      case AttributeAccessor.ECOLOGICAL_MOTIVATION_SCORE:
      case AttributeAccessor.SELF_SUFFICIENCY_MOTIVATION_SCORE:
      case AttributeAccessor.MULTI_PERSON_SCORE:
        return TermType.NUMBER;
      case AttributeAccessor.FIRST:
        return item.type;
      case AttributeAccessor.LAST:
        return item.type;
      case AttributeAccessor.GROUP_VALUE:
        return item.type;
      default:
        throw Error(`Unsupported attribute accessor: ${accessor}`)
    }
  }

  static getResultingStructure(accessor: string, item: TermItem): TermStructure {
    if (item.isScalar()) {
      if (accessor === AttributeAccessor.DEVICE_TYPES) {
        return TermStructure.VECTOR;
      }
      return TermStructure.SCALAR;
    } else if (item.isVector()) {
      if (accessor === AttributeAccessor.FIRST || accessor === AttributeAccessor.LAST) {
        return TermStructure.SCALAR
      }
      return TermStructure.VECTOR;
    } else if (item.isGroupedScalar()) {
      return TermStructure.VECTOR;
    } else if (item.isGroupedVector()) {
      if (accessor === AttributeAccessor.FIRST || accessor === AttributeAccessor.LAST) {
        return TermStructure.GROUPED_SCALAR;
      } else if (accessor === AttributeAccessor.GROUP_KEY_DEVICE_ID || accessor === AttributeAccessor.GROUP_KEY_DEVICE_NAME) {
        return TermStructure.VECTOR;
      } else {
        return TermStructure.GROUPED_VECTOR;
      }
    }
    throw Error(`Unsupported attribute accessor: ${accessor} on ${item.getStructureType()}`);
  }

}

