<div class="filter-bar">
  <form [formGroup]="filterForm" class="rules-filter">

    <mat-form-field appearance="outline" class="search-text-field">
      <mat-label>Search for Text</mat-label>
      <input formControlName="searchText" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="statuses-field">
      <mat-label>Status</mat-label>
      <mat-select formControlName="statuses" multiple>
        <mat-option *ngFor="let status of statusesChoice" [value]="status">
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="restrictions-field">
      <mat-label>Restriction</mat-label>
      <mat-select formControlName="restrictions" multiple>
        <mat-option *ngFor="let restriction of restrictionsChoice" [value]="restriction">
          {{ restriction }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="triggers-field">
      <mat-label>Trigger</mat-label>
      <mat-select formControlName="triggers" multiple>
        <mat-option *ngFor="let trigger of triggersChoice" [value]="trigger">
          {{ trigger }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="categories-field">
      <mat-label>Category</mat-label>
      <mat-select formControlName="categories" multiple>
        <mat-option *ngFor="let category of categoriesChoice" [value]="category">
          {{ category }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="origins-field">
      <mat-label>Origin</mat-label>
      <mat-select formControlName="origins" multiple>
        <mat-option *ngFor="let origin of originsChoice" [value]="origin">
          {{ origin }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="event-types-field">
      <mat-label>Event Types</mat-label>
      <mat-select formControlName="eventTypes" multiple>
        <mat-option *ngFor="let eventType of eventTypesChoice" [value]="eventType">
          {{ eventType }}
        </mat-option>
      </mat-select>
      <mat-spinner *ngIf="isLoadingEventTypes" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
    </mat-form-field>

    <mat-form-field appearance="outline" class="source-types-field">
      <mat-label>Source Types</mat-label>
      <mat-select formControlName="sourceTypes" multiple>
        <mat-option *ngFor="let sourceType of sourceTypesChoice" [value]="sourceType">
          {{ sourceType }}
        </mat-option>
      </mat-select>
      <mat-spinner *ngIf="isLoadingSourceTypes" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
    </mat-form-field>
  </form>

  <button (click)="clearFilter()" color="gray" mat-icon-button
          matTooltip="Clear filter">
    <mat-icon>delete_outline</mat-icon>
  </button>
</div>
