import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {MoostHeaderComponent} from './moost-header/moost-header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {LayoutModule} from '@angular/cdk/layout';
import {MatListModule} from '@angular/material/list';
import {MoostNavigationComponent} from './moost-navigation/moost-navigation.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {EventsModule} from './events-module/events.module';
import {NotificationsModule} from './notifications-module/notifications.module';
import {UsersModule} from './users-module/users.module';
import {JwtModule, JwtModuleOptions} from '@auth0/angular-jwt';
import {RulesModule} from './rules-module/rules.module';
import {NgxEchartsModule} from 'ngx-echarts';
import {BuildingsModule} from './buildings-module/buildings.module';
import {SharedModule} from './shared-module/shared.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ApmModule, ApmService} from '@elastic/apm-rum-angular';
import {SettingsModule} from './settings-module/settings.module';
import {MatStepperModule} from '@angular/material/stepper';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {AuthTokenModule} from './auth-token-module/auth-token.module';
import {CacheLocation} from '@auth0/auth0-spa-js/src/global';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

const JWT_Module_Options: JwtModuleOptions = {
  config: {}
};

const allowedListWithBearerToken: string[] = environment.envName === 'local' ? [
  `${environment.apiUrl}/*`,
  `http://localhost:8080/*`,
] : [`${environment.apiUrl}/*`];

const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": environment.domain
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "rgba(0,0,0,0.7)",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#2dbe60",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "opt-in",
  "content": {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": "https://www.moost.io/privacy",
    "policy": "Cookie Policy"
  }
};

@NgModule({ declarations: [
        AppComponent,
        MoostHeaderComponent,
        MoostNavigationComponent
    ],
    exports: [
        MoostNavigationComponent
    ],
    bootstrap: [AppComponent], imports: [ApmModule,
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
        NgcCookieConsentModule.forRoot(cookieConfig),
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        LayoutModule,
        MatListModule,
        EventsModule,
        NotificationsModule,
        RulesModule,
        BuildingsModule,
        UsersModule,
        JwtModule.forRoot(JWT_Module_Options),
        SharedModule,
        MatSnackBarModule,
        SettingsModule,
        MatStepperModule,
        MatRadioModule,
        MatSlideToggleModule,
        AuthTokenModule,
        AuthModule.forRoot({
            ...environment.auth0,
            cacheLocation: environment.auth0.cacheLocationType as CacheLocation,
            httpInterceptor: {
                allowedList: allowedListWithBearerToken,
            },
        }),
        AppRoutingModule // must be last entry in the import list, because this one has the default route
    ], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        ApmService, {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor(apmService: ApmService) {
    const apmConfig = environment['apm'];
    if (apmConfig) {
      apmService.init({
        serviceName: 'fe-rp-admin',
        serverUrl: apmConfig?.serverUrl,
        distributedTracingOrigins: apmConfig?.distributedTracingOrigins,
        environment: (environment.production ? 'prod' : 'dev')
      });
    }
  }
}
