export const BUILDING_FILTER_STATUS_ACTIVE: string = 'ACTIVE';
export const BUILDING_FILTER_STATUS_INACTIVE: string = 'INACTIVE';

export class BuildingsFormFilter {
  readonly id: string;
  readonly location: string;
  readonly status: string[];
  readonly eventTypes: string[];
  readonly eventSources: string[];

  constructor(id: string = null, location: string = null, status: string[] = null, types: string[] = null,
              sources: string[] = null) {
    this.id = id;
    this.location = location;
    this.status = status;
    this.eventTypes = types;
    this.eventSources = sources;
  }
}
