import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MoostEventsOverviewComponent} from './moost-events-overview/moost-events-overview.component';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {EventsRoutingModule} from './events-routing.module';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MoostEventsTableComponent} from './moost-events-table/moost-events-table.component';
import {SharedModule} from '../shared-module/shared.module';
import {MatListModule} from '@angular/material/list';
import {MoostChartsModule} from "../moost-charts/moost-charts.module";
import {NotificationsModule} from "../notifications-module/notifications.module";
import {MoostEventsGroupByChartComponent} from './moost-events-group-by-chart/moost-events-group-by-chart.component';
import {MoostEventsFilterComponent} from './moost-events-filter/moost-events-filter.component';


@NgModule({
  declarations: [
    MoostEventsFilterComponent,
    MoostEventsOverviewComponent,
    MoostEventsTableComponent,
    MoostEventsGroupByChartComponent,
  ],
  imports: [
    MatMomentDateModule,
    EventsRoutingModule,
    CommonModule,
    MatDatepickerModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatListModule,
    MoostChartsModule,
    NotificationsModule
  ],
  exports: [
    MoostEventsOverviewComponent,
    MoostEventsTableComponent
  ]
})
export class EventsModule {
}
