import {Component, OnInit} from '@angular/core';
import * as moment from 'moment/moment';
import {ActivatedRoute, Params} from '@angular/router';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {DeliveryStatuses, NotificationsFilter} from '../notifications.models';

@Component({
  selector: 'app-moost-notifications-overview',
  templateUrl: './moost-notifications-overview.component.html',
  styleUrls: ['./moost-notifications-overview.component.scss']
})
export class MoostNotificationsOverviewComponent implements OnInit {
  initFilter: NotificationsFilter;
  filter: NotificationsFilter;

  constructor(private headerService: MoostHeaderService, private route: ActivatedRoute) {
    this.headerService.setTitle('Notifications');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams: Params) => {
      const paramStartTimestamp: number = queryParams['s'];
      const paramEndTimestamp: number = queryParams['e'];
      this.initFilter = new NotificationsFilter(
        paramStartTimestamp || moment().startOf('day').subtract({days: 14}).toDate().getTime(),
        paramEndTimestamp || moment().endOf('day').toDate().getTime(),
        queryParams['building']?.split(","),
        queryParams['rule']?.split(","),
        null,
        (queryParams['deliveryStatus'] ? queryParams['deliveryStatus'].split(",") : [DeliveryStatuses.DELIVERED.toString()]),
        queryParams['interaction']?.split(",")
      );
    });
  }

  applyFilter($event: NotificationsFilter): void {
    this.filter = $event;
  }
}
