import {languages} from 'monaco-editor';
import LanguageConfiguration = languages.LanguageConfiguration;
import IAutoClosingPairConditional = languages.IAutoClosingPairConditional;
import CharacterPair = languages.CharacterPair;

export class RuleLanguageConfiguration implements LanguageConfiguration {
  autoClosingPairs: IAutoClosingPairConditional[] = [

    {open: '\'', close: '\'', notIn: ['string']},
    {open: '"', close: '"', notIn: ['string']}
  ];
  brackets: CharacterPair[] = [
    ['(', ')']
  ];
}
