import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {SettingsService} from '../settings.service';
import {catchError} from 'rxjs/operators';
import {IFulfillmentPreference} from '../fulfillment.model';

export class SettingsDatasource implements DataSource<any> {
  private fulfillmentPreferencesSubject = new BehaviorSubject<any[]>([]);
  public fulfillments$ = this.fulfillmentPreferencesSubject.asObservable();

  constructor(private settingsService: SettingsService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.fulfillmentPreferencesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.fulfillmentPreferencesSubject.complete();
  }

  loadFulfillmentPreferences(): void {
    this.settingsService.getFulfillmentPreference()
      .pipe(
        catchError(() => null)
      ).subscribe((fulfillmentPreferences: IFulfillmentPreference[]): void => {
      this.fulfillmentPreferencesSubject.next(fulfillmentPreferences);
    });
  }

  clear(): void {
    this.fulfillmentPreferencesSubject.next([]);
  }
}
