import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-sync-form-field',
  templateUrl: './sync-form-field.component.html',
  styleUrl: './sync-form-field.component.scss'
})
export class SyncFormFieldComponent {
  @Input() target: AbstractControl<string | number | boolean>;
  @Input() source: string | number | boolean;
  protected readonly String = String;
}
