import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MoostSettingsOverviewComponent} from './moost-settings-overview/moost-settings-overview.component';
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from '../shared-module/shared.module';
import {SettingsRoutingModule} from './settings-routing.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MoostSettingsAddComponent} from './moost-settings-add/moost-settings-add.component';
import {MatStepperModule} from '@angular/material/stepper';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MoostSettingsCardComponent} from './moost-settings-card/moost-settings-card.component';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MoostSettingsEditComponent} from './moost-settings-edit/moost-settings-edit.component';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    MoostSettingsOverviewComponent,
    MoostSettingsAddComponent,
    MoostSettingsCardComponent,
    MoostSettingsEditComponent
  ],
  imports: [
    BrowserModule,
    SettingsRoutingModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDividerModule,
    MatTooltipModule
  ],
  exports: [
    MoostSettingsOverviewComponent
  ]
})
export class SettingsModule {
}
