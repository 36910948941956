import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

export const DEFAULT_ROUTE = '/notifications';

const routes: Routes = [
  //If path doesn't match anything reroute to default page
  {
    path: '**',
    redirectTo: DEFAULT_ROUTE,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
