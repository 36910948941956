import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ActivatedRoute, Params} from '@angular/router';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {EventsFilter} from '../events.models';

@Component({
  selector: 'app-moost-events-overview',
  templateUrl: './moost-events-overview.component.html',
  styleUrls: ['./moost-events-overview.component.scss']
})
export class MoostEventsOverviewComponent implements OnInit {
  initFilter: EventsFilter;
  filter: EventsFilter;

  constructor(private headerService: MoostHeaderService, private route: ActivatedRoute) {
    this.headerService.setTitle('Events');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams: Params) => {
      const paramStartTimestamp: number = queryParams['s'];
      const paramEndTimestamp: number = queryParams['e'];
      this.initFilter = new EventsFilter(
        paramStartTimestamp || moment().startOf('day').toDate().getTime(),
        paramEndTimestamp || moment().endOf('day').toDate().getTime(),
        queryParams['building']?.split(","),
        queryParams['source']?.split(","),
        queryParams['type']?.split(","),
        queryParams['deviceId']?.split(","),
        queryParams['deviceName']?.split(",")
      )
    });
  }

  applyFilter($event: EventsFilter): void {
    this.filter = $event;
  }
}
