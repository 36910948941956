import SignatureHelpProvider = languages.SignatureHelpProvider;
import SignatureHelpResult = languages.SignatureHelpResult;
import ParameterInformation = languages.ParameterInformation;
import SignatureInformation = languages.SignatureInformation;
import SignatureHelpContext = languages.SignatureHelpContext;
import ProviderResult = languages.ProviderResult;
import {CancellationToken, editor, IPosition, languages, Position} from 'monaco-editor';
import {RuleLanguage} from "./rule-language";
import {Token} from '../validator/token';
import {ITermFunctionDocumentationHelp, ITermFunctionSignatureHelp, TermFunction} from '../validator/term-function';

export interface IFindTermFunctionResult {
  termFunction: TermFunction,
  activeParameter: number
}

export class RuleLanguageSignatureHelpProvider implements SignatureHelpProvider {
  signatureHelpTriggerCharacters: string[] = ['('];
  signatureHelpRetriggerCharacters: string[] = [','];

  provideSignatureHelp(model: editor.ITextModel, position: Position, token: CancellationToken,
                       context: SignatureHelpContext): ProviderResult<SignatureHelpResult> {

    const tokens: Token[] = RuleLanguage.EDITOR_CONFIG.tokens.get(model.uri);

    let currentCaretPositionTokenIndex: number;
    //Spaces are not tokenized, so we need to see if we are over the last token.
    //if so we use the last tokenIndex as our current position
    if (position.column - 1 > tokens[tokens.length - 1].getEndPosition()) {
      currentCaretPositionTokenIndex = tokens.length - 1;
    } else {
      currentCaretPositionTokenIndex = tokens.findIndex((token: Token): boolean => {
        return this.isPositionWithinToken(position, token);
      });
    }

    const termFunctionResult: IFindTermFunctionResult = this.findTermFunctionNearestToSelectedToken(tokens,
      currentCaretPositionTokenIndex)

    if (termFunctionResult.termFunction) {
      return this.getSignatureHelpResult(
        termFunctionResult.termFunction.documentationHelp,
        termFunctionResult.activeParameter
      );
    } else {
      return;
    }
  }

  findTermFunctionNearestToSelectedToken(tokens: Token[], currentSelectedTokenIndex: number): IFindTermFunctionResult {
    let functionToShowDoc: TermFunction;
    let activeParamNumber: number = 0;
    let closeBracketCounter: number = 0;
    let functionCounter: number = 0;

    for (let i: number = currentSelectedTokenIndex; i >= 0; i--) {
      const tokenFromArray: Token = tokens[i];

      //We never go further then to the next operator to look for a function
      if (tokenFromArray.isOperator()) {
        break;
      }

      //We keep track fo the closed brackets to later know if we are
      //within another function or not.
      if (tokenFromArray.isCloseBracket()) {
        closeBracketCounter++;
      }

      //We need to keep track how many functions and closeBrackets we have seen.
      //If we have seen more closeBrackets then functions we are within another function
      //and need to process the tokens array further backwards to find the x-th function
      //which was not already closed.
      if (tokenFromArray.isFunction()) {
        functionCounter++;
        if (functionCounter > closeBracketCounter) {
          functionToShowDoc = tokenFromArray.getFunction();
          break;
        } else {
          //Reset the activeParamNumber if we are now within a function, but it's not the one
          //for which we will show the documentation and for this do not count the parameters
          activeParamNumber = 0;
        }
      }

      //We keep counting how many functionArgumentSeparators we have seen to know
      //the description to which parameter we need to show.
      if (tokenFromArray.isFunctionArgumentSeparator()) {
        activeParamNumber++;
      }
    }

    return {
      termFunction: functionToShowDoc,
      activeParameter: activeParamNumber
    }
  }

  getSignatureHelpResult(documentationHelp: ITermFunctionDocumentationHelp,
                         activeParameterIndex: number): SignatureHelpResult {
    const signatureInfos: SignatureInformation[] = [];

    documentationHelp.signatures.forEach((signatureHelp: ITermFunctionSignatureHelp): void => {
      signatureInfos.push({
        label: signatureHelp.signature,
        documentation: {
          value: `<p>${documentationHelp.description}</p><b>Example Usage</b><br/><ul><li>${documentationHelp.example}</ul></li>`,
          supportHtml: true
        },
        parameters: this.getParameterInfos(signatureHelp.params),
      });
    });

    return {
      value: {
        signatures: signatureInfos,
        activeSignature: 0,
        activeParameter: activeParameterIndex
      },
      dispose: (): void => {
      }
    };
  }

  getParameterInfos(parameterValues: Map<string, string>): ParameterInformation[] {
    const parameterInfo: ParameterInformation[] = [];

    parameterValues.forEach((value: string, key: string): void => {
      parameterInfo.push({
        label: key,
        documentation: {
          value: `<i>@param ${key} - ${value}</i>`,
          supportHtml: true
        }
      });
    });

    return parameterInfo;
  }

  isPositionWithinToken(position: IPosition, token: Token): boolean {
    //position.column is the current caret position which is behind the actual token.
    return position.column - 1 >= token.getStartPosition() && position.column - 1 <= token.getEndPosition();
  }
}
