import { Injectable, inject } from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private httpClient = inject(HttpClient);

  translationsUrl: string = `${environment.apiUrl}/translations`;

  translate(text: string, sourceLang: string, targetLang: string): Observable<string> {
    if (text) {
      return this.httpClient.post(`${this.translationsUrl}/v1`, text, {
        responseType: 'text',
        params: new HttpParams()
          .set('sourceLanguage', sourceLang)
          .set('targetLanguage', targetLang)
      });
    } else {
      return of('');
    }
  }
}
