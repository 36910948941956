import { Component, inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'app-moost-confirm-dialog',
    templateUrl: './moost-confirm-dialog.component.html',
    styleUrls: ['./moost-confirm-dialog.component.scss'],
    standalone: false
})
export class MoostConfirmDialogComponent {
  dialogRef = inject<MatDialogRef<MoostConfirmDialogComponent, boolean>>(MatDialogRef);
  data = inject<ConfirmDialogModel>(MAT_DIALOG_DATA);

  title: string;
  message: string;
  confirm: string;
  confirmColor: ThemePalette;
  dismiss: string;
  icon: string;

  constructor() {
    const data = this.data;

    this.title = data.title;
    this.message = data.message;
    this.confirm = data.confirm;
    this.confirmColor = data.confirmColor;
    this.dismiss = data.dismiss;
    this.icon = data.icon;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(public title: string, public message: string, public confirm: string, public dismiss: string,
              public icon: string, public confirmColor: ThemePalette = "primary") {
  }
}
