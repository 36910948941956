import {Component, Input} from '@angular/core';
import {ProgressState} from './progress-state';

@Component({
  selector: 'app-moost-progress-indicator',
  templateUrl: './moost-progress-indicator.component.html',
  styleUrls: ['./moost-progress-indicator.component.scss']
})
export class MoostProgressIndicatorComponent {
  @Input() progressState: ProgressState;
}
