import {editor} from 'monaco-editor';
import {MONACO_TOKEN_CLASS} from './rule-language';
import IStandaloneThemeData = editor.IStandaloneThemeData;
import BuiltinTheme = editor.BuiltinTheme;
import ITokenThemeRule = editor.ITokenThemeRule;
import IColors = editor.IColors;

export class RuleLanguageTheme implements IStandaloneThemeData {
  base: BuiltinTheme = "vs";
  inherit: boolean = false;
  rules: ITokenThemeRule[] = [
    {token: MONACO_TOKEN_CLASS.COMMENT, foreground: "8C8C8C"},
    {token: MONACO_TOKEN_CLASS.ARITHMETIC_OPERATOR, foreground: "000000"},
    {token: MONACO_TOKEN_CLASS.VARIABLE, foreground: "871094"},
    {token: MONACO_TOKEN_CLASS.NUMBER, foreground: "1750EB",},
    {token: MONACO_TOKEN_CLASS.TEXT, foreground: "067D17",},
    {token: MONACO_TOKEN_CLASS.COMPARISON_OPERATOR, foreground: "000000"},
    {token: MONACO_TOKEN_CLASS.FUNCTION, foreground: "00627A"},
    {token: MONACO_TOKEN_CLASS.LOGICAL_OPERATOR, foreground: "573904"},
    {token: MONACO_TOKEN_CLASS.TRANSFORMATION_OPERATOR, foreground: "000000"},
  ];
  colors: IColors = {
    "editor.foreground": "#000000",
    "editor.lineHighlightBorder": "#FFFFFF",
    "editor.lineHighlightBackground": "#FFFFFF",
  }
}
