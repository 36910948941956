import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../../shared-module/customer.service';
import {Customer, EventCategory} from '../../shared-module/customer.model';
import {Permission} from '../../auth-token-module/auth-token.models';
import {Subscription} from 'rxjs';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {LanguageService} from '../../shared-module/language.service';
import {NotificationLanguage} from '../../notifications-module/notifications.models';
import {ColorPalette} from '../../shared-module/color-palette';
import {secondsToDurationString} from '../../rules-module/rules.models';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-moost-users-profile',
  templateUrl: './moost-users-profile.component.html',
  styleUrls: ['./moost-users-profile.component.scss'],
  standalone: false
})

export class MoostUsersProfileComponent implements OnInit, OnDestroy {
  protected readonly Permission = Permission;
  protected readonly JSON = JSON;
  protected readonly ColorPalette = ColorPalette;
  protected readonly secondsToDurationString = secondsToDurationString;

  protected authTokenService = inject(AuthTokenService);
  private customerService = inject(CustomerService);
  private languageService = inject(LanguageService);
  private headerService = inject(MoostHeaderService);
  protected customer: Customer;
  protected eventCategories: EventCategory[];
  private customerSubscription: Subscription;
  readonly displayedColumnsEventCategories: string[] = ["type", "source", "interval", "isComputed", "description"];

  constructor() {
    this.headerService.setTitle('Profile');
  }

  ngOnInit(): void {
    this.customerSubscription = this.customerService.getCustomer()
      .subscribe((customer: Customer) => {
        this.customer = customer;
        this.eventCategories = customer.eventCategories?.length > 0 ? customer.eventCategories : null;
      });
  }

  ngOnDestroy(): void {
    this.customerSubscription?.unsubscribe();
  }

  getActiveNotificationLanguages(): NotificationLanguage[] {
    return this.languageService.getNotificationLanguages(this.customer?.notificationLanguages);
  }

  getInactiveNotificationLanguages(): NotificationLanguage[] {
    const inactiveLanguages: string[] = this.languageService?.availableNotificationLanguages
      ?.map((it: NotificationLanguage) => it.code)
      ?.filter((it: string) => !this.customer?.notificationLanguages.includes(it));
    return this.languageService.getNotificationLanguages(inactiveLanguages);
  }

  sortData(sort: Sort): void {
    const eventCategories: EventCategory[] = this.eventCategories.slice();
    this.eventCategories = eventCategories.sort((a, b) => {
      const isAsc: boolean = sort.direction === 'asc';
      switch (sort.active) {
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'source':
          return compare(a.source, b.source, isAsc);
        case 'interval':
          return compare(a.interval, b.interval, isAsc);
        case 'isComputed':
          return compare(Number(a.isComputed), Number(b.isComputed), isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
