import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Customer} from './customer.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private httpClient: HttpClient = inject(HttpClient);

  private static readonly CUSTOMER_URL: string = `${environment.apiUrl}/customer`;

  getCustomer(): Observable<Customer> {
    return this.httpClient.get<any>(`${CustomerService.CUSTOMER_URL}/v1`, {});
  }

}
