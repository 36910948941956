<div>
  <div class="filter">
    <app-moost-rules-filter
      (applyFilterChange)="applyFilter($event)">
    </app-moost-rules-filter>
  </div>
  <div class="page-content">
    @for (section of sections; track section) {
      @if (section === SECTION_CUSTOMER_RULES) {
        <div class="add-button-container">
          <p>
            @if (customerRules.length === filteredRules.length) {
              Found {{ customerRules.length }} rules.
              {{ libraryRules.length - libraryOnlyRules.length }} rules originate from the MOOST Library.
            } @else {
              Filter {{ filteredRules.length }} of {{ customerRules.length }} rules.
            }
          </p>
          <a [disabled]="!authTokenService.hasPermission(Permission.WRITE_RULES)"
             color="primary" mat-flat-button routerLink="./add"
             style="color: #FFFFFF;">ADD RULE</a>
        </div>
      } @else if (section === SECTION_LIBRARY_ONLY_RULES) {
        <h2 class="title">Library Rules</h2>
        <p>
          Found {{ libraryRules.length }} rules.
          Following {{ libraryOnlyRules.length }} rules are not part of your used rules.
        </p>
      }
      <div class="card-container">
        @for (rule of getRules(section); track rule.id) {
          <mat-card class="mat-mdc-elevation-specific mat-elevation-z4">
            <mat-card-header [class]="section === SECTION_LIBRARY_ONLY_RULES ? 'library-rule' : 'customer-rule'">
              <div class="icon-container">
                <mat-icon
                  class="topic-color">{{ (RuleIcon.getTopicIcon(rule.topicIcon) || RuleIcon.TOPICS[0]).materialIcon }}
                </mat-icon>
                <mat-icon
                  class="impact-color">{{ (RuleIcon.getImpactIcon(rule.impactIcon) || RuleIcon.IMPACTS[0]).materialIcon }}
                </mat-icon>
              </div>
              <div class="feature-content">
                @if (rule.templateRuleId || section === SECTION_LIBRARY_ONLY_RULES) {
                  <div class="ribbon-left-upper-corner-wrapper">
                    <div class="ribbon">
                      Library
                      @if (isDifferentToLibraryRule(rule)) {
                        <mat-icon style="font-size: 14px;" matTooltip="Rule Configuration is different to Library">
                          difference
                        </mat-icon>
                      }
                    </div>
                  </div>
                }
                @if (rule.ruleState !== Rule.ACTIVE) {
                  <mat-icon matTooltip="Rule not active">toggle_off</mat-icon>
                }
                @if (rule.isTimeBased) {
                  <mat-icon [matTooltip]="getTimeBasedCronDescription(rule)">schedule</mat-icon>
                }
                @if (rule.isRestrictedToEarlyAdopters) {
                  <mat-icon matTooltip="Restricted to Early Adopters">school</mat-icon>
                }
                @if (rule.streakCondition) {
                  <mat-icon matTooltip="Streak">military_tech</mat-icon>
                }
              </div>
            </mat-card-header>
            <mat-card-content>
              <div class="card-title">{{ rule.name }}</div>
              <p>{{ rule.description }}</p>
            </mat-card-content>
            <mat-card-actions>
              @if (section === SECTION_LIBRARY_ONLY_RULES) {
                <button
                  [disabled]="!authTokenService.hasPermission(Permission.WRITE_RULES)"
                  mat-button [routerLink]="['./add']" [queryParams]="{ libraryRule: rule.id }">
                  ADD RULE
                </button>
              } @else {
                <button mat-button [routerLink]="['./', rule.id]" queryParamsHandling="merge">
                  EDIT
                </button>
              }
            </mat-card-actions>
          </mat-card>
        }
      </div>
    }
  </div>
</div>
