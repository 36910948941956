import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MoostEventsOverviewComponent} from './moost-events-overview/moost-events-overview.component';
import {AuthGuard} from '@auth0/auth0-angular';


const routes: Routes = [
  {
    path: 'events',
    component: MoostEventsOverviewComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule {
}
