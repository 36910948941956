export class RelativeDuration {

  static asText(fromSeconds: number, toSeconds: number): string {
    const deltaSeconds: number = toSeconds - fromSeconds;
    if (deltaSeconds < 60) {
      return `${this.renderValueAndUnit(deltaSeconds, 'second')}`;
    } else if (deltaSeconds < 3600) {
      return `${this.renderValueAndUnit(deltaSeconds / 60, 'minute')}`;
    } else if (deltaSeconds < 86400) {
      return `${this.renderValueAndUnit(deltaSeconds / 3600, 'hour')}`;
    } else if (deltaSeconds < 2678400) {
      return `${this.renderValueAndUnit(deltaSeconds / 86400, 'day')}`;
    } else if (deltaSeconds < 31536000) {
      return `${this.renderValueAndUnit(deltaSeconds / 2678400, 'month')}`;
    } else {
      return `${this.renderValueAndUnit(deltaSeconds / 31536000, 'year')}`;
    }
  }

  private static renderValueAndUnit(value: number, unit: string): string {
    const v: number = Math.floor(value);
    return v === 1 ? `${v} ${unit}` : `${v} ${unit}s`
  }
}
