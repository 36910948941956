<mat-toolbar class="mat-elevation-z3 mat-toolbar">
  <button
    (click)="drawer.toggle()"
    aria-label="Toggle sidenav"
    id="navigation"
    mat-icon-button
    type="button"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  <button *ngIf="headerService.getBackRouterLink()"
          [queryParamsHandling]="'preserve'"
          [routerLink]="[headerService.getBackRouterLink()]"
          mat-icon-button>
    <mat-icon>arrow_back</mat-icon>
  </button>

  <h1 class="title">
    {{ headerService.getTitle() }}
  </h1>
  <span class="spacer"></span>
  <app-moost-user-menu></app-moost-user-menu>
</mat-toolbar>
