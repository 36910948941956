import {TermItem} from './term-item';
import {TermStructure} from './term-structure';
import {Kind, Token} from './token';
import {TermType} from './term-type';

export class TermError {

  constructor(readonly message: string, readonly token: Token, readonly args?: TermItem[]) {
    this.message = message;
    this.token = token;
    this.args = args;
  }

  /**
   * Use this method if you may presume the resulting TermItem (although there was an error detected).
   */
  andPresume(structure: TermStructure, type: TermType): TermItem {
    let min: number;
    let max: number;
    let expression: string;
    if (this.args) {
      for (let item of this.args) {
        if (item && item.token) {
          if (!min || item.token.getStartPosition() < min) {
            min = item.token.getStartPosition();
          }
          if (!max || item.token.getEndPosition() > max) {
            max = item.token.getEndPosition();
          }
          if (!expression) {
            expression = item.token.getExpression();
          } else if (expression !== item.token.getExpression()) {
            throw Error("Arguments of different expressions detected");
          }
        }
      }
    }
    if (min && max && expression) {
      return new TermItem(structure, type, new Token(Kind.AGGREGATION, expression, min, max));
    } else {
      return new TermItem(structure, type);
    }
  }

  /**
   * The start position of the error by taking related arguments (in case of a function or operation) into account.
   */
  getStartPosition(): number {
    const positions: number[] = [this.token.getStartPosition()];
    this.args?.filter(it => !!it.token).forEach(it => positions.push(it.token.getStartPosition()));
    return Math.min(...positions);
  }

  /**
   * The end position of the error by taking related arguments (in case of a function or operation) into account.
   */
  getEndPosition(): number {
    const positions: number[] = [this.token.getEndPosition()];
    this.args?.filter(it => !!it.token).forEach(it => positions.push(it.token.getEndPosition()));
    return Math.max(...positions);
  }

}
