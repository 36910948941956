<div class="event-graph-container">
  <app-moost-rules-data-graph [customerBuildingId]="customerBuildingId"
                              [datasets]="eventDatasetSelection"
                              [startTimeRangeMillis]="startTimeRangeMillis"
                              [endTimeRangeMillis]="endTimeRangeMillis"
                              [isSimulationRunning]="false"
                              [isHelplineVisible]="false"
                              class="rules-data-graph">
  </app-moost-rules-data-graph>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Event Selection</mat-panel-title>
        <mat-panel-description>Select the data points to be displayed</mat-panel-description>
      </mat-expansion-panel-header>

      <form [formGroup]="eventCategorySelectionForm">
        <div class="event-dataset-selection">
          <table mat-table [dataSource]="eventCategorySelectionSource"
                 class="event-dataset-selection-table">
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let eventCategory">
                @if (eventCategory) {
                  {{ eventCategory.type }}
                } @else {
                  <mat-form-field appearance="outline" class="types-field">
                    <mat-label>Event Type</mat-label>
                    <mat-select formControlName="eventType"
                                (selectionChange)="updateSelectionsInEventCategorySelectionForm()">
                      <mat-option *ngFor="let type of eventTypesChoice" [value]="type">
                        {{ type }}
                      </mat-option>
                    </mat-select>
                    <mat-spinner *ngIf="isLoadingEventTypes" [diameter]="24" matPrefix
                                 style="margin: 0 16px;"></mat-spinner>
                  </mat-form-field>
                }
              </td>
            </ng-container>

            <ng-container matColumnDef="source">
              <th mat-header-cell *matHeaderCellDef>Source</th>
              <td mat-cell *matCellDef="let eventCategory">
                @if (eventCategory) {
                  {{ eventCategory.source }}
                } @else {
                  <mat-form-field appearance="outline" class="sources-field">
                    <mat-label>Source Type</mat-label>
                    <mat-select formControlName="eventSource">
                      <mat-option *ngFor="let source of eventSourcesChoice" [value]="source">
                        {{ source }}
                      </mat-option>
                    </mat-select>
                    <mat-spinner *ngIf="isLoadingSourceTypes" [diameter]="24" matPrefix
                                 style="margin: 0 16px;"></mat-spinner>
                  </mat-form-field>
                }
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let eventDataset">
                @if (eventDataset) {
                  <button (click)="discardEventDataset(eventDataset)" mat-icon-button type="button" matTooltip="Remove"
                          class="remove">
                    <mat-icon>cancel</mat-icon>
                  </button>
                } @else {
                  <button (click)="addEventDataset()" mat-icon-button type="button" matTooltip="Add" class="add">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                }
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsEventDatasets"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsEventDatasets;"></tr>
          </table>
          @if (errorMessage) {
            <mat-error>
              {{ errorMessage }}
            </mat-error>
          }
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
