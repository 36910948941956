import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, EMPTY, forkJoin, Observable, ObservedValueOf} from 'rxjs';
import {EventsService} from '../events.service';
import {catchError, finalize} from 'rxjs/operators';
import {EventsFilter, IEvent} from '../events.models';

export class EventsDatasource implements DataSource<IEvent> {
  private eventsSubject: BehaviorSubject<IEvent[]> = new BehaviorSubject<IEvent[]>([]);
  public events$: Observable<IEvent[]> = this.eventsSubject.asObservable();
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();
  private lengthSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  public length$: Observable<number> = this.lengthSubject.asObservable();

  constructor(private eventsService: EventsService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<IEvent[]> {
    return this.eventsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.eventsSubject.complete();
    this.loadingSubject.complete();
    this.lengthSubject.complete();
  }

  loadEvents(filter: EventsFilter,
             sortProperties: string = 'timestamp',
             sortDirection: string = 'desc',
             pageNumber: number = 0,
             pageSize: number = 10): void {
    this.loadingSubject.next(true);
    forkJoin({
      page: this.eventsService.getEventsPage(
        filter,
        sortProperties, sortDirection,
        pageNumber, pageSize),
      count: this.eventsService.getEventsCount(
        filter)
    }).pipe(
      catchError(() => EMPTY),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe((results: {
      page: ObservedValueOf<Observable<IEvent[]>>
      count: ObservedValueOf<Observable<number>>;
    }) => {
      this.eventsSubject.next(results.page);
      this.lengthSubject.next(results.count);
    });
  }

  clear(): void {
    this.eventsSubject.next([]);
    this.lengthSubject.next(0);
  }
}
