import {TermStructure} from "./term-structure";
import {TermType} from "./term-type";
import {Token} from "./token";

export class TermItem {

  readonly structure: TermStructure;
  readonly type: TermType;
  readonly token: Token;

  constructor(structure: TermStructure, type: TermType, token?: Token) {
    this.structure = structure;
    this.type = type;
    this.token = token;
  }

  getStructureName(): string {
    return TermStructure[this.structure];
  }

  getTypeName(): string {
    return TermType[this.type];
  }

  getStructureType(): string {
    return `${this.getStructureName()}<${this.getTypeName()}>`;
  }

  isScalar(): boolean {
    return this.structure === TermStructure.SCALAR;
  }

  isVector(): boolean {
    return this.structure === TermStructure.VECTOR;
  }

  isGroupedScalar(): boolean {
    return this.structure === TermStructure.GROUPED_SCALAR;
  }

  isGroupedVector(): boolean {
    return this.structure === TermStructure.GROUPED_VECTOR;
  }

  isBoolean(): boolean {
    return this.type === TermType.BOOLEAN;
  }

  isNumber(): boolean {
    return this.type === TermType.NUMBER;
  }

  isTime(): boolean {
    return this.type === TermType.TIME;
  }

  isTimespan(): boolean {
    return this.type === TermType.TIMESPAN;
  }

  isText(): boolean {
    return this.type === TermType.TEXT;
  }

  isEvent(): boolean {
    return this.type === TermType.EVENT;
  }

  hasNumberValue(): boolean {
    return this.type === TermType.NUMBER || this.type === TermType.EVENT;
  }

}
