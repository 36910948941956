import { Component, Input, inject } from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Permission} from '../auth-token-module/auth-token.models';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthTokenService} from '../auth-token-module/auth-token.service';

@Component({
    selector: 'app-moost-navigation',
    templateUrl: './moost-navigation.component.html',
    styleUrls: ['./moost-navigation.component.scss'],
    standalone: false
})
export class MoostNavigationComponent {
  private breakpointObserver = inject(BreakpointObserver);
  private httpClient = inject(HttpClient);
  protected authTokenService = inject(AuthTokenService);

  @Input() drawer: MatSidenav;
  isHandset$: Observable<boolean>;
  protected readonly Permission = Permission;


  constructor() {
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(
        map(result => result.matches),
        shareReplay()
      );
  }

  public redirectToBillingPortal(): void {
    this.httpClient.get(
      `${environment.apiUrl}/customer/billing/portal/v1`,
      {
        responseType: 'text',
        observe: 'response'
      }
    ).pipe(
      catchError(err => {
        return throwError(err);
      })
    ).subscribe(response => {
      window.location.href = response.body;
    });
  }
}
