export function toRgba(hex: string, alpha: number): string {
  const result: RegExpExecArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r: number = parseInt(result[1], 16);
    const g: number = parseInt(result[2], 16);
    const b: number = parseInt(result[3], 16);
    return `rgba(${r},${g},${b},${alpha})`;
  } else {
    return '';
  }
}

export enum EventTypeColorCategory {
  RATE,
  STATE,
  TEMPERATURE,
  CONSUMPTION,
  GENERATION,
  EXCESS,
  DEFAULT
}

export class ColorPalette {
  static readonly PRIMARY: string = '#2DBE60';
  static readonly PRIMARY_GRAY: string = '#499263';
  static readonly GRAY: string = '#666666';
  static readonly OPPOSITE_PRIMARY: string = '#D2419F';

  // Colors from https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
  static readonly BLUEGRAY: string = '#546E7A';          // Blue Gray 600
  static readonly BLUEGRAY_LIGHTER: string = '#78909C';  // Blue Gray 400
  static readonly BLUEGRAY_LIGHT: string = '#CFD8DC';    // Blue Gray 100
  static readonly PURPLE: string = '#7B1FA2';            // Purple 700
  static readonly PURPLE_LIGHTER: string = '#AB47BC';    // Purple 400
  static readonly PURPLE_LIGHT: string = '#E1BEE7';      // Purple 100
  static readonly YELLOW: string = '#FDD835';            // Yellow 600
  static readonly YELLOW_LIGHTER: string = '#FFEE58';    // Yellow 400
  static readonly YELLOW_LIGHT: string = '#FFF59D';      // Yellow 200
  static readonly TEAL: string = '#00897B';              // Teal 600
  static readonly TEAL_LIGHTER: string = '#26A69A';      // Teal 400
  static readonly TEAL_LIGHT: string = '#80CBC4';        // Teal 200
  static readonly LIGHT_BLUE: string = '#0288D1';        // Light Blue 700
  static readonly LIGHT_BLUE_LIGHTER: string = '#29B6F6';// Light Blue 400
  static readonly LIGHT_BLUE_LIGHT: string = '#B3E5FC';  // Light Blue 100
  static readonly RED: string = '#D32F2F';               // Red 700
  static readonly RED_LIGHTER: string = '#EF5350';       // Red 400
  static readonly RED_LIGHT: string = '#FFCDD2';         // Red 200
  static readonly ORANGE: string = '#F57C00';            // Orange 700
  static readonly ORANGE_LIGHTER: string = '#FFA726';    // Orange 400
  static readonly ORANGE_LIGHT: string = '#FFE0B2';      // Orange 100

  static readonly EVENT_TYPE_COLORSET: Map<EventTypeColorCategory, string[]> = new Map<EventTypeColorCategory, string[]>([
    [EventTypeColorCategory.RATE, [ColorPalette.PURPLE, ColorPalette.PURPLE_LIGHT, ColorPalette.PURPLE_LIGHTER]],
    [EventTypeColorCategory.STATE, [ColorPalette.BLUEGRAY, ColorPalette.BLUEGRAY_LIGHT, ColorPalette.BLUEGRAY_LIGHTER]],
    [EventTypeColorCategory.TEMPERATURE, [ColorPalette.RED, ColorPalette.RED_LIGHT, ColorPalette.RED_LIGHTER]],
    [EventTypeColorCategory.CONSUMPTION, [ColorPalette.LIGHT_BLUE, ColorPalette.LIGHT_BLUE_LIGHT, ColorPalette.LIGHT_BLUE_LIGHTER]],
    [EventTypeColorCategory.GENERATION, [ColorPalette.YELLOW, "rgba(253,166,53,0.71)", ColorPalette.YELLOW_LIGHTER]],
    [EventTypeColorCategory.EXCESS, [ColorPalette.TEAL, ColorPalette.TEAL_LIGHT, ColorPalette.TEAL_LIGHTER]],
    [EventTypeColorCategory.DEFAULT, [ColorPalette.ORANGE, ColorPalette.ORANGE_LIGHT, ColorPalette.ORANGE_LIGHTER]],
  ]);

  static getEventTypeBasedColor(eventType: string, index: number): string {
    const alpha: number = 1.0 - index * 0.15;
    return toRgba(this.getEventTypeBasedColorSet(eventType)[0], alpha);
  }

  static getEventTypeBasedColorSet(eventType: string): string[] {
    return this.EVENT_TYPE_COLORSET.get(this.getEventTypeColorCategory(eventType));
  }

  static getEventTypeColorCategory(eventType: string): EventTypeColorCategory {
    if (eventType.includes("RATE")) {
      return EventTypeColorCategory.RATE;
    } else if (eventType.includes("STATUS") || eventType.includes("STATE")) {
      return EventTypeColorCategory.STATE;
    } else if (eventType.includes("TEMPERATURE")) {
      return EventTypeColorCategory.TEMPERATURE;
    } else if (eventType.includes("CONSUMPTION")) {
      return EventTypeColorCategory.CONSUMPTION;
    } else if (eventType.includes("GENERATION")) {
      return EventTypeColorCategory.GENERATION;
    } else if (eventType.includes("EXCESS")) {
      return EventTypeColorCategory.EXCESS;
    } else {
      return EventTypeColorCategory.DEFAULT;
    }
  }

  static getNotificationInteractionBasedColor(interaction: string): string {
    switch (interaction) {
      case 'VIEWED':
        return toRgba(ColorPalette.PRIMARY, 0.4);
      case 'OPENAPP':
        return toRgba(ColorPalette.PRIMARY, 0.7);
      case 'OPENWEB':
        return toRgba(ColorPalette.PRIMARY, 1.0);
      case 'STOPDELIVERY':
        return toRgba(ColorPalette.OPPOSITE_PRIMARY, 1.0);
      case 'DISMISS':
        return toRgba(ColorPalette.OPPOSITE_PRIMARY, 0.7);
      default:
        return toRgba(ColorPalette.OPPOSITE_PRIMARY, 0.4);
    }
  }

  static getNotificationDeliveryBasedColor(status: string, reason: string, simulation: boolean): string {
    switch (status) {
      case 'DELIVERED':
        return toRgba(ColorPalette.PRIMARY, simulation ? 0.5 : 0.8);
      case 'DROPPED':
        switch (reason) {
          case 'TIME_BETWEEN_TRIGGERS_NOT_ELAPSED':
            return toRgba(ColorPalette.GRAY, simulation ? 0.5 : 1.0);
          case 'STOP_DELIVERY_INTERACTION_FOUND':
            return toRgba(ColorPalette.GRAY, simulation ? 0.4 : 0.8);
          case 'ONLY_FOR_EARLY_ADOPTERS':
            return toRgba(ColorPalette.GRAY, simulation ? 0.3 : 0.6);
          case 'RULE_INACTIVE_FOR_BUILDING':
            return toRgba(ColorPalette.GRAY, simulation ? 0.2 : 0.4);
          default:
            return toRgba(ColorPalette.GRAY, simulation ? 0.1 : 0.2);
        }
      default: // PENDING
        return toRgba(ColorPalette.OPPOSITE_PRIMARY, 1.0);
    }
  }

  static getStandardColorPalette(count: number): string[] {
    return [
      toRgba(this.PRIMARY, 1.0),
      toRgba(this.PRIMARY, 0.8),
      toRgba(this.PRIMARY, 0.6),
      toRgba(this.PRIMARY, 0.4),
      toRgba(this.PRIMARY, 0.2),
      toRgba(this.GRAY, 0.2),
      toRgba(this.GRAY, 0.4),
      toRgba(this.GRAY, 0.6),
      toRgba(this.GRAY, 0.8),
      toRgba(this.GRAY, 1.0),
      toRgba(this.PRIMARY_GRAY, 1.0),
      toRgba(this.PRIMARY_GRAY, 0.8),
      toRgba(this.PRIMARY_GRAY, 0.6),
      toRgba(this.PRIMARY_GRAY, 0.4),
      toRgba(this.PRIMARY_GRAY, 0.2),
    ].slice(0, count);
  }
}

