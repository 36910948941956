import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {CountByGroup, EventsService} from '../events.service';
import {EventsFilter} from '../events.models';
import {IChartDataItem} from '../../moost-charts/moost-charts.models';
import {ColorPalette, EventTypeColorCategory, toRgba} from '../../shared-module/color-palette';

@Component({
    selector: 'app-moost-events-group-by-chart',
    templateUrl: './moost-events-group-by-chart.component.html',
    standalone: false
})
export class MoostEventsGroupByChartComponent implements OnChanges {
  private eventsService = inject(EventsService);

  @Input() filter: EventsFilter;
  @Input() title: string;
  @Input() groupBy: string;
  @Input() maxGroupsWithoutOthers: number = 9;
  @Input() showOthersGroup: boolean = true;
  isLoading: boolean;
  hasError: boolean = false;
  subscription: Subscription;
  private chartOptionsSubject = new BehaviorSubject({});
  public chartOptions$ = this.chartOptionsSubject.asObservable();

  ngOnChanges(changes: SimpleChanges): void {
    this.loadCountByGroup();
  }

  applyOnChart(counts: CountByGroup[]): void {
    if (counts) {
      const sortedChartItems: IChartDataItem[] = counts.map((item: CountByGroup): IChartDataItem => {
        return {
          name: item.name,
          value: item.count
        }
      }).sort((a: IChartDataItem, b: IChartDataItem): number => b.value - a.value);

      const chartData: IChartDataItem[] = sortedChartItems.slice(0, this.maxGroupsWithoutOthers);
      //Add Group for "Others" values
      if (this.showOthersGroup && this.maxGroupsWithoutOthers < counts.length) {
        chartData.push({
          name: `Others (${counts.length - this.maxGroupsWithoutOthers}x)`,
          value: sortedChartItems.slice(this.maxGroupsWithoutOthers).reduce((accumulator: number, group: IChartDataItem) => accumulator + group.value, 0)
        });
      }
      let chartColor: string[];
      if (this.groupBy === "type") {
        const typeCount: Map<EventTypeColorCategory, number> = new Map();
        chartColor = chartData.map((it: IChartDataItem) => {
          if (it.name.startsWith("Others")) {
            return toRgba(ColorPalette.GRAY, 0.5);
          }
          const category: EventTypeColorCategory = ColorPalette.getEventTypeColorCategory(it.name);
          const index: number = typeCount.get(category) | 0;
          typeCount.set(category, index + 1);
          return ColorPalette.getEventTypeBasedColor(it.name, index);
        });
      } else {
        chartColor = ColorPalette.getStandardColorPalette(chartData.length);
      }

      this.chartOptionsSubject.next({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          textStyle: {
            width: 90,
            overflow: 'truncate'
          }
        },
        series: [{
          type: 'pie',
          avoidLabelOverlap: true,
          padAngle: 2,
          minAngle: 4,
          radius: ['30%', '60%'],
          data: chartData,
          color: chartColor,
          center: ['50%', '60%'],
          label: {
            show: true,
            position: 'outside',
            overflow: 'truncate',
            bleedMargin: '10',
            formatter: '{b} \n {d}%'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      });
    }
  }

  private loadCountByGroup(): void {
    if (this.filter) {
      this.hasError = false;
      this.isLoading = true;
      this.eventsService.getCountByGroup(this.filter, this.groupBy)
        .pipe(
          catchError(() => {
            this.hasError = true;
            return null;
          }),
          finalize(() => this.isLoading = false)
        ).subscribe((counts: CountByGroup[]) => {
        this.hasError = false;
        this.applyOnChart(counts);
      });
    }
  }
}
