import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../notifications-module/notifications.service';
import {NotificationLanguage} from '../notifications-module/notifications.models';

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnDestroy {

  availableNotificationLanguages: NotificationLanguage[];
  private notificationsSubscription: Subscription;

  constructor(private notificationsService: NotificationsService) {
    this.notificationsSubscription = this.notificationsService.getAvailableLanguages().subscribe({
      next: (notificationLanguages: NotificationLanguage[]): void => {
        this.availableNotificationLanguages = notificationLanguages;
      },
      error: (error): void => {
        console.error(error.message);
      }
    });
  }

  getLanguageNames(codes: string[]): string[] {
    return codes?.map(code => this.getLanguageName(code));
  }

  getLanguageName(code: string): string {
    return this.availableNotificationLanguages.find(it => it.code === code).name;
  }

  getNotificationLanguages(codes: string[]): NotificationLanguage[] {
    return codes?.map(code => this.getNotificationLanguage(code));
  }

  getNotificationLanguage(code: string): NotificationLanguage {
    return this.availableNotificationLanguages.find(it => it.code === code);
  }

  ngOnDestroy(): void {
    this.notificationsSubscription?.unsubscribe();
  }
}
