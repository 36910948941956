<div *ngIf="isAuthenticated()">
  <app-moost-header [drawer]="drawer"></app-moost-header>

  <!--suppress HtmlUnknownAttribute -->
  <mat-sidenav-container [ngClass]="'sidenav-container-spacing'" fullscreen>
    <mat-sidenav #drawer [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [autoFocus]="false"
                 mode="over">
      <app-moost-navigation [drawer]="drawer">
      </app-moost-navigation>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<p *ngIf="(isAuthenticated()) === false" class="center">
  Not logged in.
  Please click <a routerLink="/">here</a> if you are not forwarded to the login page.
  <router-outlet></router-outlet>
</p>
