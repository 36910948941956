import {YAXisOption} from 'echarts/types/dist/shared';
import {DataUnit} from '../../events-module/events.models';

export class MoostRulesDataGraphAxis {

  static getYAxis(unit: DataUnit, min: number, max: number, offset: number, showSplitLine: boolean): YAXisOption {
    switch (unit) {
      case DataUnit.ID:
        const interval: number = Math.ceil((max - min) / 5); // render max 5 scale ticks on y-axis
        return this.getYAXisOption(unit, min, max, offset, interval, showSplitLine);
      case DataUnit.BOOLEAN:
        return this.getYAXisOption(unit, 0, 1, offset, 1, showSplitLine);
      case DataUnit.NUMBER:
        return this.getYAXisOption(unit, min, max, offset, undefined, showSplitLine);
      case DataUnit.PERCENT:
      case DataUnit.CELSIUS:
      case DataUnit.WATT:
      case DataUnit.WATT_HOUR:
        return this.getYAXisOption(unit, min, max, offset, undefined, showSplitLine);
      default:
        return this.getYAXisOption(unit, min, max, offset, undefined, showSplitLine);
    }
  }

  static getYAXisId(unit: DataUnit): string {
    return unit.name;
  }

  private static getYAXisOption(unit: DataUnit, min: number, max: number, offset: number, interval: number, showSplitLine: boolean): YAXisOption {
    return {
      id: this.getYAXisId(unit),
      type: 'value',
      min: min,
      max: max,
      offset: offset,
      interval: interval,
      position: 'left',
      boundaryGap: ["4%", "10%"],
      axisLine: {
        onZero: false
      },
      axisLabel: {
        formatter: (value: number) => value + ' ' + unit.symbol
      },
      splitLine: {
        show: showSplitLine
      }
    }
  }

  static getNotificationYAxis(): YAXisOption {
    return {
      id: this.getNotificationYAXisId(),
      name: 'Notification',
      show: false,
      type: 'value',
      min: 0,
      max: 2,
      interval: 0.1,
      axisLine: {
        onZero: false
      },
      axisLabel: {
        formatter: '{value}'
      }
    }
  }

  static getNotificationYAXisId(): string {
    return 'Notification';
  }
}
