<div class="filter-bar">
  <form [formGroup]="filterForm" class="buildings-filter">

    <mat-form-field appearance="outline" class="id-field">
      <mat-label>ID</mat-label>
      <input (keyup)="applyFilter()" formControlName="id" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="location-field">
      <mat-label>Location</mat-label>
      <input (keyup)="applyFilter()" formControlName="location" matInput placeholder="CH-1000 Lausanne">
    </mat-form-field>

    <mat-form-field appearance="outline" class="status-field">
      <mat-label>Status</mat-label>
      <mat-select (selectionChange)="applyFilter()" formControlName="status" multiple>
        <mat-option *ngFor="let status of buildingStatus" [value]="status">
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="event-type-field">
      <mat-label>Event Types</mat-label>
      <mat-select (selectionChange)="applyFilter()" formControlName="eventTypes" multiple>
        <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
          {{ eventType }}
        </mat-option>
      </mat-select>
      <mat-spinner *ngIf="isLoadingEventTypes" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
    </mat-form-field>

    <mat-form-field appearance="outline" class="event-source-field">
      <mat-label>Event Sources</mat-label>
      <mat-select (selectionChange)="applyFilter()" formControlName="eventSources" multiple>
        <mat-option *ngFor="let eventSource of eventSources" [value]="eventSource">
          {{ eventSource }}
        </mat-option>
      </mat-select>
      <mat-spinner *ngIf="isLoadingSourceTypes" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
    </mat-form-field>
  </form>

  <button (click)="clearFilter()" color="gray" mat-icon-button
          matTooltip="Clear filter">
    <mat-icon>delete_outline</mat-icon>
  </button>

  <button (click)="saveFilteredBuildingsAsFavorites()" color="gray" mat-icon-button
          matTooltip="Save filtered buildings as favorites">
    <mat-icon [class]="isFilterAndFavoritesInSync() ? 'material-icons-outlined' : ''">star</mat-icon>
  </button>
</div>
