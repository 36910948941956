import {TermOperator} from "./term-operator";
import {TermFunction} from "./term-function";
import {TermItem} from './term-item';
import {TermError} from './term-error';

export enum Kind {
  AGGREGATION, // this kind is never used on tokenized expression, but only on function + operation validation
  OPEN_BRACKET,
  CLOSE_BRACKET,
  FUNCTION_SEPARATOR,
  FUNCTION,
  OPERATOR,
  LITERAL,
  COMMENT,
}

export class Token {
  static readonly FUNCTION_ARG_SEPARATOR: string = ',';
  static readonly OPEN_BRACKET: string = '(';
  static readonly CLOSE_BRACKET: string = ')';
  static readonly COMMENT_START: string = '//';

  private error: TermError;
  private function: TermFunction;
  private operator: TermOperator;

  constructor(private readonly kind: Kind, private readonly expression: string, private readonly start: number,
              private readonly end: number) {
    this.kind = kind;
    this.expression = expression;
    this.start = start;
    this.end = end;
  }

  static getKind(strToken: string): Kind {
    if (strToken.startsWith(Token.COMMENT_START)) {
      return Kind.COMMENT;
    } else if (strToken === Token.FUNCTION_ARG_SEPARATOR) {
      return Kind.FUNCTION_SEPARATOR;
    } else if (TermFunction.exists(strToken)) {
      return Kind.FUNCTION;
    } else if (TermOperator.exists(strToken)) {
      return Kind.OPERATOR;
    } else if (strToken === Token.OPEN_BRACKET) {
      return Kind.OPEN_BRACKET;
    } else if (strToken === Token.CLOSE_BRACKET) {
      return Kind.CLOSE_BRACKET;
    } else {
      return Kind.LITERAL;
    }
  }

  getKind(): Kind {
    return this.kind;
  }

  getExpression(): string {
    return this.expression;
  }

  getContent(): string {
    return this.expression.substring(this.start, this.end);
  }

  getStartPosition(): number {
    return this.start;
  }

  getEndPosition(): number {
    return this.end;
  }

  getError(): TermError {
    return this.error;
  }

  hasError(): boolean {
    return !!this.error;
  }

  assignError(error: string, args?: TermItem[]): TermError {
    this.error = new TermError(error, this, args);
    return this.error;
  }

  isOpenBracket(): boolean {
    return this.kind === Kind.OPEN_BRACKET;
  }

  isCloseBracket(): boolean {
    return this.kind === Kind.CLOSE_BRACKET;
  }

  isFunctionArgumentSeparator(): boolean {
    return this.kind === Kind.FUNCTION_SEPARATOR;
  }

  isFunction(): boolean {
    return this.kind === Kind.FUNCTION;
  }

  isOperator(): boolean {
    return this.kind === Kind.OPERATOR;
  }

  isLiteral(): boolean {
    return this.kind === Kind.LITERAL;
  }

  isComment(): boolean {
    return this.kind === Kind.COMMENT;
  }

  setOperator(operator: TermOperator): void {
    if (!this.isOperator()) {
      throw new Error("Not an operator");
    }
    this.operator = operator;
  }

  getOperator(): TermOperator {
    if (!this.isOperator()) {
      throw new Error("Not an operator");
    }
    return this.operator;
  }

  setFunction(fct: TermFunction): void {
    if (!this.isFunction()) {
      throw new Error("Not a function");
    }
    this.function = fct;
  }

  getFunction(): TermFunction {
    if (!this.isFunction()) {
      throw new Error("Not a function");
    }
    return this.function;
  }

  getLiteral(): string {
    if (!this.isLiteral()) {
      throw new Error("Not a literal");
    }
    let literal = this.getContent();
    if (literal.match(/^'[^']*'$/) || literal.match(/^"[^"]*"$/)) {
      return literal.substring(1, literal.length - 1);
    }
    return literal;
  }

}
