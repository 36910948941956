import {Injectable, OnDestroy} from '@angular/core';
import {AuthAccessToken, Permission} from './auth-token.models';
import {AuthService, IdToken} from '@auth0/auth0-angular';
import {Subscription} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService implements OnDestroy {

  private idToken: IdToken;
  private accessToken: AuthAccessToken;
  private _isAuthenticated: boolean;
  private authServiceIsAuthenticatedSubscription: Subscription;
  private getAccessTokenSubscription: Subscription;
  private getIdTokenSubscription: Subscription;

  constructor(private authService: AuthService) {
    this.authServiceIsAuthenticatedSubscription = this.authService.isAuthenticated$.subscribe({
      next: (isAuthenticated: boolean): void => {
        this._isAuthenticated = isAuthenticated;
      },
      error: (error): void => {
        console.error(error.message);
      }
    });

    this.getIdTokenSubscription = this.authService.idTokenClaims$.subscribe({
      next: (idToken: IdToken): void => {
        this.idToken = idToken;

        if (idToken) {
          this.getAccessTokenSubscription = this.authService.getAccessTokenSilently().subscribe({
            next: (token: string): void => {
              this.accessToken = new JwtHelperService().decodeToken(token);
            },
            error: (error): void => {
              console.error(error);
              this.authService.loginWithRedirect();
            }
          })
        }
      },
      error: (error): void => {
        console.error(error.message);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  unsubscribe(): void {
    this.getAccessTokenSubscription?.unsubscribe();
    this.authServiceIsAuthenticatedSubscription?.unsubscribe();
    this.getIdTokenSubscription?.unsubscribe();
  }

  logout(): void {
    this.unsubscribe();
    this.authService.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  getIdToken(): IdToken {
    return this.idToken;
  }

  getAccessToken(): AuthAccessToken {
    return this.accessToken;
  }

  getUsername(): string {
    return this.idToken?.name;
  }

  getCustomerId(): string {
    return this.idToken?.customerId;
  }

  isRuleLibCustomer(): boolean {
    return this.getCustomerId() === '000000000000000000000000';
  }

  getPermissions(): string[] {
    return this.accessToken?.permissions;
  }

  hasPermission(permission: Permission): boolean {
    return this.getPermissions()?.includes(permission.name);
  }

  isAuthenticated(): boolean {
    return this._isAuthenticated;
  }
}
