<div>
  <table [dataSource]="dataSource" class="events-table" mat-table
         matSort
         matSortActive="timestamp"
         matSortDirection="desc"
         matSortDisableClear>

    <ng-container matColumnDef="Timestamp">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="timestamp">Timestamp</th>
      <td *matCellDef="let event" class="timestamp"
          mat-cell>{{ (event.timestamp * 1000)|date: 'dd.MM.yyyy HH:mm:ss.SSS' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Building">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="customerBuildingId">Building</th>
      <td *matCellDef="let event" class="building" mat-cell>
        <a [routerLink]="['/buildings/', event.customerBuildingId]" queryParamsHandling="merge">
          {{ event.customerBuildingId }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="Type">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="type">Type</th>
      <td *matCellDef="let event" class="type" mat-cell>{{ event.type }}</td>
    </ng-container>

    <ng-container matColumnDef="Source">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="source">Source</th>
      <td *matCellDef="let event" class="source" mat-cell>{{ event.source }}</td>
    </ng-container>

    <ng-container matColumnDef="Value">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="value">Value</th>
      <td *matCellDef="let event" class="value" mat-cell>{{ event.value }}</td>
    </ng-container>

    <ng-container matColumnDef="Unit">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="unit"></th>
      <td *matCellDef="let event" class="unit" mat-cell>{{ DataUnit.ofEventType(event.type).symbol }}</td>
    </ng-container>

    <ng-container matColumnDef="Forecast">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="forecastTimestamp">Forecast</th>
      <td *matCellDef="let event" class="forecast" mat-cell>
        {{ event?.forecastTimestamp > 0 ? (event?.forecastTimestamp * 1000 | date: 'dd.MM.yyyy HH:mm:ss') : "" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="DeviceName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="deviceName">Device Name</th>
      <td *matCellDef="let event" class="device-name" mat-cell>{{ event?.deviceName }}</td>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="9999">
        No data matching the search
      </td>
    </tr>
  </table>

  <div *ngIf="dataSource.loading$ | async" class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>

  <mat-paginator [hidden]="dataSource.loading$ | async"
                 [length]="dataSource.length$ | async"
                 [pageSizeOptions]="[5, 10, 15, 20, 25]"
                 [pageSize]="10">
  </mat-paginator>
</div>
