import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {IPushNotification, MatColumnName, NotificationLanguage, NotificationsFilter} from '../notifications.models';
import {NotificationsDatasource} from './notifications.datasource';
import {NotificationsService} from '../notifications.service';
import {merge, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {LanguageService} from '../../shared-module/language.service';
import {Customer} from '../../shared-module/customer.model';
import {CustomerService} from '../../shared-module/customer.service';

@Component({
  selector: 'app-moost-notifications-table',
  templateUrl: './moost-notifications-table.component.html',
  styleUrls: ['./moost-notifications-table.component.scss'],
  standalone: false
})
export class MoostNotificationsTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  protected notificationsService: NotificationsService = inject(NotificationsService);
  protected languageService: LanguageService = inject(LanguageService);
  protected customerService: CustomerService = inject(CustomerService);

  private customerSubscription: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() filter: NotificationsFilter;
  allColumns: MatColumnName[] = [
    {displayName: 'Timestamp', columnDefName: 'createdAtTimeMillis'},
    {displayName: 'Building', columnDefName: 'Building'},
    {displayName: 'Rule', columnDefName: 'Rule'},
    {displayName: 'Title', columnDefName: 'Title'},
    {displayName: 'Message', columnDefName: 'Message'},
    {displayName: 'Status', columnDefName: 'Status'},
    {displayName: 'Reason', columnDefName: 'Reason'},
    {displayName: 'Interaction', columnDefName: 'Interaction'}
  ];
  visibleColumns: string[] = ['createdAtTimeMillis', 'Building', 'Title', 'Message', 'Status'];
  dataSource: NotificationsDatasource;
  notificationsCount: number = 0;
  notifications: IPushNotification[];
  columnSelectionForm: FormGroup = new FormGroup({});
  selectedDisplayNotificationLanguage: NotificationLanguage;
  availableNotificationLanguages: NotificationLanguage[];

  constructor() {
    this.columnSelectionForm = new FormGroup({
      columnSelectionField: new FormControl<string[]>(this.visibleColumns),
    });
  }

  ngOnDestroy(): void {
    this.customerSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.dataSource = new NotificationsDatasource(this.notificationsService);
    this.dataSource.length$.subscribe((notificationsCount: number) => {
      this.notificationsCount = notificationsCount;
    });
    this.dataSource.notifications$.subscribe((notifications: IPushNotification[]) => {
      this.notifications = notifications;
    });
    this.customerSubscription = this.customerService.getCustomer().subscribe((customer: Customer) => {
      this.availableNotificationLanguages = this.languageService.getNotificationLanguages(customer.notificationLanguages);
      this.selectedDisplayNotificationLanguage = this.availableNotificationLanguages[0];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter || changes.paginator) {
      if (this.paginator && this.filter != null) {
        this.paginator.firstPage();
      }
      this.loadNotifications();
    }
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadNotifications())
      )
      .subscribe();
  }

  loadNotifications(): void {
    if (this.dataSource) {
      this.dataSource.clear();
      this.dataSource.loadNotifications(
        this.filter,
        this.sort?.active,
        this.sort?.direction,
        this.paginator?.pageIndex,
        this.paginator?.pageSize);
    }
  }

  setVisibleColumns(columns: string[]): void {
    this.visibleColumns = columns;
  }
}
