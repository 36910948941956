import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Dataset, DatasetType} from '../../../rules-module/rules.models';
import {Customer, EventCategory} from '../../../shared-module/customer.model';
import {TitleCasePipe} from '@angular/common';
import {CustomerService} from '../../../shared-module/customer.service';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {EventsService} from '../../../events-module/events.service';

@Component({
  selector: 'app-moost-building-events',
  templateUrl: './moost-building-events.component.html',
  styleUrls: ['./moost-building-events.component.scss'],
  standalone: false
})
export class MoostBuildingEventsComponent implements OnInit, OnDestroy {
  @Input() customerBuildingId: string;
  @Input() startTimeRangeMillis: number;
  @Input() endTimeRangeMillis: number;
  private customerService: CustomerService = inject(CustomerService);
  private eventsService: EventsService = inject(EventsService);
  private loadCustomerSubscription: Subscription;
  private loadEventTypesSubscription: Subscription;
  private loadSourceTypesSubscription: Subscription;
  isLoadingEventTypes: boolean = true;
  isLoadingSourceTypes: boolean = true;
  private customerEventCategories: EventCategory[];
  private eventTypes: string[];
  private eventSources: string[];
  eventCategorySelectionSource: EventCategory[] = [];
  eventDatasetSelection: Dataset[] = [];
  eventCategorySelectionForm: FormGroup;
  eventTypesChoice: string[];
  eventSourcesChoice: string[];
  readonly displayedColumnsEventDatasets: string[] = ["type", "source", "action"];
  errorMessage: string = '';

  private readonly MAX_SELECTION_ENTRIES: number = 10;
  private readonly DEFAULT_EVENT_CATEGORY_SELECTION: EventCategory[] = [
    {
      type: "POWER_CONSUMPTION",
      source: "GATEWAY"
    },
    {
      type: "POWER_CONSUMPTION_FORECAST_1H",
      source: "MOOST"
    },
    {
      type: "POWER_GENERATION",
      source: "GATEWAY"
    },
    {
      type: "POWER_GENERATION_FORECAST_1H",
      source: "GATEWAY"
    },
    {
      type: "GRID_POWER_CONSUMPTION",
      source: "GATEWAY"
    },
    {
      type: "POWER_CONSUMPTION_BASE_LOAD",
      source: "MOOST"
    }
  ];

  ngOnInit(): void {
    this.eventCategorySelectionForm = new FormGroup({
      eventType: new FormControl<string[]>(this.eventTypesChoice),
      eventSource: new FormControl<string[]>(this.eventSourcesChoice),
    });
    this.loadCustomerSubscription = this.customerService.getCustomer().subscribe((customer: Customer): void => {
      this.customerEventCategories = customer.eventCategories?.length > 0 ? customer.eventCategories.sort(
        (a: EventCategory, b: EventCategory): number => a.type.localeCompare(b.type)) : undefined;
      this.setEventCategoryAndDatasetSelection(this.buildInitialEventCategorySelection(this.customerEventCategories));
      if (this.customerEventCategories) {
        this.eventTypes = [...new Set(this.customerEventCategories.map(it => it.type))];
        this.eventSources = [...new Set(this.customerEventCategories.map(it => it.source))];
        this.isLoadingEventTypes = false;
        this.isLoadingSourceTypes = false;
        this.updateSelectionsInEventCategorySelectionForm();
      } else {
        this.loadEventTypesSubscription = this.eventsService.getEventTypes().subscribe((types: string[]): void => {
          this.eventTypes = types;
          this.isLoadingEventTypes = false;
          this.updateSelectionsInEventCategorySelectionForm();
        });
        this.loadSourceTypesSubscription = this.eventsService.getSourceTypes().subscribe((sources: string[]): void => {
          this.eventSources = sources;
          this.isLoadingSourceTypes = false;
          this.updateSelectionsInEventCategorySelectionForm();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.loadCustomerSubscription?.unsubscribe();
    this.loadEventTypesSubscription?.unsubscribe();
    this.loadSourceTypesSubscription?.unsubscribe();
  }

  private buildInitialEventCategorySelection(customerEventCategories: EventCategory[]): EventCategory[] {
    let eventCategories: EventCategory[] = [];
    if (customerEventCategories) {
      eventCategories.push(...customerEventCategories.filter(it => ['GRID_ENERGY_CONSUMPTION', 'ENERGY_CONSUMPTION', 'ENERGY_GENERATION', 'ENERGY_EXCESS'].includes(it.type) && it.source === 'GATEWAY'));
      eventCategories.push(...customerEventCategories.filter(it => ['GRID_POWER_CONSUMPTION', 'POWER_CONSUMPTION', 'POWER_GENERATION', 'POWER_EXCESS'].includes(it.type) && it.source === 'GATEWAY'));
    }
    if (eventCategories.length === 0) {
      eventCategories = this.DEFAULT_EVENT_CATEGORY_SELECTION;
    }
    return eventCategories;
  }

  private buildEventDatasetList(eventCategories: EventCategory[]): Dataset[] {
    const titleCasePipe = new TitleCasePipe();
    return eventCategories
      .filter(it => it !== undefined)
      .map(it => {
        const dataset: Dataset = {
          name: titleCasePipe.transform(it.type.replaceAll('_', ' ')).replaceAll(' ', ''),
          type: DatasetType.SINGLEVALUE,
          event_types: [it.type],
          source_types: [it.source]
        };
        return dataset
      });
  }

  private setEventCategoryAndDatasetSelection(eventCategories: EventCategory[]): void {
    this.eventCategorySelectionSource = eventCategories.filter(it => it !== undefined);
    if (this.eventCategorySelectionSource.length < this.MAX_SELECTION_ENTRIES) {
      // add 'undefined', which is rendered by UI as 'add' row (unless max entries threshold reached)
      this.eventCategorySelectionSource.push(undefined);
    }
    this.eventDatasetSelection = this.buildEventDatasetList(this.eventCategorySelectionSource);
  }

  discardEventDataset(eventCategory: EventCategory): void {
    this.setEventCategoryAndDatasetSelection(this.eventCategorySelectionSource.filter(it => it !== eventCategory));
  }

  addEventDataset(): void {
    const selectedType: string = this.eventCategorySelectionForm.get("eventType").value;
    const selectedSource: string = this.eventCategorySelectionForm.get("eventSource").value;
    if (!selectedType) {
      this.errorMessage = 'No event type selected.';
    } else if (!selectedSource) {
      this.errorMessage = 'No event source selected.';
    } else {
      const isAlreadyInSelection: EventCategory = this.eventCategorySelectionSource.filter(it => it !== undefined)
        .find(it => it.type === selectedType && it.source === selectedSource);
      if (isAlreadyInSelection) {
        this.errorMessage = 'Already added to selection.';
      } else {
        const addedEventCategory: EventCategory = {type: selectedType, source: selectedSource};
        this.setEventCategoryAndDatasetSelection([...this.eventCategorySelectionSource, addedEventCategory]);
        this.eventCategorySelectionForm.get("eventType").reset();
        this.eventCategorySelectionForm.get("eventSource").reset();
        this.errorMessage = '';
      }
    }
  }

  updateSelectionsInEventCategorySelectionForm(): void {
    if (this.customerEventCategories) {
      const selectedType: string = this.eventCategorySelectionForm.get("eventType").value;
      this.eventTypesChoice = [...new Set(this.customerEventCategories
        .map(it => it.type))];
      this.eventSourcesChoice = [...new Set(this.customerEventCategories
        .filter(it => !selectedType || it.type === selectedType)
        .map(it => it.source))];
      if (this.eventSourcesChoice.length === 1) {
        this.eventCategorySelectionForm.controls.eventSource.setValue(this.eventSourcesChoice[0]);
      }
    } else {
      this.eventTypesChoice = this.eventTypes;
      this.eventSourcesChoice = this.eventSources;
    }
  }
}
