<div *ngIf="progressState.isProgressing || progressState.isError" class="container">
  <mat-spinner *ngIf="progressState.isProgressing">
  </mat-spinner>

  <mat-error *ngIf="progressState.isError">
    <div class="info-box-container">
      <div class="info-box-item">
        <mat-icon fontIcon="error"></mat-icon>
      </div>
      <div class="info-box-item">
        <div *ngIf="progressState.header" class="header">
          {{ progressState.header }}
        </div>
        <div *ngIf="progressState.message" class="message">
          {{ progressState.message }}
        </div>
      </div>
    </div>
  </mat-error>

  <div *ngIf="!progressState.isError">
    <p *ngIf="progressState.header" class="header">
      {{ progressState.header }}
    </p>
    <p *ngIf="progressState.message" class="message">
      {{ progressState.message }}
    </p>
  </div>
</div>
