<mat-chip
  [matTooltip]="'Event Types: ' + dataset.event_types?.join(',') +'\n' +
     'Source Types: ' + (dataset.source_types ? dataset.source_types.join(',') : '-')"
  matTooltipClass="multiline"
>
  <div [style.background-color]="color" matChipAvatar></div>
  <p>
    {{ dataset.name }}
    <span class="summary">{{ getSummary() }}</span>
  </p>
  <button (click)="removeDataset(dataset.name)" aria-label="Remove dataset" matChipRemove>
    <mat-icon>cancel</mat-icon>
  </button>
</mat-chip>
