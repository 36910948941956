<div class="page-content">
  <h2>Add fulfillment preference</h2>

  <mat-stepper>
    <mat-step [stepControl]="typeForm" errorMessage="type is required.">
      <form [formGroup]="typeForm">
        <ng-template matStepLabel>Choose type</ng-template>
        <h2>Choose type</h2>
        <mat-radio-group
          aria-labelledby="Select an option"
          class="radio-group"
          formControlName="type">
          <mat-radio-button
            (change)="radioChange($event)"
            *ngFor="let type of FulfillmentPreference.TYPE_CHOICE"
            [value]="type"
            class="radio-button">
            {{ type }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="button-container">
          <button (click)="cancel()" mat-button>CANCEL</button>
          <button (click)="next()" mat-button matStepperNext>NEXT</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="settingsForm" errorMessage="Configuration is required.">
      <form (ngSubmit)="onSubmit($event)" [formGroup]="settingsForm">
        <ng-template matStepLabel>Configure settings</ng-template>
        <div *ngIf="type === 'REST'">
          <h2>Configuration of your rest service</h2>
          <input formControlName="type"
                 hidden="hidden"
                 matInput
                 type="text"
                 value="REST">
          <div class="form-container">
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Choose an auth strategy</mat-label>
              <mat-select formControlName="restAuthStrategy">
                <mat-option
                  (onSelectionChange)="changeAuthStrategy($event)"
                  *ngFor="let restAuthStrategy of FulfillmentPreference.REST_AUTH_STRATEGY_CHOICE"
                  [value]="restAuthStrategy">
                  {{ restAuthStrategy }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_APITOKEN"
                            appearance="outline" class="form-item">
              <mat-label>Enter an API token</mat-label>
              <input formControlName="restApiToken" matInput type="text">
              <mat-icon class="material-icons-outlined" matSuffix>settings</mat-icon>
            </mat-form-field>
            <mat-form-field *ngIf="restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BEARER"
                            appearance="outline" class="form-item">
              <mat-label>Enter a bearer token</mat-label>
              <input formControlName="restBearerToken" matInput type="text">
              <mat-icon class="material-icons-outlined" matSuffix>settings</mat-icon>
            </mat-form-field>
          </div>
          <div *ngIf="restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BASIC" class="form-container">
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Enter a basic auth user</mat-label>
              <input formControlName="restBasicAuthUser" matInput type="text">
              <mat-icon class="material-icons-outlined" matSuffix>person</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Enter a basic auth password</mat-label>
              <input formControlName="restBasicAuthPassword" matInput type="text">
              <mat-icon class="material-icons-outlined" matSuffix>password</mat-icon>
            </mat-form-field>
          </div>
          <div class="form-container">
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Enter a hostname</mat-label>
              <input formControlName="restHostname" matInput type="text">
              <mat-icon class="material-icons-outlined" matSuffix>display_settings</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Enter a path</mat-label>
              <input formControlName="restPath" matInput type="text">
              <mat-icon class="material-icons-outlined" matSuffix>network_ping</mat-icon>
            </mat-form-field>
          </div>
          <div class="form-container">
            <mat-slide-toggle (change)="isSecureToggle($event)"
                              [checked]="restIsSecure"
                              class="form-item"
                              color="primary"
                              formControlName="restIsSecure">is secure
            </mat-slide-toggle>
          </div>
        </div>

        <div *ngIf="type === 'EMAIL'">
          <h2>Configuration of your email service</h2>
          <div class="form-container">
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Enter an email address</mat-label>
              <input autocomplete="false"
                     formControlName="recipientEmailAddress"
                     matInput
                     placeholder="hans.muster@moost.io"
                     type="email">
              <mat-icon class="material-icons-outlined" matSuffix>email</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Enter a building Id</mat-label>
              <input autocomplete="false"
                     formControlName="customerBuildingId"
                     matInput
                     type="text">
              <mat-icon class="material-icons-outlined" matSuffix>apartment</mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="type === 'FIREBASE_CLOUD_MESSAGING'">
          <h2>Configuration of your FCM Token</h2>
          <input formControlName="type"
                 hidden="hidden"
                 matInput
                 type="text"
                 value="FIREBASE_CLOUD_MESSAGING">
          <div class="form-container">
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Enter a valid FCM Token</mat-label>
              <input formControlName="fcmToken" matInput type="text">
              <mat-icon class="material-icons-outlined" matSuffix>token</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-item">
              <mat-label>Enter a building Id</mat-label>
              <input formControlName="customerBuildingId" matInput type="text">
              <mat-icon class="material-icons-outlined" matSuffix>apartment</mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div class="button-container">
          <button (click)="cancel()" mat-button>CANCEL</button>
          <button mat-button matStepperPrevious>BACK</button>
          <button mat-button matStepperNext>NEXT</button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p *ngIf="settingsForm.valid">
        <mat-icon>check_circle</mat-icon>
        Please <b>save</b> your new fulfillment preference.
      </p>
      <p *ngIf="!settingsForm.valid">
        <mat-icon>cancel</mat-icon>
        <b>Error:</b> please check and correct your configuration.
      </p>
      <div class="button-container">
        <button (click)="cancel()" mat-button>CANCEL</button>
        <button mat-button matStepperPrevious>BACK</button>
        <button (click)="create()" [disabled]="!settingsForm.valid" class="create" mat-button>SAVE</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
