import {Dataset, IDataset, INotification, Rule} from '../rules.models';

export class RuleComparator {

  static haveSameSettings(rule1: Rule, rule2: Rule): boolean {
    return rule1.name === rule2.name &&
      rule1.description === rule2.description &&
      rule1.ruleState === rule2.ruleState &&
      rule1.match_threshold === rule2.match_threshold &&
      rule1.time_between_triggers_seconds === rule2.time_between_triggers_seconds &&
      rule1.resetStateWhenMatched === rule2.resetStateWhenMatched &&
      rule1.condition === rule2.condition &&
      rule1.streakCondition === rule2.streakCondition &&
      rule1.isRestrictedToEarlyAdopters === rule2.isRestrictedToEarlyAdopters &&
      rule1.isTimeBased === rule2.isTimeBased &&
      rule1.timeBasedCron === rule2.timeBasedCron &&
      this.haveSameNotificationSettings(rule1.notification, rule2.notification) &&
      this.haveSameDatasetsSettings(rule1.datasets, rule2.datasets);
  }

  static haveSameNotificationSettings(notification1: INotification, notification2: INotification): boolean {
    return JSON.stringify(notification1) === JSON.stringify(notification2);
  }

  static haveSameDatasetsSettings(dataset1: IDataset[], dataset2: IDataset[]): boolean {
    return JSON.stringify(dataset1?.sort((a: Dataset, b: Dataset) => a.name.localeCompare(b.name))) === JSON.stringify(dataset2?.sort((a: Dataset, b: Dataset) => a.name.localeCompare(b.name)));
  }
}
