import {Component, EventEmitter, OnDestroy, OnInit, Output,} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {EMPTY, Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {catchError, finalize} from 'rxjs/operators';
import {Rule, RulesFilter} from '../rules.models';
import {EventsService} from '../../events-module/events.service';

export const RESTRICTION_EARLY_ADOPTER: string = 'Early Adopter';
export const RESTRICTION_UNSRESTRICTED: string = 'Unrestricted';
export const TRIGGER_TIME_BASED: string = 'Time-based';
export const TRIGGER_EVENT_BASED: string = 'Event-based';
export const CATEGORY_STREAK: string = 'Streak';
export const CATEGORY_NON_STREAK: string = 'Non-Streak';
export const ORIGIN_LIBRARY: string = 'Library';
export const ORIGIN_SELF_CREATED: string = 'Self-Created';

@Component({
  selector: 'app-moost-rules-filter',
  templateUrl: './moost-rules-filter.component.html',
  styleUrls: ['./moost-rules-filter.component.scss']
})

export class MoostRulesFilterComponent implements OnInit, OnDestroy {
  @Output() applyFilterChange: EventEmitter<RulesFilter> = new EventEmitter<RulesFilter>()
  filterForm: FormGroup;
  filter: RulesFilter;
  statusesChoice: string[] = [Rule.ACTIVE, Rule.PAUSE];
  categoriesChoice: string[] = [CATEGORY_STREAK, CATEGORY_NON_STREAK];
  originsChoice: string[] = [ORIGIN_LIBRARY, ORIGIN_SELF_CREATED];
  eventTypesChoice: string[] = [];
  sourceTypesChoice: string[] = [];
  isLoadingEventTypes: boolean = false;
  isLoadingSourceTypes: boolean = false;
  restrictionsChoice: string[] = [RESTRICTION_EARLY_ADOPTER, RESTRICTION_UNSRESTRICTED];
  triggersChoice: string[] = [TRIGGER_TIME_BASED, TRIGGER_EVENT_BASED];
  private loadEventTypesSubscription: Subscription;
  private loadSourceTypesSubscription: Subscription;
  private valueChangesSubscription: Subscription;

  constructor(private eventsService: EventsService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.filter = new RulesFilter();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      this.filter = new RulesFilter(
        queryParams['searchText'],
        queryParams['status']?.split(",").filter((it: string) => it?.length > 0),
        queryParams['restriction']?.split(",").filter((it: string) => it?.length > 0),
        queryParams['trigger']?.split(",").filter((it: string) => it?.length > 0),
        queryParams['category']?.split(",").filter((it: string) => it?.length > 0),
        queryParams['origin']?.split(",").filter((it: string) => it?.length > 0),
        queryParams['eventType']?.split(",").filter((it: string) => it?.length > 0),
        queryParams['sourceType']?.split(",").filter((it: string) => it?.length > 0),
      );
      this.buildForm(this.filter);
      this.loadEventTypes();
      this.loadSourceTypes();
      this.setFilter(this.filter);
    });
  }

  ngOnDestroy(): void {
    this.loadEventTypesSubscription?.unsubscribe();
    this.loadSourceTypesSubscription?.unsubscribe();
    this.valueChangesSubscription?.unsubscribe();
  }

  clearFilter(): void {
    this.resetForm(this.filterForm);
  }

  private setFilter(filter: RulesFilter): void {
    this.applyFilterChange.emit(filter);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          searchText: filter.searchText,
          status: filter.statuses?.join(","),
          restriction: filter.restrictions?.join(","),
          trigger: filter.triggers?.join(","),
          category: filter.categories?.join(","),
          origin: filter.origins?.join(","),
          eventType: filter.eventTypes?.join(","),
          sourceType: filter.sourceTypes?.join(","),
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private loadEventTypes(): void {
    this.filterForm.get("eventTypes").disable();
    this.isLoadingEventTypes = true;
    this.loadEventTypesSubscription = this.eventsService.getEventTypes().pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoadingEventTypes = false)
    ).subscribe((eventTypes: string[]): void => {
      this.eventTypesChoice = eventTypes;
      this.filterForm.get("eventTypes").enable();
    });
  }

  private loadSourceTypes(): void {
    this.filterForm.get("sourceTypes").disable();
    this.isLoadingSourceTypes = true;
    this.loadSourceTypesSubscription = this.eventsService.getSourceTypes().pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoadingSourceTypes = false)
    ).subscribe((sourceTypes: string[]): void => {
      this.sourceTypesChoice = sourceTypes;
      this.filterForm.get("sourceTypes").enable();
    });
  }

  private buildForm(filter: RulesFilter): void {
    this.filterForm = new FormGroup({
      searchText: new FormControl<string>(filter?.searchText),
      statuses: new FormControl<string[]>(filter?.statuses),
      restrictions: new FormControl<string[]>(filter?.restrictions),
      triggers: new FormControl<string[]>(filter?.triggers),
      categories: new FormControl<string[]>(filter?.categories),
      origins: new FormControl<string[]>(filter?.origins),
      eventTypes: new FormControl<string[]>(filter?.eventTypes),
      sourceTypes: new FormControl<string[]>(filter?.sourceTypes),
    });
    this.filterForm.get("eventTypes").disable();
    this.filterForm.get("sourceTypes").disable();
    this.valueChangesSubscription = this.filterForm.valueChanges.subscribe(() => {
      if (this.filterForm.valid && this.filterForm.get("eventTypes").enabled && this.filterForm.get("sourceTypes")) {
        this.setFilter(this.buildRulesFilter(this.filterForm));
      }
    });
  }

  private resetForm(filterForm: FormGroup): void {
    filterForm.get("searchText").reset();
    filterForm.get("statuses").reset();
    filterForm.get("restrictions").reset();
    filterForm.get("triggers").reset();
    filterForm.get("categories").reset();
    filterForm.get("origins").reset();
    filterForm.get("eventTypes").reset();
    filterForm.get("sourceTypes").reset();
  }

  private buildRulesFilter(filterForm: FormGroup): RulesFilter {
    return new RulesFilter(
      filterForm.get("searchText").value,
      filterForm.get("statuses").value,
      filterForm.get("restrictions").value,
      filterForm.get("triggers").value,
      filterForm.get("categories").value,
      filterForm.get("origins").value,
      filterForm.get("eventTypes").value,
      filterForm.get("sourceTypes").value,
    );
  }
}
