<div class="filter-bar">
  <form [formGroup]="filterForm" class="notifications-filter">

    <mat-form-field appearance="outline">
      <mat-label>Customer Building ID</mat-label>
      <input formControlName="customerBuildingIds" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="ruleIds-field">
      <mat-label>Rules</mat-label>
      <mat-select formControlName="ruleIds" multiple>
        @for (ruleId of getRuleIdsSortedByName(); track ruleId) {
          <mat-option [value]="ruleId">
            {{ getRule(ruleId).name }}
          </mat-option>
        }
      </mat-select>
      @if (isLoadingRules) {
        <mat-spinner [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
      }
    </mat-form-field>

    <mat-form-field appearance="outline" class="delivery-statuses-field">
      <mat-label>Delivery Status</mat-label>
      <mat-select formControlName="deliveryStatuses" multiple>
        @for (deliveryStatus of deliveryStatusesChoice; track deliveryStatus) {
          <mat-option [value]="deliveryStatus">
            {{ deliveryStatus }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="interactions-field">
      <mat-label>Interactions</mat-label>
      <mat-select formControlName="interactions" multiple>
        @for (interaction of interactionsChoice; track interaction) {
          <mat-option [value]="interaction">
            {{ interaction }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" formGroupName="range">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [max]="maxDate" [rangePicker]="picker">
        <input formControlName="startTimestampMillis" matStartDate
               placeholder="Start date">
        <input formControlName="endTimestampMillis" matEndDate
               placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>

  <button (click)="clearFilter()" color="gray" mat-icon-button
          matTooltip="Clear filter">
    <mat-icon>delete_outline</mat-icon>
  </button>
</div>
