import { Component, Input, inject } from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {MoostHeaderService} from './moost-header.service';

@Component({
    selector: 'app-moost-header',
    templateUrl: './moost-header.component.html',
    styleUrls: ['./moost-header.component.scss'],
    standalone: false
})
export class MoostHeaderComponent {
  headerService = inject(MoostHeaderService);

  @Input() drawer: MatSidenav;

}
