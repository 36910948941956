import {TermStructure} from './term-structure';
import {TermItem} from './term-item';
import {TermType} from './term-type';

export class AttributeAccessor {
  static readonly NAMES: string[] = [];
  static readonly DEVICE_NAME: string = AttributeAccessor.register('DeviceName');
  static readonly DEVICE_ID: string = AttributeAccessor.register('DeviceId');
  static readonly TIMESTAMP: string = AttributeAccessor.register('Timestamp');
  static readonly FORECAST_TIMESTAMP: string = AttributeAccessor.register('ForecastTimestamp');
  static readonly VALUE: string = AttributeAccessor.register('Value');
  static readonly FIRST: string = AttributeAccessor.register('First');
  static readonly LAST: string = AttributeAccessor.register('Last');
  static readonly GROUP_KEY_DEVICE_NAME: string = AttributeAccessor.register('GroupKeyDeviceName');
  static readonly GROUP_KEY_DEVICE_ID: string = AttributeAccessor.register('GroupKeyDeviceId');
  static readonly GROUP_KEY: string = AttributeAccessor.register("GroupKey");
  static readonly GROUP_VALUE: string = AttributeAccessor.register('GroupValue');
  static readonly ID: string = AttributeAccessor.register('Id');
  static readonly REGISTER_TIMESTAMP: string = AttributeAccessor.register('RegistrationTimestamp');
  static readonly ACTIVATION_TIMESTAMP: string = AttributeAccessor.register('ActivationTimestamp');
  static readonly DEVICE_TYPES: string = AttributeAccessor.register('DeviceTypes');
  static readonly ECONOMICAL_MOTIVATION_SCORE: string = AttributeAccessor.register('EconomicalMotivationScore');
  static readonly ECOLOGICAL_MOTIVATION_SCORE: string = AttributeAccessor.register('EcologicalMotivationScore');
  static readonly SELF_SUFFICIENCY_MOTIVATION_SCORE: string = AttributeAccessor.register('SelfSufficiencyMotivationScore');
  static readonly MULTI_PERSON_SCORE: string = AttributeAccessor.register('MultiPersonScore');
  static readonly COMMERCIAL_BUILDING_SCORE: string = AttributeAccessor.register('CommercialBuildingScore');
  static readonly IS_SELF_SUFFICIENCY_MOTIVATED: string = AttributeAccessor.register('IsSelfSufficiencyMotivated');
  static readonly IS_ECOLOGICAL_MOTIVATED: string = AttributeAccessor.register('IsEcologicalMotivated');
  static readonly IS_ECONOMICAL_MOTIVATED: string = AttributeAccessor.register('IsEconomicalMotivated');
  static readonly IS_MULTI_PERSON: string = AttributeAccessor.register('IsMultiPerson');
  static readonly IS_SINGLE_PERSON: string = AttributeAccessor.register('IsSinglePerson');
  static readonly IS_COMMERCIAL: string = AttributeAccessor.register('IsCommercial');
  static readonly IS_RESIDENTIAL: string = AttributeAccessor.register('IsResidential');
  static readonly IS_IN_SAME_COUNTRY: string = AttributeAccessor.register('IsInSameCountry');
  static readonly IS_IN_SAME_CONSUMPTION_CATEGORY: string = AttributeAccessor.register('IsInSameConsumptionCategory');
  static readonly ALL: string = AttributeAccessor.register('All');


  static register(name: string): string {
    AttributeAccessor.NAMES.push(name);
    return name;
  }

  static getAllowedAccessors(structure: TermStructure): string[] {
    switch (structure) {
      case TermStructure.SCALAR:
        return [
          AttributeAccessor.DEVICE_NAME,
          AttributeAccessor.DEVICE_ID,
          AttributeAccessor.TIMESTAMP,
          AttributeAccessor.FORECAST_TIMESTAMP,
          AttributeAccessor.VALUE,
          AttributeAccessor.ID,
          AttributeAccessor.REGISTER_TIMESTAMP,
          AttributeAccessor.ACTIVATION_TIMESTAMP,
          AttributeAccessor.DEVICE_TYPES,
          AttributeAccessor.ECONOMICAL_MOTIVATION_SCORE,
          AttributeAccessor.ECOLOGICAL_MOTIVATION_SCORE,
          AttributeAccessor.SELF_SUFFICIENCY_MOTIVATION_SCORE,
          AttributeAccessor.MULTI_PERSON_SCORE,
          AttributeAccessor.COMMERCIAL_BUILDING_SCORE,
          AttributeAccessor.IS_SELF_SUFFICIENCY_MOTIVATED,
          AttributeAccessor.IS_ECOLOGICAL_MOTIVATED,
          AttributeAccessor.IS_ECONOMICAL_MOTIVATED,
          AttributeAccessor.IS_MULTI_PERSON,
          AttributeAccessor.IS_SINGLE_PERSON,
          AttributeAccessor.IS_COMMERCIAL,
          AttributeAccessor.IS_RESIDENTIAL,
          AttributeAccessor.IS_IN_SAME_COUNTRY,
          AttributeAccessor.IS_IN_SAME_CONSUMPTION_CATEGORY,
          AttributeAccessor.ALL
        ];
      case TermStructure.VECTOR:
        return [
          AttributeAccessor.DEVICE_NAME,
          AttributeAccessor.DEVICE_ID,
          AttributeAccessor.TIMESTAMP,
          AttributeAccessor.FORECAST_TIMESTAMP,
          AttributeAccessor.VALUE,
          AttributeAccessor.FIRST,
          AttributeAccessor.LAST
        ];
      case TermStructure.GROUPED_SCALAR:
        return [
          AttributeAccessor.DEVICE_NAME,
          AttributeAccessor.DEVICE_ID,
          AttributeAccessor.TIMESTAMP,
          AttributeAccessor.FORECAST_TIMESTAMP,
          AttributeAccessor.VALUE,
          AttributeAccessor.GROUP_KEY_DEVICE_NAME,
          AttributeAccessor.GROUP_KEY_DEVICE_ID,
          AttributeAccessor.GROUP_KEY,
          AttributeAccessor.GROUP_VALUE,
        ];
      case TermStructure.GROUPED_VECTOR:
        return [
          AttributeAccessor.DEVICE_NAME,
          AttributeAccessor.DEVICE_ID,
          AttributeAccessor.TIMESTAMP,
          AttributeAccessor.FORECAST_TIMESTAMP,
          AttributeAccessor.VALUE,
          AttributeAccessor.GROUP_KEY_DEVICE_NAME,
          AttributeAccessor.GROUP_KEY_DEVICE_ID,
          AttributeAccessor.GROUP_KEY,
          AttributeAccessor.FIRST,
          AttributeAccessor.LAST,
        ];
      default:
        return [];
    }
  }

  static getResultingType(accessor: string, item: TermItem): TermType {
    switch (accessor) {
      case AttributeAccessor.DEVICE_NAME:
      case AttributeAccessor.DEVICE_ID:
      case AttributeAccessor.GROUP_KEY_DEVICE_NAME:
      case AttributeAccessor.GROUP_KEY_DEVICE_ID:
      case AttributeAccessor.GROUP_KEY:
      case AttributeAccessor.ID:
      case AttributeAccessor.DEVICE_TYPES:
      case AttributeAccessor.IS_SELF_SUFFICIENCY_MOTIVATED:
      case AttributeAccessor.IS_ECOLOGICAL_MOTIVATED:
      case AttributeAccessor.IS_ECONOMICAL_MOTIVATED:
      case AttributeAccessor.IS_MULTI_PERSON:
      case AttributeAccessor.IS_SINGLE_PERSON:
      case AttributeAccessor.IS_COMMERCIAL:
      case AttributeAccessor.IS_RESIDENTIAL:
      case AttributeAccessor.IS_IN_SAME_COUNTRY:
      case AttributeAccessor.IS_IN_SAME_CONSUMPTION_CATEGORY:
      case AttributeAccessor.ALL:
        return TermType.TEXT;
      case AttributeAccessor.TIMESTAMP:
      case AttributeAccessor.FORECAST_TIMESTAMP:
      case AttributeAccessor.REGISTER_TIMESTAMP:
        return TermType.TIME;
      case AttributeAccessor.ACTIVATION_TIMESTAMP:
        return TermType.TIME;
      case AttributeAccessor.VALUE:
      case AttributeAccessor.ECONOMICAL_MOTIVATION_SCORE:
      case AttributeAccessor.ECOLOGICAL_MOTIVATION_SCORE:
      case AttributeAccessor.SELF_SUFFICIENCY_MOTIVATION_SCORE:
      case AttributeAccessor.MULTI_PERSON_SCORE:
      case AttributeAccessor.COMMERCIAL_BUILDING_SCORE:
        return TermType.NUMBER;
      case AttributeAccessor.FIRST:
        return item.type;
      case AttributeAccessor.LAST:
        return item.type;
      case AttributeAccessor.GROUP_VALUE:
        return item.type;
      default:
        throw Error(`Unsupported attribute accessor: ${accessor}`)
    }
  }

  static getResultingStructure(accessor: string, item: TermItem): TermStructure {
    if (item.isScalar()) {
      switch (accessor) {
        case AttributeAccessor.IS_SELF_SUFFICIENCY_MOTIVATED:
        case AttributeAccessor.IS_ECOLOGICAL_MOTIVATED:
        case AttributeAccessor.IS_ECONOMICAL_MOTIVATED:
        case AttributeAccessor.IS_MULTI_PERSON:
        case AttributeAccessor.IS_SINGLE_PERSON:
        case AttributeAccessor.IS_COMMERCIAL:
        case AttributeAccessor.IS_RESIDENTIAL:
        case AttributeAccessor.IS_IN_SAME_COUNTRY:
        case AttributeAccessor.IS_IN_SAME_CONSUMPTION_CATEGORY:
        case AttributeAccessor.ALL:
        case AttributeAccessor.DEVICE_TYPES:
          return TermStructure.VECTOR;
        default:
          return TermStructure.SCALAR;
      }
    } else if (item.isVector()) {
      if (accessor === AttributeAccessor.FIRST || accessor === AttributeAccessor.LAST) {
        return TermStructure.SCALAR
      }
      return TermStructure.VECTOR;
    } else if (item.isGroupedScalar()) {
      return TermStructure.VECTOR;
    } else if (item.isGroupedVector()) {
      if (accessor === AttributeAccessor.FIRST || accessor === AttributeAccessor.LAST) {
        return TermStructure.GROUPED_SCALAR;
      } else if (accessor === AttributeAccessor.GROUP_KEY_DEVICE_ID ||
        accessor === AttributeAccessor.GROUP_KEY_DEVICE_NAME ||
        accessor == AttributeAccessor.GROUP_KEY) {
        return TermStructure.VECTOR;
      } else {
        return TermStructure.GROUPED_VECTOR;
      }
    }
    throw Error(`Unsupported attribute accessor: ${accessor} on ${item.getStructureType()}`);
  }

}

