import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {Permission} from '../../auth-token-module/auth-token.models';

@Component({
  selector: 'app-moost-settings-overview',
  templateUrl: './moost-settings-overview.component.html',
  styleUrls: ['./moost-settings-overview.component.scss']
})
export class MoostSettingsOverviewComponent {

  protected readonly Permission = Permission;

  constructor(private headerService: MoostHeaderService, protected authTokenService: AuthTokenService,
              private router: Router, public dialog: MatDialog) {
    this.headerService.setTitle('Settings overview');
  }

  add(): void {
    this.router.navigate(['settings/add']);
  }
}
