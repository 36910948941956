import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Device} from '../../buildings.models';

@Component({
  selector: 'app-moost-devices-table',
  templateUrl: './moost-devices-table.component.html',
  styleUrls: ['./moost-devices-table.component.scss'],
})
export class MoostDevicesTableComponent implements OnInit, AfterViewInit {
  readonly displayedColumns: string[] = ["id", "type", "product_name", "createdAt"];
  dataSource: MatTableDataSource<Device>;
  @Input() devices: Device[];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Device>(this.devices);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
