export class MoostEvent {
  timestamp?: number = 0;
  forecastTimestamp?: number = 0;
  customerId?: string = '';
  customerBuildingId?: string = '';
  deviceId?: string = '';
  deviceName?: string = '';
  value?: number = 0;
  type?: string = '';
  source?: string = '';
}

export class DataUnit {
  static readonly NUMBER: DataUnit = new DataUnit('');
  static readonly BOOLEAN: DataUnit = new DataUnit('');
  static readonly PERCENT: DataUnit = new DataUnit('%');
  static readonly CELSIUS: DataUnit = new DataUnit('°C');
  static readonly WATT: DataUnit = new DataUnit('W');
  static readonly WATT_HOUR: DataUnit = new DataUnit('Wh');
  static readonly CURRENCY: DataUnit = new DataUnit('');
  readonly symbol: string;

  constructor(symbol: string) {
    this.symbol = symbol;
  }

  static ofEventType(eventType: string): DataUnit {
    if (eventType.indexOf("ANOMALY_SCORE") > -1) {
      return DataUnit.NUMBER;
    } else if (eventType.indexOf("RATE") > -1) {
      return DataUnit.PERCENT;
    } else if (eventType.indexOf("TEMPERATURE") > -1) {
      return DataUnit.CELSIUS;
    } else if (eventType.indexOf("POWER") > -1) {
      return DataUnit.WATT;
    } else if (eventType.indexOf("ENERGY") > -1) {
      return DataUnit.WATT_HOUR;
    } else if (eventType.indexOf("_PRICE") > -1) {
      return DataUnit.CURRENCY;
    } else if (eventType.indexOf("IS_") > -1) {
      return DataUnit.BOOLEAN;
    } else {
      return DataUnit.NUMBER;
    }
  }
}

export class EventsFilter {
  readonly startTimestampMillis: number;
  readonly endTimestampMillis: number;
  readonly customerBuildingIds: string[];
  readonly sources: string[];
  readonly types: string[];
  readonly deviceIds: string[];
  readonly deviceNames: string[];

  constructor(startTimestampMillis: number, endTimestampMillis: number,
              customerBuildingIds?: string[],
              sources?: string[], types?: string[],
              deviceIds?: string[], deviceNames?: string[]) {
    this.startTimestampMillis = startTimestampMillis;
    this.endTimestampMillis = endTimestampMillis;
    this.customerBuildingIds = customerBuildingIds;
    this.sources = sources;
    this.types = types;
    this.deviceIds = deviceIds;
    this.deviceNames = deviceNames;
  }
}

export interface IEvent extends MoostEvent {
}
