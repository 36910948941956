import { Component, Input, inject } from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {finalize} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {TranslationService} from '../../translation.service';
import {MatIconButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';
import {NgIf} from '@angular/common';
import {TranslationHelper} from './translation-helper';

@Component({
    selector: 'app-translate-form-button',
    imports: [
        MatIcon,
        MatProgressSpinner,
        MatIconButton,
        MatTooltip,
        NgIf
    ],
    templateUrl: './translate-form-button.component.html',
    styleUrl: './translate-form-button.component.scss'
})
export class TranslateFormButtonComponent {
  private translationService = inject(TranslationService);


  @Input() fieldNamePrefix: string;
  @Input() sourceLanguage: string;
  @Input() notificationLanguages: string[] = [];
  @Input() ruleForm: FormGroup;
  protected loadingTranslations: number;

  translateNotificationField(): void {
    const sourceText: string = this.ruleForm.get(['notification', `${this.fieldNamePrefix}_${this.sourceLanguage}`]).value;
    const translationHelper: TranslationHelper = this.isRuleLanguageTerm() ? TranslationHelper.forRuleLanguageTerm() : TranslationHelper.forPlainText();
    const sourceTranslatableText: string = translationHelper.toTranslatableText(sourceText);
    const targetLanguages: string[] = this.notificationLanguages.filter((lang: string) => lang !== this.sourceLanguage);
    this.loadingTranslations = 0;
    for (let targetLang of targetLanguages) {
      this.loadingTranslations++;
      this.translationService.translate(sourceTranslatableText, this.sourceLanguage, targetLang)
        .pipe(finalize(() => this.loadingTranslations--))
        .subscribe((translation: string) => {
          this.ruleForm.get(['notification', `${this.fieldNamePrefix}_${targetLang}`]).setValue(translationHelper.fromTranslatedText(translation));
          this.ruleForm.get(['notification', `${this.fieldNamePrefix}_${targetLang}`]).markAsDirty();
        });
    }
    this.ruleForm.markAsDirty();
  }

  private isRuleLanguageTerm(): boolean {
    return this.fieldNamePrefix === 'message';
  }
}
