import {Component, Input} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {MoostHeaderService} from './moost-header.service';

@Component({
  selector: 'app-moost-header',
  templateUrl: './moost-header.component.html',
  styleUrls: ['./moost-header.component.scss']
})
export class MoostHeaderComponent {
  @Input() drawer: MatSidenav;

  constructor(public headerService: MoostHeaderService) {
  }

}
