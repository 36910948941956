import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BuildingDetailFormFilter} from './building-detail-form-filter';
import {DeliveryStatuses} from '../../notifications-module/notifications.models';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Customer} from '../../shared-module/customer.model';
import {CustomerService} from '../../shared-module/customer.service';
import {UtilityService} from '../../shared-module/utility.service';

@Component({
  selector: 'app-moost-buildings-detail-filter',
  templateUrl: './moost-buildings-detail-filter.component.html',
  styleUrl: './moost-buildings-detail-filter.component.scss',
  standalone: false
})
export class MoostBuildingsDetailFilterComponent implements OnInit, OnDestroy {
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private customerService: CustomerService = inject(CustomerService);
  private utilityService: UtilityService = inject(UtilityService);

  @Output() applyFilterChange: EventEmitter<BuildingDetailFormFilter> = new EventEmitter<BuildingDetailFormFilter>()

  filter: BuildingDetailFormFilter;
  filterForm: FormGroup = new FormGroup({
    deliveryStatuses: new FormControl<string[]>(null, [Validators.required]),
    range: new FormGroup({
      startTimestampMillis: new FormControl<Date>(null, [Validators.required]),
      endTimestampMillis: new FormControl<Date>(null, [Validators.required]),
    })
  });
  deliveryStatusesChoice: string[] = Object.keys(DeliveryStatuses);
  maxDate: Date;

  private disableEventEmit: boolean = false;
  private isInitializing: boolean = true;
  private valueChangesSubscription: Subscription;
  private routeSubscription: Subscription;
  private customerSubscription: Subscription;

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe((queryParams: Params): void => {
      this.customerSubscription = this.customerService.getCustomer().subscribe((customer: Customer): void => {
        const paramStartTimestamp: number = queryParams['s'];
        const paramEndTimestamp: number = queryParams['e'];

        this.maxDate = this.utilityService.getCustomerSpecificMoment(customer.id).endOf('day').toDate();
        this.filter = new BuildingDetailFormFilter(
          Number(paramStartTimestamp) || this.utilityService.getCustomerSpecificMoment(customer.id).startOf('day').subtract({days: 14}).toDate().getTime(),
          Number(paramEndTimestamp) || this.utilityService.getCustomerSpecificMoment(customer.id).endOf('day').toDate().getTime(),
          (queryParams['deliveryStatus'] === undefined ? [DeliveryStatuses.DELIVERED] : queryParams['deliveryStatus']?.split(","))
        );

        if (this.isInitializing) {
          this.setFormValues(this.filter);
          this.emitFilterChanged(this.filter);
        } else if ((this.filterForm.valid && this.filterForm.get("range").get("endTimestampMillis").errors == null)) {
          this.setFormValues(this.filter);
        }

        this.isInitializing = false;
      })
    });

    this.valueChangesSubscription = this.filterForm.valueChanges.subscribe((): void => {
      if (this.filterForm.valid && !this.disableEventEmit &&
        this.filterForm.get("range").get("endTimestampMillis").errors == null) {
        this.emitFilterChanged(this.buildBuildingDetailFormFilter(this.filterForm));
      }
    });
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.customerSubscription?.unsubscribe();
  }

  clearFilter(): void {
    this.resetForm(this.filterForm);
  }

  private emitFilterChanged(filter: BuildingDetailFormFilter): void {
    this.applyFilterChange.emit(filter);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          s: filter.startTimestampMillis,
          e: filter.endTimestampMillis,
          deliveryStatus: filter.deliveryStatuses?.join(","),
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private setFormValues(filter: BuildingDetailFormFilter): void {
    this.disableEventEmit = true;
    this.filterForm.patchValue({
      deliveryStatuses: filter?.deliveryStatuses,
      range: {
        startTimestampMillis: new Date(filter?.startTimestampMillis),
        endTimestampMillis: new Date(filter?.endTimestampMillis),
      }
    });
    this.disableEventEmit = false;
  }

  private resetForm(filterForm: FormGroup): void {
    filterForm.get("deliveryStatuses").reset(BuildingDetailFormFilter.DEFAULT_DELIVERY_STATUSES);
  }

  private buildBuildingDetailFormFilter(filterForm: FormGroup): BuildingDetailFormFilter {
    return new BuildingDetailFormFilter(
      filterForm.get("range").get("startTimestampMillis").value.valueOf(),
      filterForm.get("range").get("endTimestampMillis").value.valueOf(),
      filterForm.get("deliveryStatuses").value,
    );
  }
}
