<div class="chart">
  <h3>Per Rule</h3>
  @if (filter?.deliveryStatuses?.length > 0 || filter?.interactions?.length > 0) {
    <p>
      Notifications with
      @if (filter?.deliveryStatuses?.length > 0 && filter?.interactions?.length > 0) {
        delivery status: {{ filter?.deliveryStatuses?.join(', ') }} and interaction: {{ filter?.interactions?.join(', ') }}
      } @else if (filter?.deliveryStatuses?.length > 0) {
        delivery status: {{ filter.deliveryStatuses.join(', ') }}
      } @else if (filter?.interactions?.length > 0) {
        interaction: {{ filter.interactions.join(', ') }}
      }
    </p>
  }
  <div [loadingOpts]="loadingOptions"
       [loading]="isLoading"
       [options]="chartOptions$ | async"
       [style.height.px]="rulesCount * 20 + 100"
       class="heatmap-chart" echarts></div>
</div>
