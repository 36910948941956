import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../customer.service';
import {Customer} from '../customer.model';
import {Subscription} from 'rxjs';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';

@Component({
  selector: 'app-moost-user-menu',
  templateUrl: './moost-user-menu.component.html',
  styleUrls: ['./moost-user-menu.component.scss']
})
export class MoostUserMenuComponent implements OnInit, OnDestroy {
  public customer: Customer;
  private customerSubscription: Subscription;

  constructor(protected authTokenService: AuthTokenService, private customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.customerSubscription = this.customerService.getCustomer().subscribe(it => {
      this.customer = it
    });
  }

  ngOnDestroy(): void {
    this.customerSubscription?.unsubscribe();
  }
}
