import {RuleLanguageTokenizer} from './rule-language-tokenizer';
import {TermItem} from "./term-item";
import {TermFunction} from "./term-function";
import {TermStructure} from "./term-structure";
import {TermOperator} from "./term-operator";
import {TermType} from "./term-type";
import {Token} from "./token";
import {EvaluationContext} from "./evaluation-context";
import {AttributeAccessor} from './attribute-accessor';
import {FilterComparison} from './filter-comparison';
import {TermLiteral} from './term-literal';

function isScalarOrGroupedScalarPair(v1: TermItem, v2: TermItem): boolean {
  return (v1.isScalar() || v1.isGroupedScalar()) && (v2.isScalar() || v2.isGroupedScalar());
}

/*---------------------------------------------------------------------------------------------------------------
 RuleLanguageValidator is a 1:1 TypeScript implementation of:
 <li> _javaluator_ Java class "AbstractEvaluator" (for generic evaluation)
 <li> _fk-rules-executor_ Java class "TermEvaluator" (for concrete Rule-Language evaluation)
 Remark: the class structure, and all field and method names are the same in both implementations, to make it easy
 to keep both implementations in sync.
----------------------------------------------------------------------------------------------------------------*/
export class RuleLanguageValidator {
  readonly FORMAT_NUMBER_PATTERN: RegExp = /^[0#.,-]+$/;
  readonly FORMAT_TIME_PATTERN: RegExp = /^[yYMLwWdDFEuaHkKhmsS., :-]+$/;
  readonly FORMAT_TIMESPAN_PATTERN: RegExp = /^(ADAPTIVE|SECONDS|MINUTES|HOURS|DAYS):(SYMBOL|WORD)$/;

  evaluate(expression: string, evaluationContext: EvaluationContext, allowedTypes: TermType[], allowedStructures: TermStructure[]): Token[] {
    const values: TermItem[] = []; // values stack
    const stack: Token[] = []; // operator stack
    const previousValuesSize: number[] = [];
    const tokens: Token[] = new RuleLanguageTokenizer().tokenize(expression);
    let previous: Token = null;
    let current: Token;
    try {
      for (let token of tokens) {
        current = token;
        if (token.isFunction()) {
          token.setFunction(TermFunction[token.getContent()])
        } else if (token.isOperator()) {
          token.setOperator(this.guessOperator(previous, token));
        }
        if (token.isOpenBracket()) {
          // If the token is a left parenthesis, then push it onto the stack.
          stack.push(token);
        } else if (token.isCloseBracket()) {
          if (previous == null) {
            throw new SyntaxError("Expression cannot start with a close bracket");
          }
          if (previous.isFunctionArgumentSeparator()) {
            throw new SyntaxError("Argument is missing before comma");
          }
          // If the token is a right parenthesis:
          let openBracketFound: boolean = false;
          // Until the token at the top of the stack is a left parenthesis,
          // pop operators off the stack onto the output queue
          while (stack.length > 0) {
            let sc: Token = stack.pop();
            if (sc.isOpenBracket()) {
              openBracketFound = true;
              break;
            } else {
              this.output(values, sc, evaluationContext);
            }
          }
          if (!openBracketFound) {
            // If the stack runs out without finding a left parenthesis, then
            // there are mismatched parentheses.
            throw new SyntaxError("Parentheses mismatched");
          }
          if (stack.length > 0 && stack[stack.length - 1].isFunction()) {
            // If the token at the top of the stack is a function token, pop it
            // onto the output queue.
            let argCount: number = values.length - previousValuesSize.pop();
            values.push(this.evaluateFunction(stack.pop(), this.getArguments(values, argCount)));
          }
        } else if (token.isFunctionArgumentSeparator()) {
          if (previous == null) {
            throw new SyntaxError("Expression cannot start with a comma");
          }
          // Verify that there was an argument before this separator
          if (previous.isOpenBracket() || previous.isFunctionArgumentSeparator()) {
            // The cases were operator miss an operand are detected elsewhere.
            throw new SyntaxError("Argument is missing before comma");
          }
          // If the token is a function argument separator
          let pe: boolean = false;
          while (stack.length > 0) {
            if (stack[stack.length - 1].isOpenBracket()) {
              pe = true;
              break;
            } else {
              // Until the token at the top of the stack is a left parenthesis,
              // pop operators off the stack onto the output queue.
              this.output(values, stack.pop(), evaluationContext);
            }
          }
          if (!pe) {
            // If no left parentheses are encountered, either the separator was misplaced
            // or parentheses were mismatched.
            throw new SyntaxError("Separator or parentheses mismatched");
          } else {
            // Verify we are in function scope
            let openBracket: Token = stack.pop();
            let scopeToken: Token = stack[stack.length - 1];
            stack.push(openBracket);
            if (!scopeToken.isFunction()) {
              throw new SyntaxError("Argument separator used outside of function scope");
            }
          }
        } else if (token.isFunction()) {
          // If the token is a function token, then push it onto the stack.
          stack.push(token);
          previousValuesSize.push(values.length);
        } else if (token.isOperator()) {
          // If the token is an operator, op1, then:
          while (stack.length > 0) {
            let sc: Token = stack[stack.length - 1];
            // While there is an operator token, o2, at the top of the stack
            // op1 is left-associative and its precedence is less than or equal
            // to that of op2,
            // or op1 has precedence less than that of op2,
            // Let + and ^ be right associative.
            // Correct transformation from 1^2+3 is 12^3+
            // The differing operator priority decides pop / push
            // If 2 operators have equal priority then associativity decides.
            if (sc.isOperator() && token.getOperator()
              && ((token.getOperator().isLeftAssociativity && (token.getOperator().precedence <= sc.getOperator().precedence)) ||
                (token.getOperator().precedence < sc.getOperator().precedence))) {
              // Pop o2 off the stack, onto the output queue;
              this.output(values, stack.pop(), evaluationContext);
            } else {
              break;
            }
          }
          // push op1 onto the stack.
          stack.push(token);
        } else if (token.isLiteral()) {
          // If the token is a number (identifier), a constant or a variable, then add its value to the output queue.
          if ((previous != null) && previous.isLiteral()) {
            throw new SyntaxError("A literal cannot follow another literal");
          }
          this.output(values, token, evaluationContext);
        }
        if (!token.isComment()) {
          previous = token;
        }
      }
      // When there are no more tokens to read:
      // While there are still operator tokens in the stack:
      while (stack.length > 0) {
        let sc: Token = stack.pop();
        if (sc.isOpenBracket() || sc.isCloseBracket()) {
          previous.assignError("Separator or parentheses mismatched");
        }
        this.output(values, sc, evaluationContext);
      }
      if (values.length != 1 && !previous.getError()) {
        previous.assignError(`Expected 1 valid expression but found ${values.length}`);
      } else {
        const result = values[0];
        if (!allowedStructures.includes(result.structure)) {
          previous.assignError(`Expected ${allowedStructures.map(it => TermStructure[it]).join('|')} but found ${result.getStructureName()}`);
        } else if (!allowedTypes.includes(result.type)) {
          previous.assignError(`Expected ${allowedStructures.map(it => TermStructure[it]).join('|')}<${allowedTypes.map(it => TermType[it]).join('|')}> but found ${result.getStructureType()}`);
        }
      }
      values.pop();
    } catch (e) {
      if (e instanceof SyntaxError) {
        current.assignError(e.message);
      } else {
        console.log(e.stack);
      }
    }
    return tokens;
  }

  private guessOperator(previous: Token, current: Token): TermOperator {
    const candidates: TermOperator[] = TermOperator.OPERATORS.filter(op => op.symbol === current.getContent());
    const argCount: number = ((previous != null) && (previous.isCloseBracket() || previous.isLiteral())) ? 2 : 1;
    for (let operator of candidates) {
      if (operator.operandCount == argCount) {
        return operator;
      }
    }
    return null;
  }

  private output(values: TermItem[], token: Token, evaluationContext: EvaluationContext): void {
    if (token.isLiteral()) { // If the token is a literal, a constant, or a variable name
      let varValue: TermItem;
      if (token.getContent().startsWith('$')) {
        const varName = token.getContent().substring(1);
        varValue = evaluationContext.variables.get(varName);
      }
      if (varValue) {
        values.push(new TermItem(varValue.structure, varValue.type, token));
      } else {
        values.push(this.toValue(token));
      }
    } else if (token.isOperator() && token.getOperator()) {
      const args = this.getArguments(values, token.getOperator().operandCount);
      values.push(this.evaluateOperator(token, args));
    } else {
      throw new SyntaxError("Syntax error");
    }
  }

  private getArguments(values: TermItem[], nb: number): TermItem[] {
    // Be aware that arguments are in reverse order on the values stack.
    // Don't forget to reorder them in the original order (the one they appear in the evaluated formula)
    if (values.length < nb) {
      throw new SyntaxError(`Expected ${nb} arguments but found ${values.length}`);
    }
    let result: TermItem[] = [];
    for (let i = 0; i < nb; i++) {
      result.unshift(values.pop());
    }
    return result;
  }

  private toValue(token: Token): TermItem {
    const literal: string = token.getContent();
    const termLiteral: TermLiteral = TermLiteral.byName(literal);
    if (termLiteral) {
      return new TermItem(TermStructure.SCALAR, termLiteral.termType, token);
    } else if (/^(\d+)(d|h|min|s)$/.test(literal)) {
      return new TermItem(TermStructure.SCALAR, TermType.TIMESPAN, token);
    } else if (/^(\d+)(\.\d+)?$/.test(literal)) {
      return new TermItem(TermStructure.SCALAR, TermType.NUMBER, token);
    } else {
      return new TermItem(TermStructure.SCALAR, TermType.TEXT, token);
    }
  }

  private evaluateFunction(token: Token, args: TermItem[]): TermItem {
    const fct: TermFunction = token.getFunction();
    const v1 = args[0];
    switch (fct) {
      case TermFunction.AVG:
      case TermFunction.SUM:
      case TermFunction.MIN:
      case TermFunction.MAX:
        if (args.length !== 1) {
          return token.assignError(`Expected 1 argument but found ${args.length}`, args)
            .andPresume(TermStructure.SCALAR, TermType.NUMBER);
        }
        if (v1.isVector() && v1.hasNumberValue()) {
          return new TermItem(TermStructure.SCALAR, TermType.NUMBER);
        } else if (v1.isGroupedScalar() && v1.hasNumberValue()) {
          return new TermItem(TermStructure.SCALAR, TermType.NUMBER);
        } else if (v1.isGroupedVector() && v1.hasNumberValue()) {
          return new TermItem(TermStructure.GROUPED_SCALAR, TermType.NUMBER);
        }
        return token.assignError(`Function '${fct.name}' cannot be applied to ${v1.getStructureType()}...`, args)
          .andPresume(TermStructure.SCALAR, TermType.NUMBER);
      case TermFunction.COUNT:
        if (args.length !== 1) {
          return token.assignError(`Expected 1 argument but found ${args.length}`, args)
            .andPresume(TermStructure.SCALAR, TermType.NUMBER);
        }
        if (v1.isVector() || v1.isGroupedScalar()) {
          return new TermItem(TermStructure.SCALAR, TermType.NUMBER);
        } else if (v1.isGroupedVector()) {
          return new TermItem(TermStructure.GROUPED_SCALAR, TermType.NUMBER);
        }
        return token.assignError(`Function '${fct.name}' cannot be applied to ${v1.getStructureType()}...`, args)
          .andPresume(TermStructure.SCALAR, TermType.NUMBER);
      case TermFunction.SORT:
        switch (v1.structure) {
          case TermStructure.SCALAR:
          case TermStructure.VECTOR:
            if (args.length !== 1) {
              return token.assignError(`Expected 1 argument but found ${args.length}`, args)
                .andPresume(TermStructure.SCALAR, TermType.NUMBER);
            }
            return new TermItem(v1.structure, v1.type);
          case TermStructure.GROUPED_SCALAR:
          case TermStructure.GROUPED_VECTOR:
            if (args.length < 1 || args.length > 2) {
              return token.assignError(`Expected 1..2 arguments but found ${args.length}`, args)
                .andPresume(v1.structure, v1.type);
            }
            return new TermItem(v1.structure, v1.type);
        }
        return token.assignError(`Function '${fct.name}' cannot be applied to ${v1.getStructureType()}...`, args)
          .andPresume(v1.structure, v1.type);
      case TermFunction.REVERSE:
        if (args.length !== 1) {
          return token.assignError(`Expected 1 argument but found ${args.length}`, args)
            .andPresume(TermStructure.SCALAR, TermType.NUMBER);
        }
        return new TermItem(v1.structure, v1.type);
      case TermFunction.DISTINCT:
        if (args.length !== 1) {
          return token.assignError(`Expected 1 argument but found ${args.length}`, args)
            .andPresume(TermStructure.SCALAR, TermType.TEXT);
        }
        return new TermItem(v1.structure, v1.type);
      case TermFunction.SUBSET:
        if (v1.isVector()) {
          if (
            (args.length == 2 && args[1].isNumber()) ||
            (args.length == 3 && args[1].isNumber() && args[2].isNumber())
          ) {
            return new TermItem(TermStructure.VECTOR, v1.type);
          } else if (v1.isEvent()) {
            if (
              (args.length == 2 && args[1].isTime()) ||
              (args.length == 3 && args[1].isTime() && args[2].isTime())
            ) {
              return new TermItem(TermStructure.VECTOR, v1.type);
            }
            return token.assignError(`Function '${fct.name}' can only be applied to (${v1.getStructureType()}, SCALAR<TIME>) or (${v1.getStructureType()}, SCALAR<TIME>, SCALAR<TIME>)`, args)
              .andPresume(TermStructure.VECTOR, v1.type);
          }
        }
        return token.assignError(`Function '${fct.name}' cannot be applied to ${v1.getStructureType()}...`, args)
          .andPresume(TermStructure.VECTOR, v1.type);
      case TermFunction.FILTER:
        if (args.length !== 3) {
          return token.assignError(`Expected 3 arguments but found ${args.length}`, args)
            .andPresume(
              args.length >= 1 ? args[0].structure : TermStructure.VECTOR,
              args.length >= 1 ? args[0].type : TermType.EVENT);
        }
        const data = args[0];
        if (data.isScalar()) {
          return token.assignError(`First argument must be Vector, GroupedScalar or GroupedVector`)
            .andPresume(TermStructure.VECTOR, data.type);
        }
        const symbol = args[1];
        if (!(symbol.isScalar() && symbol.isText() && FilterComparison.isValidSymbol(symbol.token.getLiteral()))) {
          return token.assignError(`Second argument must be a Scalar<Text> containing a comparator: ${FilterComparison.SYMBOLS.join(', ')}`)
            .andPresume(data.structure, data.type);
        }
        const threshold = args[2];
        if (!threshold.isScalar()) {
          return token.assignError(`Third argument must be Scalar`)
            .andPresume(data.structure, data.type);
        }
        return new TermItem(data.structure, data.type);
      case TermFunction.GROUP_BY_DEVICE:
        if (v1.isVector() && v1.isEvent() && args.length == 1) {
          return new TermItem(TermStructure.GROUPED_VECTOR, TermType.EVENT);
        }
        return token.assignError(`Function '${fct.name}' cannot be applied to ${v1.getStructureType()}...`, args)
          .andPresume(TermStructure.GROUPED_VECTOR, TermType.EVENT);
      case TermFunction.GROUP_BY_TIME:
        if (v1.isVector() && v1.isEvent()) {
          if (
            (args.length === 2 && args[1].isScalar() && args[1].isTimespan()) ||
            (args.length === 3 && args[1].isScalar() && args[1].isTimespan() && args[2].isScalar() && args[2].isTime())
          ) {
            return new TermItem(TermStructure.GROUPED_VECTOR, TermType.EVENT);
          }
        }
        return token.assignError(`Function '${fct.name}' cannot be applied to ${v1.getStructureType()}...`, args)
          .andPresume(TermStructure.GROUPED_VECTOR, TermType.EVENT);
      case TermFunction.DAILY_ENERGY_REPORT:
        if (args.length === 2 && v1.isScalar() && v1.isTime() && args[1].isScalar() && args[1].isTime()) {
          return new TermItem(TermStructure.SCALAR, TermType.BOOLEAN);
        }
        return token.assignError(`Function '${fct.name}' cannot be applied to ${v1.getStructureType()}...`, args)
          .andPresume(TermStructure.SCALAR, TermType.BOOLEAN);
    }
  }

  private evaluateOperator(token: Token, args: TermItem[]): TermItem {
    const op: TermOperator = token.getOperator();
    const v1 = args[0];
    const v2 = (args.length > 1 ? args[1] : null);
    const resultStructure = op.getDefaultResultStructure(v1);
    const resultType = op.getDefaultResultType(v1);
    if (args.length !== op.operandCount) {
      return token.assignError(`Expected ${op.operandCount} arguments but found ${args.length}`, args)
        .andPresume(resultStructure, resultType);
    }
    switch (op) {
      case TermOperator.PLUS:
        if (isScalarOrGroupedScalarPair(v1, v2) && v1.hasNumberValue() && v2.hasNumberValue()) {
          return new TermItem(resultStructure, TermType.NUMBER);
        } else if (isScalarOrGroupedScalarPair(v1, v2) && v1.isTime() && v2.isTimespan()) {
          return new TermItem(resultStructure, TermType.TIME);
        } else if (isScalarOrGroupedScalarPair(v1, v2) && v1.isTimespan() && v2.isTimespan()) {
          return new TermItem(resultStructure, TermType.TIMESPAN);
        } else if (v1.isText() || v2.isText()) {
          return new TermItem(resultStructure, TermType.TEXT);
        }
        break;
      case TermOperator.MINUS:
        if (isScalarOrGroupedScalarPair(v1, v2) && v1.hasNumberValue() && v2.hasNumberValue()) {
          return new TermItem(resultStructure, TermType.NUMBER);
        } else if (isScalarOrGroupedScalarPair(v1, v2) && v1.isTime() && v2.isTime()) {
          return new TermItem(resultStructure, TermType.TIMESPAN);
        } else if (isScalarOrGroupedScalarPair(v1, v2) && v1.isTime() && v2.isTimespan()) {
          return new TermItem(resultStructure, TermType.TIME);
        } else if (isScalarOrGroupedScalarPair(v1, v2) && v1.isTimespan() && v2.isTimespan()) {
          return new TermItem(resultStructure, TermType.TIMESPAN);
        }
        break;
      case TermOperator.MULTIPLY:
      case TermOperator.DIVIDE:
        if (isScalarOrGroupedScalarPair(v1, v2) && v1.hasNumberValue() && v2.hasNumberValue()) {
          return new TermItem(resultStructure, resultType);
        }
        break;
      case TermOperator.NEGATE:
        if (v1.hasNumberValue()) {
          return new TermItem(resultStructure, resultType);
        }
        break;
      case TermOperator.LESS:
      case TermOperator.LESS_OR_EQUAL:
      case TermOperator.EQUAL:
      case TermOperator.NOT_EQUAL:
      case TermOperator.GREATER_OR_EQUAL:
      case TermOperator.GREATER:
        if (isScalarOrGroupedScalarPair(v1, v2) && (v1.type === v2.type || v1.hasNumberValue() && v2.hasNumberValue())) {
          return new TermItem(resultStructure, resultType);
        }
        break;
      case TermOperator.AND:
      case TermOperator.OR:
        if (isScalarOrGroupedScalarPair(v1, v2) && v1.isBoolean() && v2.isBoolean()) {
          return new TermItem(resultStructure, resultType);
        }
        break;
      case TermOperator.NOT:
        if (v1.isBoolean()) {
          return new TermItem(resultStructure, resultType);
        }
        break;
      case TermOperator.FORMAT:
        if (v2.isText()) {
          const pattern: string = v2.token.getLiteral();
          if (v1.hasNumberValue() && !pattern.match(this.FORMAT_NUMBER_PATTERN)) {
            return token.assignError(`Invalid number pattern. Use following characters: 0#.,-`, args)
              .andPresume(resultStructure, resultType);
          } else if (v1.isTime() && !pattern.match(this.FORMAT_TIME_PATTERN)) {
            return token.assignError(`Invalid time pattern. Use following characters: yYMLwWdDFEuaHkKhmsS., :-`, args)
              .andPresume(resultStructure, resultType);
          } else if (v1.isTimespan() && !pattern.match(this.FORMAT_TIMESPAN_PATTERN)) {
            return token.assignError(`Invalid time pattern. Use following format: (ADAPTIVE|SECONDS|MINUTES|HOURS|DAYS):(SYMBOL|WORD)`, args)
              .andPresume(resultStructure, resultType);
          }
          return new TermItem(resultStructure, resultType);
        }
        break;
      case TermOperator.ATTRIBUTE:
        const accessor: string = v2.token.getLiteral();
        if (v2.isScalar() && v2.isText()) {
          const allowedAccessors: string[] = AttributeAccessor.getAllowedAccessors(v1.structure);
          if (allowedAccessors.includes(accessor)) {
            return new TermItem(AttributeAccessor.getResultingStructure(accessor, v1), AttributeAccessor.getResultingType(accessor, v1))
          }
        }
        return token.assignError(`Operator '${op.symbol}${accessor}' cannot be applied to ${v1.getStructureType()}`, args)
          .andPresume(v1.isScalar() ? TermStructure.SCALAR : TermStructure.VECTOR, TermType.EVENT);
      default:
        return token.assignError(`Operator '${op.symbol}' not supported`, args)
          .andPresume(resultStructure, resultType);
    }
    return token.assignError(`Operator '${op.symbol}' cannot be applied to ${args.map(it => it.getStructureType()).join(', ')}`, args)
      .andPresume(resultStructure, resultType);
  }

}
