import {Component, inject} from '@angular/core';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {EventsFilter} from '../events.models';

@Component({
  selector: 'app-moost-events-overview',
  templateUrl: './moost-events-overview.component.html',
  styleUrls: ['./moost-events-overview.component.scss'],
  standalone: false
})
export class MoostEventsOverviewComponent {
  private headerService: MoostHeaderService = inject(MoostHeaderService);

  filter: EventsFilter;

  constructor() {
    this.headerService.setTitle('Events');
  }

  applyFilter($event: EventsFilter): void {
    this.filter = $event;
  }
}
