import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
  ConfirmDialogModel,
  MoostConfirmDialogComponent
} from '../../shared-module/moost-confirm-dialog/moost-confirm-dialog.component';
import {Dataset, DatasetType, secondsToDurationString} from '../rules.models';

@Component({
  selector: 'app-moost-dataset-chip',
  templateUrl: './moost-dataset-chip.component.html',
  styleUrls: ['./moost-dataset-chip.component.scss']
})
export class MoostDatasetChipComponent {
  @Input() color: string;
  @Input() dataset: Dataset;
  @Output() datasetRemoved: EventEmitter<string> = new EventEmitter<string>()

  constructor(public dialog: MatDialog) {
  }

  removeDataset(name: string): void {
    const dialogConfig: MatDialogConfig<ConfirmDialogModel> = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.data = {
      title: "Delete Dataset",
      message: `Do you really want to delete the dataset with name '${name}'?`,
      confirm: "YES",
      dismiss: "NO",
      icon: "warning_amber",
      confirmColor: "warn"
    };

    this.dialog.open(MoostConfirmDialogComponent, dialogConfig).afterClosed()
      .subscribe((confirmed: boolean): void => {
        if (confirmed) {
          this.datasetRemoved.emit(name);
        }
      });
  }

  getSummary(): string {
    if (DatasetType[this.dataset.type] === DatasetType.SINGLEVALUE) {
      return "Single value";
    } else {
      return `Time series of ${secondsToDurationString(this.dataset.timeframe)}`;
    }
  }
}
