import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  getCustomerSpecificMoment(customerId: String): moment.Moment {
    if (customerId === environment.customers.demo.id) {
      return moment().subtract(1, 'days')
    } else if (customerId === environment.customers.eon.id) {
      //TODO: Remove after Pilot phase of EON RPD-879
      return moment('2025-02-28');
    } else {
      return moment();
    }
  }

}
