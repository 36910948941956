import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BuildingDetailFormFilter} from './building-detail-form-filter';
import {DeliveryStatuses} from '../../notifications-module/notifications.models';
import moment from 'moment/moment';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-moost-buildings-detail-filter',
  templateUrl: './moost-buildings-detail-filter.component.html',
  styleUrl: './moost-buildings-detail-filter.component.scss',
  standalone: false
})
export class MoostBuildingsDetailFilterComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);



  @Input() filter: BuildingDetailFormFilter;
  @Output() applyFilterChange: EventEmitter<BuildingDetailFormFilter> = new EventEmitter<BuildingDetailFormFilter>()
  filterForm: FormGroup;
  deliveryStatusesChoice: string[] = Object.keys(DeliveryStatuses);
  maxDate: Date = moment().endOf('day').toDate();

  private valueChangesSubscription: Subscription;

  ngOnInit(): void {
    this.buildForm(this.filter);
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  clearFilter(): void {
    this.resetForm(this.filterForm);
  }

  private setFilter(filter: BuildingDetailFormFilter): void {
    this.applyFilterChange.emit(filter);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          s: filter.startTimestampMillis,
          e: filter.endTimestampMillis,
          deliveryStatus: filter.deliveryStatuses?.join(","),
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private buildForm(filter: BuildingDetailFormFilter): void {
    this.filterForm = new FormGroup({
      deliveryStatuses: new FormControl<string[]>(filter?.deliveryStatuses),
      startTimestampMillis: new FormControl(new Date(Number(filter?.startTimestampMillis)), [Validators.required]),
      endTimestampMillis: new FormControl(new Date(Number(filter?.endTimestampMillis)), [Validators.required]),
    });
    this.valueChangesSubscription = this.filterForm.valueChanges.subscribe(() => {
      if (this.filterForm.valid) {
        this.setFilter(this.buildBuildingDetailFormFilter(this.filterForm));
      }
    });
  }

  private resetForm(filterForm: FormGroup): void {
    filterForm.get("deliveryStatuses").reset(BuildingDetailFormFilter.DEFAULT_DELIVERY_STATUSES);
  }

  private buildBuildingDetailFormFilter(filterForm: FormGroup): BuildingDetailFormFilter {
    return new BuildingDetailFormFilter(
      filterForm.get("startTimestampMillis").value.valueOf(),
      moment(filterForm.get("endTimestampMillis").value.valueOf()).endOf('day').toDate().getTime(),
      filterForm.get("deliveryStatuses").value,
    );
  }
}
