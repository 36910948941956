<h2 mat-dialog-title>
  <mat-icon class="icon">{{ icon }}</mat-icon>
  {{ title }}
  <mat-divider></mat-divider>
</h2>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions>
  <button (click)="onDismiss()" mat-stroked-button>{{ dismiss }}</button>
  <button (click)="onConfirm()" [color]="confirmColor" mat-raised-button>{{ confirm }}</button>
</div>
