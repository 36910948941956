import {TermStructure} from './term-structure';
import {TermType} from './term-type';
import {TermItem} from './term-item';

export class TermOperator {

  static readonly OPERATORS: TermOperator[] = [];

  // Arithmetic operators
  static readonly PLUS = TermOperator.register("+", 2, true, 30, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.NUMBER, TermType.TIME, TermType.TIMESPAN, TermType.TEXT]);
  static readonly MINUS = TermOperator.register("-", 2, true, 30, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.NUMBER, TermType.TIME, TermType.TIMESPAN]);
  static readonly MULTIPLY = TermOperator.register("*", 2, true, 31, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.NUMBER]);
  static readonly DIVIDE = TermOperator.register("/", 2, true, 31, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.NUMBER]);
  static readonly NEGATE = TermOperator.register("-", 1, false, 32, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR, TermStructure.VECTOR, TermStructure.GROUPED_VECTOR], [TermType.NUMBER]);
  // Comparison/relational operators
  static readonly LESS_OR_EQUAL = TermOperator.register("<=", 2, true, 20, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.BOOLEAN]);
  static readonly LESS = TermOperator.register("<", 2, true, 20, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.BOOLEAN]);
  static readonly EQUAL = TermOperator.register("=", 2, true, 20, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.BOOLEAN]);
  static readonly NOT_EQUAL = TermOperator.register("!=", 2, true, 20, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.BOOLEAN]);
  static readonly GREATER_OR_EQUAL = TermOperator.register(">=", 2, true, 20, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.BOOLEAN]);
  static readonly GREATER = TermOperator.register(">", 2, true, 20, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.BOOLEAN]);
  // Logical operators
  static readonly OR = TermOperator.register("OR", 2, true, 10, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.BOOLEAN]);
  static readonly AND = TermOperator.register("AND", 2, true, 11, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR], [TermType.BOOLEAN]);
  static readonly NOT = TermOperator.register("NOT", 1, false, 12, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR, TermStructure.VECTOR, TermStructure.GROUPED_VECTOR], [TermType.BOOLEAN]);
  // Transformation operators
  static readonly FORMAT = TermOperator.register("|", 2, true, 29, [TermStructure.SCALAR], [TermType.TEXT]);
  static readonly ATTRIBUTE = TermOperator.register("::", 2, true, 40, [TermStructure.SCALAR, TermStructure.GROUPED_SCALAR, TermStructure.VECTOR, TermStructure.GROUPED_VECTOR], [TermType.NUMBER, TermType.TEXT, TermType.TIME, TermType.EVENT]);

  private constructor(public symbol: string, public operandCount: number, public isLeftAssociativity: boolean, public precedence: number,
                      public resultingStructures: TermStructure[], public resultingTypes: TermType[]) {
    this.symbol = symbol;
    this.operandCount = operandCount;
    this.isLeftAssociativity = isLeftAssociativity;
    this.precedence = precedence;
    this.resultingStructures = resultingStructures;
    this.resultingTypes = resultingTypes;
  }

  static register(symbol: string, operandCount: number, isLeftAssociativity: boolean, precedence: number,
                  resultingStructures: TermStructure[], resultingTypes: TermType[]): TermOperator {
    const op = new TermOperator(symbol, operandCount, isLeftAssociativity, precedence, resultingStructures, resultingTypes);
    TermOperator.OPERATORS.push(op);
    return op;
  }

  static exists(symbol: string): boolean {
    const foundAny = TermOperator.OPERATORS.find(op => {
      return op.symbol === symbol;
    });
    return foundAny != null;
  }

  static getSymbols(): string[] {
    return TermOperator.OPERATORS.map(op => op.symbol);
  }

  static getArithmeticOperators(): TermOperator[] {
    return TermOperator.OPERATORS.filter(op => op.isArithmeticOperator());
  }

  static getComparisonOperators(): TermOperator[] {
    return TermOperator.OPERATORS.filter(op => op.isComparisonOperator());
  }

  static getLogicalOperators(): TermOperator[] {
    return TermOperator.OPERATORS.filter(op => op.isLogicalOperator());
  }

  isArithmeticOperator(): boolean {
    return this.precedence >= 30 && this.precedence < 40;
  }

  isComparisonOperator(): boolean {
    return this.precedence >= 20 && this.precedence < 29;
  }

  isLogicalOperator(): boolean {
    return this.precedence >= 10 && this.precedence < 20;
  }

  getDefaultResultStructure(v1: TermItem): TermStructure {
    return this.resultingStructures.includes(v1.structure) ? v1.structure : this.resultingStructures[0];
  }

  getDefaultResultType(v1: TermItem): TermType {
    return this.resultingTypes.includes(v1.type) ? v1.type : this.resultingTypes[0];
  }
}

