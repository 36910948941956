import {DeliveryStatuses} from '../../notifications-module/notifications.models';

export class BuildingDetailFormFilter {
  static DEFAULT_DELIVERY_STATUSES: string[] = [DeliveryStatuses.DELIVERED];

  readonly startTimestampMillis: number;
  readonly endTimestampMillis: number;
  readonly deliveryStatuses: string[];

  constructor(startTimestampMillis: number, endTimestampMillis: number, deliveryStatuses: string[]) {
    this.startTimestampMillis = startTimestampMillis;
    this.endTimestampMillis = endTimestampMillis;
    this.deliveryStatuses = deliveryStatuses;
  }
}
