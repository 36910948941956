export class AuthIdToken {
  customerId: string;
  name: string;
  picture: string;
  email: string;
  email_verified: boolean;
}

export class AuthAccessToken {
  customerId: string;
  permissions: string[]
}

export class Permission {
  static readonly READ_USERS = new Permission('read:users');
  static readonly WRITE_USERS = new Permission('write:users');
  static readonly READ_BUILDINGS = new Permission('read:buildings');
  static readonly WRITE_BUILDINGS = new Permission('write:buildings');
  static readonly READ_RULES = new Permission('read:rules');
  static readonly READ_RULES_SIMULATION = new Permission('read:rules-simulation');
  static readonly WRITE_RULES = new Permission('write:rules');
  static readonly READ_CUSTOMERS = new Permission('read:customers');
  static readonly WRITE_CUSTOMERS = new Permission('write:customers');
  static readonly READ_EVENTS = new Permission('read:events');
  static readonly WRITE_EVENTS = new Permission('write:events');
  static readonly READ_PUSHNOTIFICATION = new Permission('read:pushnotifications');
  static readonly WRITE_PUSHNOTIFICATIONS = new Permission('write:pushnotifications');
  static readonly ADMIN_PUSHNOTIFICATION = new Permission('admin:pushnotifications');
  static readonly ADMIN_BILLING = new Permission('admin:billing');
  static readonly ADMIN_MOOST = new Permission('admin:moost');

  readonly name: string;

  constructor(name: string) {
    this.name = name;
  }

}
