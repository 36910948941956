<h2 mat-dialog-title>
  <mat-icon class="icon">outgoing_mail</mat-icon>
  Send Test Notification
  <mat-divider></mat-divider>
</h2>

<div mat-dialog-content>
  <div class="notification-form-title-row-container">
    Send test notification to building {{ data.customerBuildingId }}
    <button mat-icon-button type="button">
      <mat-icon (click)="isNotificationFormVisible = !isNotificationFormVisible">edit</mat-icon>
    </button>
  </div>
  <div [@toggleNotificationForm]="isNotificationFormVisible ? 'open' : 'closed'"
       class="notification-form-container">
    @if (isNotificationFormVisible) {
      <!-- without this condition the scrollbar is always visible, because textarea height is set to value as it would be visible -->
      <mat-form-field appearance="outline">
        <mat-label>Notification</mat-label>
        <textarea [formControl]="notificationFormControl"
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  placeholder="Notification to send">
        </textarea>
      </mat-form-field>
      @if (notificationFormControl.invalid) {
        <mat-error>Invalid format</mat-error>
      }
    }
  </div>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>CANCEL</button>
  <button mat-flat-button color="primary"
          (click)="sendTestNotification()"
          [disabled]="notificationFormControl.invalid">
    SEND
  </button>
  <mat-error *ngIf="sendNotificationError">{{ sendNotificationError }}</mat-error>
</div>
