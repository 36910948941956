<div>
  <div class="filter">
    <app-moost-buildings-detail-filter
      (applyFilterChange)="applyFilter($event)"
      [filter]="initFilter">
    </app-moost-buildings-detail-filter>

    <button [matMenuTriggerFor]="buildingContextMenu" mat-icon-button type="button">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #buildingContextMenu="matMenu">
      <button (click)="deleteBuilding()"
              [disabled]="!authTokenService.hasPermission(Permission.WRITE_BUILDINGS)"
              mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </div>

  <div class="page-content multi-column">
    <div class="profile-box">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ building?.customerBuildingId }}</mat-card-title>
          <mat-card-subtitle>
            @if (building?.deactivatedTimestamp) {
              {{ building?.deactivatedTimestamp ? 'Inactive since ' + (building?.deactivatedTimestamp * 1000 | date : 'dd.MM.yyyy') : 'Active' }}
            } @else {
              Active
            }
          </mat-card-subtitle>
        </mat-card-header>
        <img
          mat-card-image
          width="300px"
          alt="Showing a single family home"
          src="https://www.moost.io/sites/default/files/2024-08/image%20%281%29.png"/>
        <mat-card-content class="flex-mat-card-content">
          <div class="content-badges">
            <mat-chip-set>
              @if (building?.isEarlyAdopter) {
                <mat-chip>Early Adopter</mat-chip>
              }
              @if (building?.settings?.tariff?.type) {
                <mat-chip>{{ building.settings.tariff.type | titlecase }} Tariff</mat-chip>
              }
            </mat-chip-set>
          </div>

          <div class="content-entry">
            <p>ZIP</p>
            <p>{{ building?.zip }}</p>
          </div>

          <div class="content-entry">
            <p>City</p>
            <p>{{ building?.city }}</p>
          </div>

          <div class="content-entry">
            <p>Country</p>
            <p>{{ building?.countryCode }}</p>
          </div>

          <div class="content-entry">
            <p>Time Zone</p>
            <p>{{ building?.timeZoneId || '-' }}</p>
          </div>

          @if (building?.profile?.consumptionCategory) {
            <div class="content-entry">
              <p>Consumption Category</p>
              <p>{{ building?.profile?.consumptionCategory }}</p>
            </div>
          }

          @if (building?.registrationTimestamp) {
            <div class="content-entry">
              <p>Registered</p>
              <p>{{ (building.registrationTimestamp * 1000 | date : 'dd.MM.yyyy') }}</p>
            </div>
          }

          @if (building?.activationTimestamp) {
            <div class="content-entry">
              <p>Activated</p>
              <p>{{ (building.activationTimestamp * 1000 | date : 'dd.MM.yyyy') }}</p>
            </div>
          }

          @if (building?.deactivatedTimestamp) {
            <div class="content-entry">
              <p>Deactivated on</p>
              <p>{{ (building.deactivatedTimestamp * 1000 | date : 'dd.MM.yyyy') }}</p>
            </div>
          }
        </mat-card-content>
      </mat-card>
    </div>

    <div style="flex: 1">
      <div class="chart-container">
        <app-moost-profile-motivation-chart
          class="chart-item"
          *ngIf="!isLoadingBuilding"
          [building]="building">
        </app-moost-profile-motivation-chart>

        <app-moost-profile-multi-person-chart
          class="chart-item"
          *ngIf="!isLoadingBuilding && this.building?.profile?.multiPersonScore !== null"
          [building]="building">
        </app-moost-profile-multi-person-chart>
        <app-moost-profile-commercial-building-chart
          class="chart-item"
          *ngIf="!isLoadingBuilding && this.building?.profile?.commercialBuildingScore !== null"
          [building]="building">
        </app-moost-profile-commercial-building-chart>
      </div>

      <div style="margin-top:24px;">
        <app-moost-notifications-charts
          *ngIf="!isLoadingBuilding"
          [filter]="notificationFilter">
        </app-moost-notifications-charts>
      </div>


      <h2 class="title">
        Load Profile
      </h2>
      <div style="margin-top:24px;" class="chart">
        <app-moost-rules-data-graph [customerBuildingId]="building?.customerBuildingId"
                                    [startTimeRangeMillis]="this.filter?.startTimestampMillis"
                                    [endTimeRangeMillis]="this.filter?.endTimestampMillis"
                                    [isSimulationRunning]="false"
                                    [isHelplineVisible]="false"
                                    class="rules-data-graph">
        </app-moost-rules-data-graph>
      </div>

      @if (building) {
        <h2 class="title">
          Devices
        </h2>
        <section>
          <app-moost-devices-table [devices]="building?.devices" class="wide">
          </app-moost-devices-table>
        </section>
      }

      <h2 class="title">
        Streaks
      </h2>
      <div>
        @if (isLoadingStreaksAndRules) {
          <mat-spinner [diameter]="24" matPrefix></mat-spinner>
        } @else {
          @if (streaks?.length > 0) {
            <table mat-table [dataSource]="streaks" class="streak-table mat-elevation-z1">
              <ng-container matColumnDef="badge">
                <th mat-header-cell *matHeaderCellDef>Counter</th>
                <td mat-cell *matCellDef="let streak">
                  <mat-icon matTooltip="Streak" [matBadge]="streak.streakCounter"
                            matBadgeSize="small">military_tech
                  </mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="ruleId">
                <th mat-header-cell *matHeaderCellDef>Rule ID</th>
                <td mat-cell *matCellDef="let streak"> {{ streak.ruleId }}</td>
              </ng-container>

              <ng-container matColumnDef="ruleName">
                <th mat-header-cell *matHeaderCellDef>Rule Name</th>
                <td mat-cell *matCellDef="let streak"> {{ getRuleName(streak.ruleId) }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          } @else {
            No streaks
          }
        }
      </div>

      <h2 class="title">
        Early Adopter
      </h2>
      <div>
        <mat-slide-toggle color="primary" (change)="toggleEarlyAdopterState($event)"
                          [checked]="building.isEarlyAdopter">
          Early Adopter
        </mat-slide-toggle>
      </div>

      <h2 class="title">
        Inactive Rules
      </h2>
      <div>
        <mat-form-field class="full-width">
          <mat-label>Deactivate rule</mat-label>
          <mat-select (selectionChange)="deactivateRule($event)">
            @for (rule of getDeactivatableRules(); track rule) {
              <mat-option [value]="rule.id">{{ rule.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <table mat-table [dataSource]="building?.inactiveRules" class="inactive-rules-table mat-elevation-z1">
          <ng-container matColumnDef="ruleId">
            <th mat-header-cell *matHeaderCellDef>Rule ID</th>
            <td mat-cell *matCellDef="let inactiveRuleId"> {{ inactiveRuleId }}</td>
          </ng-container>

          <ng-container matColumnDef="ruleName">
            <th mat-header-cell *matHeaderCellDef> Rule Name</th>
            <td mat-cell *matCellDef="let inactiveRuleId"> {{ getRuleName(inactiveRuleId) }}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let inactiveRuleId">
              <button
                (click)="reactivateRule(inactiveRuleId)"
                matTooltip="Reactivate"
                mat-icon-button
                type="button"
              >
                <mat-icon>cancel</mat-icon>
              </button>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsInactiveRules"></tr>
          <tr mat-row class="mat-row" *matRowDef="let row; columns: displayedColumnsInactiveRules;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              <p style="margin-left:8px;">No Rules deactivated</p>
            </td>
          </tr>
        </table>
      </div>

      @if (building?.settings?.tariff) {
        <div *ngIf="building.settings.tariff.type==='SINGLE'">
          <h2 class="title">Single Tariff</h2>
          <div [options]="tariffChartsOptions" echarts class="mat-elevation-z1"></div>
        </div>
        <div *ngIf="building.settings.tariff.type==='DUAL'">
          <h2 class="title">Dual Tariff</h2>
          <div [options]="tariffChartsOptions" echarts class="mat-elevation-z1"></div>
          <h3>Table</h3>
          <table mat-table [dataSource]="dualHighTariffs" class="dual-tariffs-table mat-elevation-z1">
            <ng-container matColumnDef="day">
              <th mat-header-cell *matHeaderCellDef>Weekday</th>
              <td mat-cell *matCellDef="let dualTariff"> {{ dualTariff.day }}</td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef>High Tariff</th>
              <td mat-cell *matCellDef="let dualTariff"> {{ dualTariff.value }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumnsDualTariffs"></tr>
            <tr mat-row class="mat-row" *matRowDef="let row; columns: displayColumnsDualTariffs;"></tr>
          </table>
        </div>
        <div *ngIf="building.settings.tariff.type==='DYNAMIC'">
          <h2 class="title">Dynamic Tariff</h2>
          <div [options]="tariffChartsOptions" echarts class="mat-elevation-z1"></div>
        </div>
      }
    </div>

  </div>
</div>
