import {NgxMonacoEditorConfig} from 'ngx-monaco-editor-v2';
import {editor, languages, Uri} from 'monaco-editor';
import {DatasetType, IDataset} from '../../rules.models';
import {EvaluationContext} from '../validator/evaluation-context';
import {RuleLanguageValidator} from '../validator/rule-language-validator';
import {RuleLanguageTokenizer} from '../validator/rule-language-tokenizer';
import {TermOperator} from '../validator/term-operator';
import {TermFunction} from '../validator/term-function';
import {TermStructure} from '../validator/term-structure';
import {TermItem} from '../validator/term-item';
import {TermType} from '../validator/term-type';
import {Token} from '../validator/token';
import {RuleLanguageSignatureHelpProvider} from './rule-language-signature-help-provider';
import {RuleLanguageCompletionItemProvider} from './rule-language-completion-item-provider';
import {RuleLanguageTheme} from './rule-language-theme';
import {RuleLanguageConfiguration} from './rule-language-configuration';
import IMarkerData = editor.IMarkerData;
import IMonarchLanguage = languages.IMonarchLanguage;

declare const monaco: any;

export class RuleLanguage implements NgxMonacoEditorConfig {
  static readonly COMMENT_PATTERN: RegExp = /\/\/[^\n]*/;
  static readonly TEXT_PATTERN: RegExp = /('[^']*'|"[^"]*")/;
  static readonly NUMBER_PATTERN: RegExp = /-?[0-9]+(\.[0-9]+)?/;
  static readonly VARIABLE_PATTERN: RegExp = /\$[a-zA-Z][a-zA-Z_0-9]*\b/;
  static readonly FUNCTION_PATTERN: RegExp = RuleLanguageTokenizer.toRegExp(TermFunction.getNames());
  static readonly TRANSFORMATION_OPERATOR_PATTERN: RegExp = /([a-zA-Z0-9_)]::[a-zA-Z]+)/
  static readonly EDITOR_CONFIG: RuleLanguage = new RuleLanguage();
  static readonly LANGUAGE_NAME: string = 'rule-language';
  static readonly THEME_NAME: string = 'rule-language-theme';
  static readonly MARKER_NAME: string = 'rule-language-marker';
  static readonly ARITHMETIC_OPERATOR_PATTERN: RegExp = RuleLanguageTokenizer.toRegExp(
    TermOperator.getArithmeticOperators().map((it: TermOperator) => it.symbol)
  );
  static readonly COMPARISON_OPERATOR_PATTERN: RegExp = RuleLanguageTokenizer.toRegExp(
    TermOperator.getComparisonOperators().map((it: TermOperator) => it.symbol)
  );
  static readonly LOGICAL_OPERATOR_PATTERN: RegExp = RuleLanguageTokenizer.toRegExp(
    TermOperator.getLogicalOperators().map((it: TermOperator) => it.symbol)
  );
  variables: IDataset[] = [];
  tokens: Map<Uri, Token[]> = new Map<Uri, Token[]>();
  private termResultType: Map<Uri, UriParam> = new Map();

  onMonacoLoad(): void {
    monaco.languages.register({id: RuleLanguage.LANGUAGE_NAME});
    monaco.languages.setMonarchTokensProvider(RuleLanguage.LANGUAGE_NAME, this.getMonarchTokensProvider());
    monaco.languages.setLanguageConfiguration(RuleLanguage.LANGUAGE_NAME, new RuleLanguageConfiguration());
    monaco.languages.registerCompletionItemProvider(RuleLanguage.LANGUAGE_NAME, new RuleLanguageCompletionItemProvider());
    monaco.languages.registerSignatureHelpProvider(RuleLanguage.LANGUAGE_NAME, new RuleLanguageSignatureHelpProvider())
    monaco.editor.defineTheme(RuleLanguage.THEME_NAME, new RuleLanguageTheme());
    monaco.editor.onDidCreateModel((model: editor.IModel) => this.registerModelChangeContentHandler(model));
    monaco.editor.getModels().forEach((model: editor.IModel) => this.registerModelChangeContentHandler(model));
  }

  validate(resource: Uri): void {
    const model: editor.IModel = monaco.editor.getModel(resource);
    const expression: string = model.getValue();
    const uriParam: UriParam = this.termResultType.get(model.uri);
    if (expression?.trim() && uriParam) {
      const context: EvaluationContext = new EvaluationContext();
      this.variables.forEach((variable: IDataset): void => {
        const structure: TermStructure = (DatasetType[variable.type] === DatasetType.SINGLEVALUE ? TermStructure.SCALAR : TermStructure.VECTOR);
        context.setVariable(variable.name, new TermItem(structure, TermType.EVENT))
      });
      const tokens: Token[] = new RuleLanguageValidator().evaluate(expression, context, uriParam.allowedResultTypes, uriParam.allowedResultStructures);
      this.tokens.set(resource, tokens);
      const markers: IMarkerData[] = [];
      tokens.forEach((token: Token): void => {
        if (token.hasError()) {
          markers.push({ // Remark: line number and column scale starts with 1 (not 0!) -> add +1
            message: token.getError().message,
            severity: monaco.MarkerSeverity.Error,
            startLineNumber: 1,
            startColumn: token.getError().getStartPosition() + 1,
            endLineNumber: 1,
            endColumn: token.getError().getEndPosition() + 1,
          });
        }
      });
      monaco.editor.setModelMarkers(model, RuleLanguage.MARKER_NAME, markers);
    }
  }

  updateVariables(variables: IDataset[]): void {
    this.variables = variables;
  }

  setAllowedTypesAndStructures(uri: Uri, allowedResultTypes: TermType[], allowedResultStructures: TermStructure[]): void {
    this.termResultType.set(uri, new UriParam(allowedResultTypes, allowedResultStructures));
  }

  private registerModelChangeContentHandler(model: editor.IModel): void {
    model.onDidChangeContent((): void => {
      this.validate(model.uri);
    });
  };

  private getMonarchTokensProvider(): IMonarchLanguage {
    return {
      defaultToken: 'invalid',
      ignoreCase: false,
      functions: TermFunction.getNames(),
      operators: TermOperator.getSymbols(),
      tokenizer: {
        root: [
          [RuleLanguage.COMMENT_PATTERN, MONACO_TOKEN_CLASS.COMMENT],
          [RuleLanguage.TEXT_PATTERN, MONACO_TOKEN_CLASS.TEXT],
          [RuleLanguage.NUMBER_PATTERN, MONACO_TOKEN_CLASS.NUMBER],
          [RuleLanguage.VARIABLE_PATTERN, MONACO_TOKEN_CLASS.VARIABLE],
          [RuleLanguage.ARITHMETIC_OPERATOR_PATTERN, MONACO_TOKEN_CLASS.ARITHMETIC_OPERATOR],
          [RuleLanguage.COMPARISON_OPERATOR_PATTERN, MONACO_TOKEN_CLASS.COMPARISON_OPERATOR],
          [RuleLanguage.FUNCTION_PATTERN, MONACO_TOKEN_CLASS.FUNCTION],
          [RuleLanguage.LOGICAL_OPERATOR_PATTERN, MONACO_TOKEN_CLASS.LOGICAL_OPERATOR],
          [RuleLanguage.TRANSFORMATION_OPERATOR_PATTERN, MONACO_TOKEN_CLASS.TRANSFORMATION_OPERATOR],
        ],
      },
    };
  }

}

class UriParam {
  readonly allowedResultTypes: TermType[];
  readonly allowedResultStructures: TermStructure[];

  constructor(allowedResultTypes: TermType[], allowedResultStructures: TermStructure[]) {
    this.allowedResultTypes = allowedResultTypes;
    this.allowedResultStructures = allowedResultStructures;
  }
}

export enum MONACO_TOKEN_CLASS {
  COMMENT = 'comment',
  ARITHMETIC_OPERATOR = 'arithmetic-operator',
  COMPARISON_OPERATOR = 'comparison-operator',
  VARIABLE = 'variable',
  NUMBER = 'number',
  TEXT = 'text',
  LOGICAL_OPERATOR = 'logical-operator',
  FUNCTION = 'function',
  TRANSFORMATION_OPERATOR = 'transformation-operator',
}
