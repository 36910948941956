import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MoostSettingsOverviewComponent} from './moost-settings-overview/moost-settings-overview.component';
import {MoostSettingsAddComponent} from './moost-settings-add/moost-settings-add.component';
import {AuthGuard} from '@auth0/auth0-angular';


const routes: Routes = [
  {
    path: 'settings',
    component: MoostSettingsOverviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/add',
    component: MoostSettingsAddComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {
}
