import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-moost-confirm-dialog',
  templateUrl: './moost-confirm-dialog.component.html',
  styleUrls: ['./moost-confirm-dialog.component.scss']
})
export class MoostConfirmDialogComponent {
  title: string;
  message: string;
  confirm: string;
  confirmColor: ThemePalette;
  dismiss: string;
  icon: string;

  constructor(public dialogRef: MatDialogRef<MoostConfirmDialogComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    this.title = data.title;
    this.message = data.message;
    this.confirm = data.confirm;
    this.confirmColor = data.confirmColor;
    this.dismiss = data.dismiss;
    this.icon = data.icon;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(public title: string, public message: string, public confirm: string, public dismiss: string,
              public icon: string, public confirmColor: ThemePalette = "primary") {
  }
}
