<div class="toolbar">
  <div>
    Customer Building ID {{ building?.customerBuildingId }}
  </div>
  <div [ngClass]="building?.deactivatedTimestamp ? 'inactive' : 'active'" class="status">
    {{ building?.deactivatedTimestamp ? 'Inactive since ' + (building?.deactivatedTimestamp * 1000 | date : 'dd.MM.yyyy') : 'Active' }}
  </div>
</div>

<form (ngSubmit)="onSubmit()" *ngIf="!isLoadingBuilding" [formGroup]="buildingForm">
  <div class="page-content">
    <h2 class="title">
      Location
    </h2>
    <section>
      {{ building.countryCode }}-{{ building.zip }} {{ building.city }}
    </section>

    <h2 class="title">
      Rules
    </h2>
    <section>
      <mat-checkbox color="primary" formControlName="isEarlyAdopterForm">Early Adopter</mat-checkbox>
    </section>
    <section>
      <mat-form-field appearance="outline" class="wide">
        <mat-label>Deactivated Rules</mat-label>
        <mat-select formControlName="inactiveRulesForm" multiple>
          <mat-option *ngFor="let rule of rules" [value]="rule.id">
            {{ rule.name }}
          </mat-option>
        </mat-select>
        <mat-spinner *ngIf="isLoadingRules" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
      </mat-form-field>
    </section>
  </div>

  <div class="footer-sticky mat-elevation-z3">
    <div class="button-container">
      <button
        [disabled]="!authTokenService.hasPermission(Permission.WRITE_BUILDINGS) || !(this.buildingForm.valid && this.buildingForm.dirty)"
        class="mat-flat-button"
        color="primary" mat-flat-button type="submit">
        SAVE
      </button>
      <a class="mat-flat-button" color="warn" mat-flat-button routerLink="../..">CANCEL</a>
      <mat-error *ngIf="error">{{ error }}</mat-error>
    </div>
  </div>
</form>
