<div *ngIf="errorCode === 'NOT_FOUND'" class="load-error">
  <img alt="An image presenting the numbers 404" height="307"
       ngSrc="assets/images/error-404.png" width="700"/>
  <h1>Rule not found</h1>
  <p>The selected rule was either deleted or is invalid</p>
</div>

<div *ngIf="errorCode === 'ERROR'" class="load-error">
  <h1>Internal Error</h1>
  <p>Cannot display the rule. Please try later.</p>
</div>
