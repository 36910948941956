import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MoostHeaderService {

  private title: string;
  private backRouterLink: string;

  public setHeader(title: string, backRoute?: string): void {
    this.title = title;
    const queryIndex: number = backRoute?.indexOf('?');
    this.backRouterLink = queryIndex < 0 ? backRoute : backRoute?.substring(0, queryIndex);
  }

  public setTitle(title: string): void {
    this.setHeader(title);
  }

  public getTitle(): string {
    return this.title;
  }

  public getBackRouterLink(): string {
    return this.backRouterLink;
  }
}
