import { Component, inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IRule} from '../rules.models';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ConfirmDialogModel} from '../../shared-module/moost-confirm-dialog/moost-confirm-dialog.component';

@Component({
    selector: 'app-moost-rule-import-dialog',
    templateUrl: './moost-rule-import-dialog.component.html',
    styleUrls: ['./moost-rule-import-dialog.component.scss'],
    standalone: false
})
export class MoostRuleImportDialogComponent {
  dialogRef = inject<MatDialogRef<MoostRuleImportDialogComponent, IRule>>(MatDialogRef);
  data = inject<ConfirmDialogModel>(MAT_DIALOG_DATA);


  ruleControl: FormControl<string> = new FormControl("", {
    updateOn: 'change',
    validators: [Validators.required, this.ruleValidator()]
  });
  pasteShortCut: string;

  constructor() {
    const isMac = navigator.userAgent.indexOf(' Mac ') >= 0;
    this.pasteShortCut = isMac ? "⌘+V" : "Ctrl+V"
  }

  pasteRule($event: ClipboardEvent): void {
    const ruleJson: string = $event.clipboardData.getData('text');
    this.importRule(ruleJson);
  }

  enterRule($event: KeyboardEvent): void {
    this.importRule(this.ruleControl.value);
  }

  private importRule(text: string): void {
    try {
      const rule: IRule = JSON.parse(text);
      this.dialogRef.close(rule);
    } catch (ignore) {
    }
  }

  private ruleValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      try {
        JSON.parse(control.value);
        return null;
      } catch (e) {
        return {isInvalidTerm: true};
      }
    };
  }
}

