import {Component, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {IEchartLoadingOptions} from '../../moost-charts/moost-charts.models';
import {NotificationsFilter} from '../notifications.models';
import {BehaviorSubject, EMPTY, Observable, Subscription} from 'rxjs';
import {formatDate} from '@angular/common';
import {CountByRulePerTime, NotificationsService} from '../notifications.service';
import {catchError, finalize} from 'rxjs/operators';
import {CallbackDataParams} from 'echarts/types/dist/shared';
import {ColorPalette} from '../../shared-module/color-palette';

@Component({
  selector: 'app-moost-notifications-heatmap',
  templateUrl: './moost-notifications-heatmap.component.html',
  styleUrls: ['./moost-notifications-heatmap.component.scss']
})
export class MoostNotificationsHeatmapComponent implements OnChanges, OnDestroy {
  @Input() filter: NotificationsFilter;
  isLoading: boolean;
  subscription: Subscription;
  public loadingOptions: IEchartLoadingOptions = {
    color: ColorPalette.PRIMARY,
    maskColor: '#FAFAFA',
    text: 'Loading...'
  };
  protected rulesCount: number;
  private readonly DURATION_1D_IN_MILLIS: number = 24 * 3600 * 1000;
  private chartOptionsSubject: BehaviorSubject<{}> = new BehaviorSubject({});
  chartOptions$: Observable<{}> = this.chartOptionsSubject.asObservable();

  constructor(private notificationsService: NotificationsService,
              @Inject(LOCALE_ID) private locale: string) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadCountByRulesPerTime();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  applyOnChart(counts: CountByRulePerTime[]): void {
    const data: any[] = [];
    const heat: number[][] = [];
    const days: string[] = [];
    const rules: string[] = [];
    const minHeat: number = 0;
    let maxHeat: number = 0;
    if (counts && counts.length > 0) {
      const sortedCounts: CountByRulePerTime[] = counts
        .sort((a: CountByRulePerTime, b: CountByRulePerTime): number => {
          return a.time - b.time
        });
      const startDay: number = this.toDay(sortedCounts[0].time);
      const endDay: number = this.toDay(sortedCounts[sortedCounts.length - 1].time);
      for (let day: number = startDay; day <= endDay; day++) {
        days.push(formatDate(day * this.DURATION_1D_IN_MILLIS, "dd.MM.yyyy", this.locale));
      }
      this.buildHeatTable(counts, heat, days, rules, startDay);
      maxHeat = this.getMaxHeat(heat);
      this.rulesCount = rules.length;
      const daysCount: number = days.length;
      if (this.rulesCount > 1) {
        this.addHeatRowWithSumOfRules(heat, rules, daysCount, this.rulesCount);
      }
      if (daysCount > 1) {
        this.addHeatColumnWithSumOfDays(heat, days, daysCount, this.rulesCount);
      }
    }
    for (let ruleIndex: number = 0; ruleIndex < heat.length; ruleIndex++) {
      for (let dayIndex: number = 0; dayIndex < heat[ruleIndex].length; dayIndex++) {
        const h: number = heat[ruleIndex][dayIndex];
        data.push([dayIndex, ruleIndex, h]);
      }
    }
    this.chartOptionsSubject.next({
      grid: {
        top: 12,
        left: 200,
        bottom: 90,
        right: 120
      },
      tooltip: {
        position: 'top',
        triggerOn: "click",
        appendToBody: true,
        extraCssText: 'pointer-events: auto!important',
        formatter: (params: CallbackDataParams): string => {
          const x: number = params.data[0];
          const y: number = params.data[1];
          const value: number = params.data[2];
          const day = days[x];
          const ruleId: string = rules[params.data[1]];
          const ruleName: string = this.filter.getRuleNameIfKnown(ruleId);
          let text: string = `${value} notification${value != 1 ? "s" : ""}`;
          text += days.length == 1 || x < days.length - 1 ? ` on ${day}` : ` on last ${days.length - 1} days`;
          text += rules.length == 1 || y < rules.length - 1 ? ` by rule: <a href="/rules/${ruleId}">${ruleName}</a>` : ` by all rules`;
          return text;
        }
      },
      xAxis: {
        type: 'category',
        data: days,
        axisLabel: {
          rotate: 90
        }
      },
      yAxis: {
        type: 'category',
        data: rules.map(ruleId => this.filter.getRuleNameIfKnown(ruleId))
          .map((title: string) => {
            return {
              value: title,
              textStyle: {
                overflow: 'truncate',
                width: 190
              }
            }
          }),
        axisLabel: {
          align: 'right',
        }
      },
      visualMap: {
        min: minHeat,
        max: maxHeat,
        inRange: {
          color: ['#ffffff', ColorPalette.PRIMARY]
        },
        calculable: true,
        orient: 'vertical',
        right: 30,
        top: 'center',
        itemHeight: 300
      },
      series: [
        {
          name: 'Notifications',
          type: 'heatmap',
          coordinateSystem: 'cartesian2d',
          data: data,
          label: {
            show: true,
            fontSize: 10
          },
          itemStyle: {
            borderWidth: 1,
            borderType: 'solid',
            borderColor: '#dddddd'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    });
  }

  private buildHeatTable(counts: CountByRulePerTime[], heat: number[][], days: string[], rules: string[], startDay: number): void {
    this.groupByRule(counts).forEach((countsByRule: CountByRulePerTime[], ruleId: string) => {
      const ruleIndex: number = rules.length;
      rules.push(ruleId);
      heat[ruleIndex] = Array<number>(days.length).fill(0);
      countsByRule.forEach((count: CountByRulePerTime) => {
        const dayIndex: number = this.toDay(count.time) - startDay;
        heat[ruleIndex][dayIndex] += count.count;
      });
    });
  }

  private getMaxHeat(heat: number[][]): number {
    let maxHeat: number = 0;
    for (let ruleIndex: number = 0; ruleIndex < heat.length; ruleIndex++) {
      for (let dayIndex: number = 0; dayIndex < heat[ruleIndex].length; dayIndex++) {
        const h: number = heat[ruleIndex][dayIndex];
        maxHeat = Math.max(h, maxHeat);
      }
    }
    return maxHeat;
  }

  private addHeatRowWithSumOfRules(heat: number[][], rules: string[], daysCount: number, rulesCount: number): void {
    heat[rulesCount] = Array<number>(daysCount).fill(0);
    for (let dayIndex: number = 0; dayIndex < daysCount; dayIndex++) {
      let heatSum: number = 0;
      for (let ruleIndex: number = 0; ruleIndex < rulesCount; ruleIndex++) {
        heatSum += heat[ruleIndex][dayIndex];
      }
      heat[rulesCount][dayIndex] = heatSum;
    }
    rules[rulesCount] = "Sum[rules]";
  }

  private addHeatColumnWithSumOfDays(heat: number[][], days: string[], daysCount: number, rulesCount: number): void {
    for (let ruleIndex: number = 0; ruleIndex < rulesCount; ruleIndex++) {
      let heatSum: number = 0;
      for (let dayIndex: number = 0; dayIndex < daysCount; dayIndex++) {
        heatSum += heat[ruleIndex][dayIndex];
      }
      heat[ruleIndex][daysCount] = heatSum;
    }
    days[daysCount] = "Sum[days]";
  }

  private groupByRule(counts: CountByRulePerTime[]): Map<string, CountByRulePerTime[]> {
    const groupedMap: Map<string, CountByRulePerTime[]> = new Map();
    for (let count of counts) {
      let list: CountByRulePerTime[] = groupedMap.get(count.ruleId);
      if (list === undefined) {
        list = [];
        groupedMap.set(count.ruleId, list);
      }
      list.push(count);
    }
    return groupedMap
  }

  private toDay(timestampMillis: number): number {
    return Math.trunc(timestampMillis / this.DURATION_1D_IN_MILLIS);
  }

  private loadCountByRulesPerTime(): void {
    if (this.filter) {
      this.isLoading = true;
      this.subscription = this.notificationsService.getCountByRulesPerTime(this.filter, this.DURATION_1D_IN_MILLIS)
        .pipe(
          catchError(() => EMPTY),
          finalize(() => this.isLoading = false)
        )
        .subscribe((counts: CountByRulePerTime[]) => this.applyOnChart(counts));
    }
  }
}

