import {DataUnit} from '../../events-module/events.models';
import {MinMax} from './min-max';

export class GraphScaleAligner {
  static alignedUnitsWithMinMax(unitsWithMinMax: Map<DataUnit, MinMax>): Map<DataUnit, MinMax> {
    const normalizationFactor = new Map<DataUnit, number>();
    // calculate normalization factor for each DataUnit...
    unitsWithMinMax.forEach((minMax: MinMax, unit: DataUnit) => {
      let min: number = Math.min(minMax.min, 0);
      let max: number = Math.max(minMax.max, 0);
      if (min === max) {
        max = 1;
      }
      const factor: number = Math.max(Math.abs(min), Math.abs(max));
      normalizationFactor.set(unit, factor);
    });
    // calculate normalized values for each DataUnit and find minimum and maximum across all DataUnits...
    let normalizedOverallMin: number = 0;
    let normalizedOverallMax: number = 0;
    unitsWithMinMax.forEach((minMax: MinMax, unit: DataUnit) => {
      const factor: number = normalizationFactor.get(unit);
      const normalizedMin: number = (minMax.min / factor);
      const normalizedMax: number = minMax.max / factor;
      normalizedOverallMin = Math.min(normalizedOverallMin, normalizedMin);
      normalizedOverallMax = Math.max(normalizedOverallMax, normalizedMax);
    });
    // calculate extended min/max range for each DataUnit, so that zero line is aligned for each DataUnit
    const alignedUnitsWithMinMax = new Map();
    unitsWithMinMax.forEach((minMax: MinMax, unit: DataUnit) => {
      const factor: number = normalizationFactor.get(unit);
      const alignedMin: number = normalizedOverallMin * factor;
      const alignedMax: number = normalizedOverallMax * factor;
      alignedUnitsWithMinMax.set(unit, {min: alignedMin, max: alignedMax});
    });
    return alignedUnitsWithMinMax;
  }
}

