<div>
  <div class="filter">
    <app-moost-notifications-filter
      (applyFilterChange)="applyFilter($event)"
      [filter]="initFilter"
    ></app-moost-notifications-filter>
  </div>

  <div class="page-content">
    <h2 class="title">Dashboard</h2>
    <app-moost-notifications-charts
      [filter]="filter"
    >
    </app-moost-notifications-charts>

    <h2 class="title" style="margin-top:24px;">Data</h2>
    <app-moost-notifications-table
      [filter]="filter"
    >
    </app-moost-notifications-table>
  </div>
</div>
