<div class="dataset-list">
  <mat-chip-listbox>
    <app-moost-dataset-chip (click)="openDatasetDetail(dataset)"
                            (datasetRemoved)="removeDataset($event)"
                            *ngFor="let dataset of datasets"
                            [color]="this.datasetsService.getHexColorStringForDataset(dataset, datasets)"
                            [dataset]="dataset">
    </app-moost-dataset-chip>

    <mat-chip (click)="openDatasetDetail()" class="add-btn">
      <p>Add Dataset</p>
      <mat-icon matChipAvatar>add</mat-icon>
    </mat-chip>
  </mat-chip-listbox>
</div>
