export class FulfillmentPreference {
  static readonly TYPE_REST: string = 'REST';
  static readonly TYPE_EMAIL: string = 'EMAIL';
  static readonly TYPE_FIREBASE_CLOUD_MESSAGING: string = 'FIREBASE_CLOUD_MESSAGING';
  static readonly TYPE_CHOICE: string[] = [
    FulfillmentPreference.TYPE_REST,
    FulfillmentPreference.TYPE_EMAIL,
    FulfillmentPreference.TYPE_FIREBASE_CLOUD_MESSAGING];

  static readonly REST_AUTH_STRATEGY_APITOKEN: string = 'APITOKEN';
  static readonly REST_AUTH_STRATEGY_BEARER: string = 'BEARER';
  static readonly REST_AUTH_STRATEGY_BASIC: string = 'BASIC';
  static readonly REST_AUTH_STRATEGY_CHOICE: string[] = [
    FulfillmentPreference.REST_AUTH_STRATEGY_APITOKEN,
    FulfillmentPreference.REST_AUTH_STRATEGY_BEARER,
    FulfillmentPreference.REST_AUTH_STRATEGY_BASIC];


  id?: string;
  customerId?: string;
  restApiToken?: string;
  restBearerToken?: string;
  restBasicAuthUser?: string;
  restBasicAuthPassword?: string;
  restHostname?: string;
  restPath?: string;
  restIsSecure?: boolean;
  restAuthStrategy?: string;
  type?: string;
  recipientEmailAddress?: string;
  customerBuildingId?: string;
  fcmToken?: string;
}

export interface IFulfillmentPreference extends FulfillmentPreference {
}
