<div>
  <form [formGroup]="columnSelectionForm">
    <mat-form-field appearance="outline" class="displayed-columns">
      <mat-label>Displayed Columns</mat-label>
      <mat-select (selectionChange)="setVisibleColumns($event.value)" formControlName="columnSelectionField" multiple>
        <mat-option *ngFor="let column of allColumns" [value]="column">
          {{ column }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <table [dataSource]="dataSource" class="notifications-table" mat-table
         matSort
         matSortActive="createdAtTimeMillis"
         matSortDirection="desc"
         matSortDisableClear>

    <ng-container matColumnDef="Timestamp">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Timestamp</th>
      <td *matCellDef="let pushNotification" mat-cell>
        {{ pushNotification.createdAtTimeMillis | date: 'dd.MM.yyyy HH:mm:ss.SSS' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Building">
      <th *matHeaderCellDef mat-header-cell>Building</th>
      <td *matCellDef="let pushNotification" mat-cell>
        <a [routerLink]="['/buildings/', pushNotification.customerBuildingId]" queryParamsHandling="merge">
          {{ pushNotification.customerBuildingId }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="Rule">
      <th *matHeaderCellDef mat-header-cell>Rule</th>
      <td *matCellDef="let pushNotification" mat-cell>
        <a [routerLink]="['/rules', pushNotification.ruleId]">
          {{ pushNotification.ruleId }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="Title">
      <th *matHeaderCellDef mat-header-cell>Title</th>
      <td *matCellDef="let pushNotification" mat-cell>
        {{ pushNotification?.notification?.texts?.en?.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Message">
      <th *matHeaderCellDef mat-header-cell>Message</th>
      <td *matCellDef="let pushNotification" mat-cell>
        {{ pushNotification?.notification?.texts?.en?.message }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th *matHeaderCellDef mat-header-cell>Status</th>
      <td *matCellDef="let pushNotification" mat-cell>
        {{ (pushNotification?.delivery_status?.status || 'PENDING') | titlecase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Reason">
      <th *matHeaderCellDef mat-header-cell>Reason</th>
      <td *matCellDef="let pushNotification" mat-cell>
        {{ notificationsService.renderDeliveryStatusReason(pushNotification?.delivery_status?.reason) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Interaction">
      <th *matHeaderCellDef mat-header-cell>Interaction</th>
      <td *matCellDef="let pushNotification" mat-cell>
        {{ (pushNotification.interaction?.actionQualifier || 'VIEWED') | titlecase }}
      </td>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="9999">
        No data matching the search
      </td>
    </tr>
  </table>

  <mat-paginator (page)="loadNotifications()"
                 [hidden]="dataSource.loading$ | async"
                 [length]="dataSource.length$ | async"
                 [pageSizeOptions]="[5, 10, 15, 20, 25]"
                 [pageSize]="10">
  </mat-paginator>
</div>
