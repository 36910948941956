import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Dataset, DatasetType, IDataset} from './rules.models';
import {ZRLineType} from 'echarts/types/src/util/types';
import {ColorPalette} from '../shared-module/color-palette';

@Injectable({
  providedIn: 'root',
})
export class DatasetsService {
  datasetsSource: BehaviorSubject<IDataset[]> = new BehaviorSubject([]);
  private datasets: IDataset[];

  constructor() {
    this.datasetsSource.subscribe((datasets: IDataset[]): void => {
      this.datasets = datasets;
    });
  }

  public getHexColorStringForDataset(dataset: Dataset, datasets: Dataset[]): string {
    const colorPaletteSet: string[] = ColorPalette.getEventTypeBasedColorSet(dataset.event_types[0]);
    const colorPaletteIndex: number = datasets.filter(it => ColorPalette.getEventTypeBasedColorSet(it.event_types[0]) === colorPaletteSet)
      .findIndex((it: IDataset): boolean => it.name === dataset.name);
    if (colorPaletteIndex === -1) {
      throw new Error(`No dataset with name '${dataset.name}' found in datasets`);
    }
    // if (colorPaletteIndex >= colors.length) {
    //   console.error("There are not enough colors on the color palette");
    // }
    return colorPaletteSet[colorPaletteIndex % colorPaletteSet.length];
  }

  public getLineStyle(dataset: Dataset): ZRLineType {
    const eventType: string = dataset.event_types[0];
    if (eventType.includes('FORECAST')) {
      return 'dotted';
    } else {
      return 'solid';
    }
  }

  public getDatasetMaxTimeframe(datasets: IDataset[]): number {
    let maxTimeframe: number = 0;

    const datasetTimeSeries: IDataset [] = datasets.filter((d: IDataset): boolean => {
      return d.type === DatasetType.TIMESERIES
    });

    if (datasetTimeSeries && datasetTimeSeries.length > 0) {
      const dataset: IDataset = datasetTimeSeries.reduce(
        (prev: IDataset, current: IDataset): IDataset => {
          return (prev && prev.timeframe > current.timeframe) ? prev : current
        });

      maxTimeframe = dataset?.timeframe;
    }

    return maxTimeframe;
  }
}
