<ng-container>
  <button [matMenuTriggerFor]="menu" class="large-icon-button" mat-icon-button>
    <mat-icon>account_circle</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <a mat-menu-item routerLink="/profile">
      <mat-icon class="material-icons-outlined">account_circle</mat-icon>
      <div class="menu-row">{{ authTokenService?.getUsername() }}</div>
      <div class="menu-row" style="font-weight: lighter">{{ customer?.name }}</div>
    </a>

    <mat-divider></mat-divider>

    <app-logout-button></app-logout-button>

  </mat-menu>
</ng-container>
