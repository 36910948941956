<div class="filter-bar">
  <form [formGroup]="filterForm" class="events-filter">

    <mat-form-field appearance="outline">
      <mat-label>Customer Building ID</mat-label>
      <input formControlName="customerBuildingIds" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="types-field">
      <mat-label>Type</mat-label>
      <mat-select formControlName="types" multiple>
        <mat-option *ngFor="let type of typesChoice" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
      <mat-spinner *ngIf="isLoadingEventTypes" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
    </mat-form-field>

    <mat-form-field appearance="outline" class="sources-field">
      <mat-label>Source</mat-label>
      <mat-select formControlName="sources" multiple>
        <mat-option *ngFor="let source of sourcesChoice" [value]="source">
          {{ source }}
        </mat-option>
      </mat-select>
      <mat-spinner *ngIf="isLoadingSourceTypes" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Device Name</mat-label>
      <input formControlName="deviceNames" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [max]="maxDate" [rangePicker]="picker" formGroupName="range">
        <input formControlName="startTimestampMillis" matStartDate
               placeholder="Start date">
        <input formControlName="endTimestampMillis" matEndDate
               placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>

  <button (click)="clearFilter()" color="gray" mat-icon-button
          matTooltip="Clear filter">
    <mat-icon>delete_outline</mat-icon>
  </button>
</div>
