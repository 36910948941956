import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EMPTY, Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {EventsFilter} from '../events.models';
import {EventsService} from '../events.service';
import {catchError, finalize} from 'rxjs/operators';
import {Customer} from '../../shared-module/customer.model';
import {CustomerService} from '../../shared-module/customer.service';
import {UtilityService} from '../../shared-module/utility.service';

@Component({
  selector: 'app-moost-events-filter',
  templateUrl: './moost-events-filter.component.html',
  styleUrls: ['./moost-events-filter.component.scss'],
  standalone: false
})

export class MoostEventsFilterComponent implements OnInit, OnDestroy {
  private eventsService: EventsService = inject(EventsService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private utilityService: UtilityService = inject(UtilityService);
  private customerService: CustomerService = inject(CustomerService);

  @Output() applyFilterChange: EventEmitter<EventsFilter> = new EventEmitter<EventsFilter>()
  filterForm: FormGroup = new FormGroup({
    customerBuildingIds: new FormControl<string>(""),
    sources: new FormControl<string[]>(null),
    types: new FormControl<string[]>(null),
    deviceNames: new FormControl<string>(null),
    range: new FormGroup({
      startTimestampMillis: new FormControl<Date>(null, [Validators.required]),
      endTimestampMillis: new FormControl<Date>(null, [Validators.required]),
    })
  });
  filter: EventsFilter;
  sourcesChoice: string[] = [];
  typesChoice: string[] = [];

  isLoadingEventTypes: boolean = false;
  isLoadingSourceTypes: boolean = false;
  maxDate: Date;
  private loadEventTypesSubscription: Subscription;
  private loadSourceTypesSubscription: Subscription;
  private valueChangesSubscription: Subscription;
  private routeSubscription: Subscription;
  private customerSubscription: Subscription;
  private disableEventEmit: boolean = false;
  private isInitializing: boolean = true;

  ngOnDestroy(): void {
    this.loadEventTypesSubscription?.unsubscribe();
    this.loadSourceTypesSubscription?.unsubscribe();
    this.valueChangesSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.customerSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe((queryParams: Params): void => {
      this.customerSubscription = this.customerService.getCustomer().subscribe((customer: Customer): void => {
        const paramStartTimestamp: number = queryParams['s'];
        const paramEndTimestamp: number = queryParams['e'];

        this.maxDate = this.utilityService.getCustomerSpecificMoment(customer.id).endOf('day').toDate();

        this.filter = new EventsFilter(
          Number(paramStartTimestamp) || this.utilityService.getCustomerSpecificMoment(customer.id).startOf('day').toDate().getTime(),
          Number(paramEndTimestamp) || this.utilityService.getCustomerSpecificMoment(customer.id).endOf('day').toDate().getTime(),
          (queryParams['building'] ? queryParams['building']?.split(",") : null),
          (queryParams['source'] ? queryParams['source']?.split(",") : null),
          (queryParams['type'] ? queryParams['type']?.split(",") : null),
          (queryParams['deviceName'] ? queryParams['deviceName']?.split(",") : null)
        );

        if (this.isInitializing) {
          this.changeFormData()
          this.emitFilterChanged(this.filter);
        } else if ((this.filterForm.valid && this.filterForm.get("range").get("endTimestampMillis").errors == null)) {
          this.changeFormData()
        }

        this.isInitializing = false;
      });
    });

    this.valueChangesSubscription = this.filterForm.valueChanges.subscribe((): void => {
      if (this.filterForm.valid && this.filterForm.get("sources").enabled && this.filterForm.get("types").enabled &&
        !this.disableEventEmit && this.filterForm.get("range").get("endTimestampMillis").errors == null) {
        this.emitFilterChanged(this.buildEventsFilter(this.filterForm));
      }
    });
  }

  clearFilter(): void {
    this.resetForm(this.filterForm);
  }

  private changeFormData(): void {
    this.setFormValues(this.filter);
    this.loadTypes();
    this.loadSources();
  }

  private emitFilterChanged(filter: EventsFilter): void {
    this.applyFilterChange.emit(filter);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          s: filter.startTimestampMillis,
          e: filter.endTimestampMillis,
          building: (filter.customerBuildingIds && filter.customerBuildingIds.length > 0 ? filter.customerBuildingIds?.join(",") : null),
          source: (filter.sources ? filter.sources?.join(",") : null),
          type: (filter.types ? filter.types?.join(",") : null),
          deviceName: (filter.deviceNames ? filter.deviceNames?.join(",") : null),
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private loadTypes(): void {
    this.filterForm.get("types").disable({emitEvent: false});
    this.isLoadingEventTypes = true;
    this.loadEventTypesSubscription = this.eventsService.getEventTypes().pipe(
      catchError(() => EMPTY),
      finalize((): boolean => this.isLoadingEventTypes = false)
    ).subscribe((types: string[]): void => {
      this.typesChoice = types;
      this.filterForm.get("types").enable({emitEvent: false});
    });
  }

  private loadSources(): void {
    this.filterForm.get("sources").disable({emitEvent: false});
    this.isLoadingSourceTypes = true;
    this.loadSourceTypesSubscription = this.eventsService.getSourceTypes().pipe(
      catchError(() => EMPTY),
      finalize((): boolean => this.isLoadingSourceTypes = false)
    ).subscribe((sources: string[]): void => {
      this.sourcesChoice = sources;
      this.filterForm.get("sources").enable({emitEvent: false});
    });
  }

  private setFormValues(filter: EventsFilter): void {
    this.disableEventEmit = true;
    this.filterForm.patchValue({
      customerBuildingIds: filter?.customerBuildingIds?.join(","),
      sources: filter?.sources,
      types: filter?.types,
      deviceNames: filter?.deviceNames,
      range: {
        startTimestampMillis: new Date(filter?.startTimestampMillis),
        endTimestampMillis: new Date(filter?.endTimestampMillis),
      }
    });
    this.disableEventEmit = false;
  }

  private resetForm(filterForm: FormGroup): void {
    filterForm.get("customerBuildingIds").reset();
    filterForm.get("sources").reset();
    filterForm.get("types").reset();
    filterForm.get("deviceNames").reset();
  }

  private buildEventsFilter(filterForm: FormGroup): EventsFilter {
    return new EventsFilter(
      filterForm.get("range").get("startTimestampMillis").value.valueOf(),
      filterForm.get("range").get("endTimestampMillis").value.valueOf(),
      filterForm.get("customerBuildingIds").value?.split(","),
      filterForm.get("sources").value,
      filterForm.get("types").value,
      null,
      filterForm.get("deviceNames").value?.split(",")
    );
  }
}
