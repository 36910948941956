import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {catchError, finalize} from 'rxjs/operators';
import {EMPTY, Subscription} from 'rxjs';
import {RulesService} from '../../rules-module/rules.service';
import {IRule} from '../../rules-module/rules.models';
import {ActionQualifier, DeliveryStatuses, NotificationsFilter} from '../notifications.models';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Customer} from '../../shared-module/customer.model';
import {UtilityService} from '../../shared-module/utility.service';
import {CustomerService} from '../../shared-module/customer.service';


@Component({
  selector: 'app-moost-notifications-filter',
  templateUrl: './moost-notifications-filter.component.html',
  styleUrls: ['./moost-notifications-filter.component.scss'],
  standalone: false
})

export class MoostNotificationsFilterComponent implements OnInit, OnDestroy {
  private rulesService: RulesService = inject(RulesService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private utilityService: UtilityService = inject(UtilityService);
  private customerService: CustomerService = inject(CustomerService);

  @Output() applyFilterChange: EventEmitter<NotificationsFilter> = new EventEmitter<NotificationsFilter>()
  filterForm: FormGroup = new FormGroup({
    customerBuildingIds: new FormControl<string>(""),
    ruleIds: new FormControl<string[]>(null),
    deliveryStatuses: new FormControl<string[]>(null, [Validators.required]),
    interactions: new FormControl<string[]>(null),
    range: new FormGroup({
      startTimestampMillis: new FormControl<Date>(null, [Validators.required]),
      endTimestampMillis: new FormControl<Date>(null, [Validators.required]),
    })
  });
  filter: NotificationsFilter;
  rules: IRule[];
  ruleIdsChoice: string[];
  deliveryStatusesChoice: string[] = Object.keys(DeliveryStatuses);
  interactionsChoice: string[] = Object.keys(ActionQualifier);
  isLoadingRules: boolean = false;
  maxDate: Date;
  private loadRulesSubscription: Subscription;
  private valueChangesSubscription: Subscription;
  private routeSubscription: Subscription;
  private customerSubscription: Subscription;
  private disableEventEmit: boolean = false;
  private isInitializing: boolean = true;

  ngOnDestroy(): void {
    this.loadRulesSubscription?.unsubscribe();
    this.valueChangesSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.customerSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe((queryParams: Params): void => {
      this.customerSubscription = this.customerService.getCustomer().subscribe((customer: Customer): void => {
        const paramStartTimestamp: number = queryParams['s'];
        const paramEndTimestamp: number = queryParams['e'];

        this.maxDate = this.utilityService.getCustomerSpecificMoment(customer.id).endOf('day').toDate();

        this.filter = new NotificationsFilter(
          Number(paramStartTimestamp) || this.utilityService.getCustomerSpecificMoment(customer.id).startOf('day').subtract({days: 14}).toDate().getTime(),
          Number(paramEndTimestamp) || this.utilityService.getCustomerSpecificMoment(customer.id).endOf('day').toDate().getTime(),
          (queryParams['building'] ? queryParams['building']?.split(",") : null),
          (queryParams['rule'] ? queryParams['rule']?.split(",") : null),
          (this.rules ? this.rules : null),
          (queryParams['deliveryStatus'] === undefined ? [DeliveryStatuses.DELIVERED] : queryParams['deliveryStatus']?.split(",")),
          (queryParams['interaction'] ? queryParams['interaction']?.split(",") : null)
        );

        if (this.isInitializing) {
          this.changeFormData(this.isInitializing)
          this.emitFilterChanged(this.filter);
        } else if ((this.filterForm.valid && this.filterForm.get("range").get("endTimestampMillis").errors == null)) {
          this.changeFormData(this.isInitializing)
        }

        this.isInitializing = false;
      });
    });

    this.valueChangesSubscription = this.filterForm.valueChanges.subscribe((): void => {
      if (this.filterForm.valid && this.filterForm.get("ruleIds").enabled && !this.disableEventEmit &&
        this.filterForm.get("range").get("endTimestampMillis").errors == null) {
        this.emitFilterChanged(this.buildNotificationsFilter(this.filterForm));
      }
    });
  }

  clearFilter(): void {
    this.resetForm(this.filterForm);
  }

  protected getRule(ruleId: string): IRule {
    return this.rules.find((rule: IRule): boolean => rule.id === ruleId);
  }

  protected getRuleIdsSortedByName(): string[] {
    return this.rules
      ?.sort((a: IRule, b: IRule): number => a.name.localeCompare(b.name))
      ?.map((rule: IRule): string => rule.id);
  }

  private changeFormData(isInit: boolean): void {
    this.setFormValues(this.filter);
    this.loadRules(isInit);
  }

  private emitFilterChanged(filter: NotificationsFilter): void {
    this.applyFilterChange.emit(filter);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          s: filter.startTimestampMillis,
          e: filter.endTimestampMillis,
          building: (filter.customerBuildingIds && filter.customerBuildingIds.length > 0 ? filter.customerBuildingIds?.join(",") : null),
          rule: (filter.ruleIds && filter.ruleIds.length > 0 ? filter.ruleIds?.join(",") : null),
          deliveryStatus: (filter.deliveryStatuses && filter.deliveryStatuses.length > 0 ? filter.deliveryStatuses?.join(",") : null),
          interaction: (filter.interactions ? filter.interactions?.join(",") : null),
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private loadRules(isInit: boolean): void {
    this.filterForm.get("ruleIds").disable({emitEvent: false});
    this.isLoadingRules = true;
    this.loadRulesSubscription = this.rulesService.getAllRules().pipe(
      catchError(() => EMPTY),
      finalize((): boolean => this.isLoadingRules = false)
    ).subscribe((rules: IRule[]): void => {
      this.rules = rules;
      this.ruleIdsChoice = rules.map((rule: IRule): string => rule.id);
      this.filterForm.get("ruleIds").enable({emitEvent: false});
      if (isInit) {
        this.emitFilterChanged(this.buildNotificationsFilter(this.filterForm));
      }
    });
  }

  private setFormValues(filter: NotificationsFilter): void {
    this.disableEventEmit = true;
    this.filterForm.patchValue({
      customerBuildingIds: filter?.customerBuildingIds?.join(","),
      ruleIds: filter?.ruleIds,
      deliveryStatuses: filter?.deliveryStatuses,
      interactions: filter?.interactions,
      range: {
        startTimestampMillis: new Date(filter?.startTimestampMillis),
        endTimestampMillis: new Date(filter?.endTimestampMillis),
      }
    });
    this.disableEventEmit = false;
  }

  private resetForm(filterForm: FormGroup): void {
    filterForm.get("customerBuildingIds").reset();
    filterForm.get("ruleIds").reset();
    filterForm.get("deliveryStatuses").reset();
    filterForm.get("interactions").reset();
  }

  private buildNotificationsFilter(filterForm: FormGroup): NotificationsFilter {
    return new NotificationsFilter(
      filterForm.get("range").get("startTimestampMillis").value.valueOf(),
      filterForm.get("range").get("endTimestampMillis").value.valueOf(),
      filterForm.get("customerBuildingIds").value?.split(","),
      filterForm.get("ruleIds").value,
      this.rules,
      filterForm.get("deliveryStatuses").value,
      filterForm.get("interactions").value
    );
  }
}
