import { Injectable, inject } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Streak} from './rules.models';

@Injectable({
  providedIn: 'root',
})
export class StreaksService {
  private httpClient = inject(HttpClient);

  streaksUrl: string = `${environment.apiUrl}/streaks`;

  getStreaksByCustomerBuildingId(customerBuildingId: string): Observable<Streak[]> {
    let params: HttpParams = new HttpParams()
      .set('customerBuildingId', customerBuildingId);

    return this.httpClient.get<Streak[]>(`${this.streaksUrl}/v1`, {
      params
    });
  }

  getStreaksByRuleId(ruleId: string): Observable<Streak[]> {
    let params: HttpParams = new HttpParams()
      .set('ruleId', ruleId);

    return this.httpClient.get<Streak[]>(`${this.streaksUrl}/v1`, {
      params
    });
  }
}
