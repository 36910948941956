<div class="filter-bar">
  <form [formGroup]="filterForm" class="filter">

    <mat-form-field appearance="outline" class="delivery-statuses-field">
      <mat-label>Delivery Status</mat-label>
      <mat-select formControlName="deliveryStatuses" multiple>
        @for (deliveryStatus of deliveryStatusesChoice; track deliveryStatus) {
          <mat-option [value]="deliveryStatus">
            {{ deliveryStatus }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [max]="maxDate" [rangePicker]="picker">
        <input formControlName="startTimestampMillis" matStartDate
               placeholder="Start date">
        <input formControlName="endTimestampMillis" matEndDate
               placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>

  <button (click)="clearFilter()" color="gray" mat-icon-button
          matTooltip="Clear filter">
    <mat-icon>delete_outline</mat-icon>
  </button>
</div>
