import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  MoostNotificationsOverviewComponent
} from './moost-notifications-overview/moost-notifications-overview.component';
import {AuthGuard} from '@auth0/auth0-angular';


const routes: Routes = [
  {
    path: 'notifications',
    component: MoostNotificationsOverviewComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule {
}
