import {Component, Input} from '@angular/core';
import {NotificationsFilter} from '../notifications.models';

@Component({
    selector: 'app-moost-notifications-charts',
    templateUrl: './moost-notifications-charts.component.html',
    styleUrls: ['./moost-notifications-charts.component.scss'],
    standalone: false
})
export class MoostNotificationsChartsComponent {
  @Input() filter: NotificationsFilter;
  @Input() showPerRuleChart: boolean = true;

}
