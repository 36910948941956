import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {DataPoint, Dataset, IRule, Rule, RuleVersion} from './rules.models';
import {IPushNotification} from '../notifications-module/notifications.models';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RulesService {
  rulesUrl: string = `${environment.apiUrl}/rules`;

  constructor(private httpClient: HttpClient) {
  }

  getAllRules(): Observable<IRule[]> {
    return this.httpClient.get<IRule[]>(`${this.rulesUrl}/v1`, {});
  }

  getAllLibraryRules(): Observable<IRule[]> {
    return this.httpClient.get<IRule[]>(`${this.rulesUrl}/library/v1`, {});
  }

  getLibraryRule(id: string): Observable<IRule> {
    return this.getAllLibraryRules().pipe(map(rules => rules.find(rule => rule.id === id)));
  }

  getRuleById(id: string): Observable<IRule> {
    return this.httpClient.get<IRule>(`${this.rulesUrl}/${id}/v1`, {});
  }

  getRuleVersionsByRuleId(ruleId: string): Observable<RuleVersion[]> {
    return this.httpClient.get<RuleVersion[]>(`${this.rulesUrl}/${ruleId}/histories/v1`, {});
  }

  updateRule(rule: IRule): Observable<void> {
    return this.httpClient.put<void>(`${this.rulesUrl}/${rule.id}/v1`, rule, {});
  }

  deleteRule(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.rulesUrl}/${id}/v1`, {});
  }

  addRule(rule: IRule): Observable<IRule> {
    return this.httpClient.post<IRule>(`${this.rulesUrl}/v1`, rule, {});
  }

  runSimulation(rule: Rule, customerBuildingId: string, fromSeconds: number, toSeconds: number): Observable<IPushNotification[]> {
    const params: HttpParams = new HttpParams()
      .set('customerBuildingId', customerBuildingId)
      .set('from', fromSeconds)
      .set('to', toSeconds);

    return this.httpClient.post<IPushNotification[]>(`${this.rulesUrl}/simulation/v1`, rule, {
      params: params
    });
  }

  evaluateSimulationTerm(term: string, datasets: Dataset[], customerBuildingId: string, fromSeconds: number, toSeconds: number): Observable<Map<string, DataPoint[]>> {
    const body = {
      datasets: datasets,
      term: term
    }
    return this.httpClient.post<Map<string, DataPoint[]>>(`${this.rulesUrl}/simulation/term/v1`, body, {
      params: new HttpParams()
        .set('customerBuildingId', customerBuildingId)
        .set('from', fromSeconds)
        .set('to', toSeconds)
    });
  }

}
