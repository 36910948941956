@if (String(target.value) === String(source)) {
  <button [disabled]="true" class="comparison-button" mat-flat-button>=</button>
} @else {
  <button (click)="target.setValue(source)"
          [matTooltip]="
          'Current: [' + target.value +']\n' +
          'Previous: [' + source + ']'"
          class="comparison-button"
          color="accent"
          matTooltipClass="multiline"
          mat-flat-button>
    <
  </button>
}
