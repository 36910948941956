import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-login',
    template: '',
    standalone: false
})
export class LoginComponent implements OnInit, OnDestroy {
  private route = inject(ActivatedRoute);

  private auth0AuthorizeUrl: string = "https://" + environment.auth0.domain + "/authorize"
  private queryParamsSubscription: Subscription;

  ngOnInit(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe({
      next: (values: Params): void => {
        let queryParamsString: string = "?response_type=code&client_id=" + environment.auth0.clientId +
          "&redirect_uri=" + window.location.origin + "&";
        queryParamsString += Object.keys(values).map((key: string): string => `${key}=${values[key]}`).join('&');
        window.location.href = this.auth0AuthorizeUrl + queryParamsString;
      },
      error: (error): void => {
        console.error(error);
      }
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription?.unsubscribe();
  }
}
