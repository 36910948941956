import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MoostBuildingsOverviewComponent} from './moost-buildings-overview/moost-buildings-overview.component';
import {MoostBuildingsDetailComponent} from './moost-buildings-detail/moost-buildings-detail.component';
import {AuthGuard} from '@auth0/auth0-angular';
import {
  MoostBuildingsConfigurationComponent
} from './moost-buildings-configuration/moost-buildings-configuration.component';

const routes: Routes = [
  {
    path: 'buildings',
    component: MoostBuildingsOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'buildings/:id',
    component: MoostBuildingsDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'buildings/:id/edit',
    component: MoostBuildingsConfigurationComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuildingsRoutingModule {
}
