<mat-toolbar>Navigation</mat-toolbar>
<mat-nav-list>
  <mat-list-item (click)="drawer.close()" [routerLinkActive]="['is-active']" class="top-separator"
                 routerLink="/notifications">
    <mat-icon class="material-icons-outlined" matListItemIcon>email</mat-icon>
    <span matListItemTitle>Notifications</span>
  </mat-list-item>
  <mat-list-item (click)="drawer.close()" [routerLinkActive]="['is-active']"
                 routerLink="/events">
    <mat-icon class="material-icons-outlined" matListItemIcon>table_chart</mat-icon>
    <span matListItemTitle>Events</span>
  </mat-list-item>
  <mat-list-item (click)="drawer.close()" [routerLinkActive]="['is-active']"
                 routerLink="/rules">
    <mat-icon matListItemIcon>rule</mat-icon>
    <span matListItemTitle>Rules</span>
  </mat-list-item>
  <mat-list-item (click)="drawer.close()"
                 [routerLinkActive]="['is-active']" routerLink="/buildings">
    <mat-icon class="material-icons-outlined" matListItemIcon>apartment</mat-icon>
    <span matListItemTitle>Buildings</span>
  </mat-list-item>
  <mat-list-item (click)="this.redirectToBillingPortal()"
                 *ngIf="authTokenService.hasPermission(Permission.ADMIN_BILLING)"
                 [routerLinkActive]="['is-active']">
    <mat-icon class="material-icons-outlined" matListItemIcon>payment</mat-icon>
    <span matListItemTitle>Billing</span>
  </mat-list-item>
  <mat-list-item (click)="drawer.close()"
                 *ngIf="authTokenService.hasPermission(Permission.WRITE_CUSTOMERS)"
                 [routerLinkActive]="['is-active']"
                 routerLink="/settings">
    <mat-icon class="material-icons-outlined" matListItemIcon>settings</mat-icon>
    <span matListItemTitle>Settings</span>
  </mat-list-item>
  <mat-list-item class="footer top-separator">
    <a (click)="drawer.close()"
       href="https://moost.atlassian.net/servicedesk/customer/portals"
       target="_blank">
      Moost Help Center
    </a>
    <mat-icon class="material-icons-outlined" matListItemIcon>support_agent</mat-icon>
  </mat-list-item>
</mat-nav-list>
