export interface ITermFunctionDocumentationHelp {
  signatures: ITermFunctionSignatureHelp[],
  description: string,
  example: string
}

export interface ITermFunctionSignatureHelp {
  signature: string;
  params: Map<string, string>
}

export class TermFunction {
  static readonly FUNCTIONS: TermFunction[] = [];

  static readonly AVG_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "AVG(vector: Vector<Number/Event>): Scalar<Number>",
      params: new Map([["vector", "A vector with elements of type <Number/Event>"]])
    }, {
      signature: "AVG(group: GroupedScalar<Device/Time,Number/Event>): Scalar<Number>",
      params: new Map([["group", "A GroupedScalar with elements of type <Device/Time,Number/Event>"]])
    }, {
      signature: "AVG(group: GroupedVector<any,Number/Event>): GroupedScalar<any,Number>",
      params: new Map([["group", "A GroupedVector with elements of type <any, Number/Event>"]])
    }],
    description: "Calculates the average on a set of numbers or event values",
    example: "AVG($PowerConsumption) > 10"
  };
  static readonly SUM_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "SUM(vector: Vector<Number/Event>): Scalar<Number>",
      params: new Map([["vector", "A vector with elements of type <Number/Event>"]])
    }, {
      signature: "SUM(group: GroupedScalar<any,Number/Event>): Scalar<Number>",
      params: new Map([["group", "A GroupedScalar with elements of type <any, Number/Event>"]])
    }, {
      signature: "SUM(group: GroupedVector<any,Number/Event>): GroupedScalar<any,Number>",
      params: new Map([["group", "A GroupedVector with elements of type <any, Number/Event>"]])
    }],
    description: "Return the sum of all values that are in the given dataset.",
    example: "SUM($PowerConsumption) > 1439.2"
  };
  static readonly COUNT_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "COUNT(vector: Vector<Number/Event>): Scalar<Number>",
      params: new Map([["vector", "A vector with elements of type <Number/Event>"]])
    }, {
      signature: "COUNT(group: GroupedScalar<any,Number/Event>): Scalar<Number>",
      params: new Map([["group", "A GroupedScalar with elements of type <any, Number/Event>"]])
    }, {
      signature: "COUNT(group: GroupedVector<any,Number/Event>): GroupedScalar<any,Number>",
      params: new Map([["group", "A GroupedVector with elements of type <any, Number/Event>"]])
    }],
    description: "Return the count of all values that are in the given dataset.",
    example: "COUNT($PowerConsumption) > 10"
  };
  static readonly MIN_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "MIN(vector: Vector<Number/Event>): Scalar<Number>",
      params: new Map([["vector", "A vector with elements of type <Number/Event>"]])
    }, {
      signature: "MIN(group: GroupedScalar<any,Number/Event>): Scalar<Number>",
      params: new Map([["group", "A GroupedScalar with elements of type <any, Number/Event>"]])
    }, {
      signature: "MIN(group: GroupedVector<any,Number/Event>): GroupedScalar<any,Number>",
      params: new Map([["group", "A GroupedVector with elements of type <any, Number/Event>"]])
    }],
    description: "Extracts the minimum on a set of numbers or event values",
    example: "MIN($PowerConsumption) > 10"
  };
  static readonly MAX_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "MAX(vector: Vector<Number/Event>): Scalar<Number>",
      params: new Map([["vector", "A vector with elements of type <Number/Event>"]])
    }, {
      signature: "MAX(group: GroupedScalar<any,Number/Event>): Scalar<Number>",
      params: new Map([["group", "A GroupedScalar with elements of type <any, Number/Event>"]])
    }, {
      signature: "MAX(group: GroupedVector<any,Number/Event>): GroupedScalar<any,Number>",
      params: new Map([["group", "A GroupedVector with elements of type <any, Number/Event>"]])
    }],
    description: "Extracts the maximum on a set of numbers or event values",
    example: "MAX($PowerConsumption) > 10"
  };
  static readonly REVERSE_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "REVERSE(scalar: Scalar<any>): Scalar<any>",
      params: new Map([["scalar", "A scalar of type <any>"]])
    }, {
      signature: "REVERSE(vector: Vector<any>): Vector<any>",
      params: new Map([["vector", "A vector of type <any>"]])
    }, {
      signature: "REVERSE(group: GroupedScalar<any, any>): GroupedScalar<any,any>",
      params: new Map([["group", "A GroupedScalar with elements of type <any, any>"]])
    }, {
      signature: "REVERSE(group: GroupedVector<any, any>): GroupedVector<any, any>",
      params: new Map([["group", "A GroupedVector with elements of type <any, any>"]])
    }],
    description: "Reverses the entries. So if applying on ascending ordered data, you would get a descending " +
      "ordered data set.",
    example: "REVERSE($PowerConsumption)"
  };
  static readonly DISTINCT_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "DISTINCT(scalar: Scalar<any>): Scalar<any>",
      params: new Map([["scalar", "A scalar of type <any>"]])
    }, {
      signature: "DISTINCT(vector: Vector<any>): Vector<any>",
      params: new Map([["vector", "A vector of type <any>"]])
    }, {
      signature: "DISTINCT(group: GroupedScalar<any, any>): GroupedScalar<any,any>",
      params: new Map([["group", "A GroupedScalar with elements of type <any, any>"]])
    }, {
      signature: "DISTINCT(group: GroupedVector<any, any>): GroupedVector<any, any>",
      params: new Map([["group", "A GroupedVector with elements of type <any, any>"]])
    }],
    description: "Returns entries without any duplicates.",
    example: "DISTINCT($PowerConsumption)"
  };
  static readonly GROUP_BY_DEVICE_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "GROUP_BY_DEVICE(vector: Vector<Event>): GroupedVector<Device, Event>",
      params: new Map([["vector", "A vector of type <Event>"]])
    }],
    description: "Converts vector to a grouped vector by grouping by devices. I.e. each device has its " +
      "own list of events.",
    example: "GROUP_BY_DEVICE($PowerConsumption)"
  };
  static readonly GROUP_BY_TIME_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "GROUP_BY_TIME(vector: Vector<Event>, timespan: Scalar<Timespan>): GroupedVector<Time, Event>",
      params: new Map([
        ["vector", "A vector of type <Event>"],
        ["timespan", "A timespan literal as a combination of number and a time unit."],
      ])
    }, {
      signature: "GROUP_BY_TIME(vector: Vector<Event>, timespan: Scalar<Timespan>, time: Scalar<Time> ): GroupedVector<Time, Event>",
      params: new Map([
        ["vector", "A vector of type <Event>"],
        ["timespan", "A timespan literal as a combination of number and a time unit."],
        ["time", "A time which consists of date and day time"]
      ])
    }],
    description: "Converts vector to a grouped vector, by gouping by timespans, so that we have a list of " +
      "events per timespan. The timespan either is fixed on the latest event, or can be " +
      "fixed on another time if specified.",
    example: "GROUP_BY_TIME($PowerConsumption, 1h)"
  };
  static readonly SORT_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "SORT(scalar: Scalar<any>): Scalar<any>",
      params: new Map([
        ["scalar", "A Scalar of type <any>"]
      ])
    }, {
      signature: "SORT(vector: Vector<any>): Vector<any>",
      params: new Map([
        ["vector", "A vector of type <any>"]
      ])
    }, {
      signature: "SORT(group: GroupedScalar<any, any>): GroupedScalar<any, any>",
      params: new Map([
        ["group", "A GroupedScalar of type <any, any>"]
      ])
    }, {
      signature: "SORT(group: GroupedScalar<any, any>, fieldToSortBy: Scalar<Text>): GroupedScalar<any, any>",
      params: new Map([
        ["group", "A GroupedScalar of type <any, any>"],
        ["fieldToSortBy", "The field by which should be sorted. Either 'byGroupKey' or 'byGroupValue'"]
      ])
    }, {
      signature: "SORT(group: GroupedVector<any, any>): GroupedVector<any, any>",
      params: new Map([
        ["group", "A GroupedVector of type <any, any>"]
      ])
    }, {
      signature: "SORT(group: GroupedVector<any, any>, fieldToSortBy: Scalar<Text>): GroupedVector<any, any>",
      params: new Map([
        ["group", "A GroupedVector of type <any, any>"],
        ["fieldToSortBy", "The field by which should be sorted. Either 'byGroupKey' or 'byGroupValue'"]
      ])
    }],
    description: "Sorts the entries in ascending order.",
    example: "SORT($PowerConsumption)"
  };
  static readonly SUBSET_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "SUBSET(vector: Vector<any>, start: Scalar<Number>): Scalar<Number>",
      params: new Map([
        ["vector", "A vector of type <any>"],
        ["start", "A positive or negative number stating which element should be extracted. Negative number starts " +
        "from the end of the vector and a positive number starts from the beginning of the vector."]
      ])
    }, {
      signature: "SUBSET(vector: Vector<any>, start: Scalar<Number>, count: Scalar Number): Vector<any>",
      params: new Map([
        ["vector", "A vector of type <any>"],
        ["start", "A positive or negative number stating which element should the starting index. Negative number starts " +
        "from the end of the vector and a positive number starts from the beginning of the vector."],
        ["count", "Number of elements that should be extracted in a subset"]
      ])
    }, {
      signature: "SUBSET(vector: Vector<Event>, start: Scalar<Time>): Vector<Event>",
      params: new Map([
        ["vector", "A vector of type <Event>"],
        ["start", "Extract entries from time x until the latest event (left argument is excluding, right is including)"]
      ])
    }, {
      signature: "SUBSET(vector: Vector<Event>, end: Scalar<Time>, y: Scalar<Time>): Vector<Event>",
      params: new Map([
        ["vector", "A vector of type <Event>"],
        ["start", "Extract entries from time x until time y (left argument is excluding, right is including)"],
        ["end", "Extract entries from time x until time y (left argument is excluding, right is including)"]
      ])
    }],
    description: "Extracts a vector subset.",
    example: "SUBSET($Produced, now-2d, now-1d)"
  };
  static readonly FILTER_DOCUMENTATION_HELP: ITermFunctionDocumentationHelp = {
    signatures: [{
      signature: "FILTER(vector: Vector<any>, comparisonSymbol: Scalar<Text>, threshold: Scalar<any>): Vector<Device/Event>",
      params: new Map([
        ["vector", "A vector of type Number or Event, which is compared to a threshold"],
        ["comparisonSymbol", "The comparison symbol. One of: <, <=, =, !=, >=, >"],
        ["threshold", "A threshold which is a scalar of Number or Event"]
      ])
    }, {
      signature: "FILTER(groupedScalar: GroupedScalar<any, any>, comparisonSymbol: Scalar<Text>, threshold: Scalar<any>): GroupedScalar<any, any>",
      params: new Map([
        ["groupedScalar", "A grouped scalar of type Number or Event, which is compared to a threshold"],
        ["comparisonSymbol", "The comparison symbol. One of: <, <=, =, !=, >=, >"],
        ["threshold", "A threshold which is a scalar of Number or Event"]
      ])
    }, {
      signature: "FILTER(groupedVector: GroupedVector<any, any>, comparisonSymbol: Scalar<Text>, threshold: Scalar<any>): GroupedVector<any, Device/Event>",
      params: new Map([
        ["groupedVector", "A grouped vector of type Number or Event, which is compared to a threshold"],
        ["comparisonSymbol", "The comparison symbol. One of: <, <=, =, !=, >=, >"],
        ["threshold", "A threshold which is a scalar of Number or Event"]
      ])
    }],
    description: "Filter data, by comparing with a threshold.",
    example: "FILTER($PowerConsumption, '>', 100)"
  };

  //Register Functions
  static readonly AVG: TermFunction = TermFunction.register("AVG", TermFunction.AVG_DOCUMENTATION_HELP);
  static readonly SUM: TermFunction = TermFunction.register("SUM", TermFunction.SUM_DOCUMENTATION_HELP);
  static readonly COUNT: TermFunction = TermFunction.register("COUNT", TermFunction.COUNT_DOCUMENTATION_HELP);
  static readonly MIN: TermFunction = TermFunction.register("MIN", TermFunction.MIN_DOCUMENTATION_HELP);
  static readonly MAX: TermFunction = TermFunction.register("MAX", TermFunction.MAX_DOCUMENTATION_HELP);
  static readonly SORT: TermFunction = TermFunction.register("SORT", TermFunction.SORT_DOCUMENTATION_HELP);
  static readonly REVERSE: TermFunction = TermFunction.register("REVERSE", TermFunction.REVERSE_DOCUMENTATION_HELP);
  static readonly DISTINCT: TermFunction = TermFunction.register("DISTINCT", TermFunction.DISTINCT_DOCUMENTATION_HELP);
  static readonly SUBSET: TermFunction = TermFunction.register("SUBSET", TermFunction.SUBSET_DOCUMENTATION_HELP);
  static readonly FILTER: TermFunction = TermFunction.register("FILTER", TermFunction.FILTER_DOCUMENTATION_HELP);
  static readonly GROUP_BY_DEVICE: TermFunction = TermFunction.register("GROUP_BY_DEVICE",
    TermFunction.GROUP_BY_DEVICE_DOCUMENTATION_HELP);
  static readonly GROUP_BY_TIME: TermFunction = TermFunction.register("GROUP_BY_TIME",
    TermFunction.GROUP_BY_TIME_DOCUMENTATION_HELP);
  static readonly DAILY_ENERGY_REPORT: TermFunction = TermFunction.register("DAILY_ENERGY_REPORT");

  private constructor(public name: string, public documentationHelp: ITermFunctionDocumentationHelp) {
    this.name = name;
    this.documentationHelp = documentationHelp;
  }

  static register(name: string, documentationHelp?: ITermFunctionDocumentationHelp): TermFunction {
    const fct: TermFunction = new TermFunction(name, documentationHelp);
    TermFunction.FUNCTIONS.push(fct);
    return fct;
  }

  static exists(name: string): boolean {
    const foundAny: TermFunction = TermFunction.FUNCTIONS.find((fct: TermFunction): boolean => {
      return fct.name === name;
    });
    return foundAny != null;
  }

  static getNames(): string[] {
    return TermFunction.FUNCTIONS.map((fct: TermFunction) => fct.name);
  }
}
