import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {IPushNotification, NotificationsFilter} from '../notifications.models';
import {NotificationsDatasource} from './notifications.datasource';
import {NotificationsService} from '../notifications.service';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-moost-notifications-table',
    templateUrl: './moost-notifications-table.component.html',
    styleUrls: ['./moost-notifications-table.component.scss'],
    standalone: false
})
export class MoostNotificationsTableComponent implements OnInit, OnChanges, AfterViewInit {
  protected notificationsService = inject(NotificationsService);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() filter: NotificationsFilter;
  allColumns: string[] = ['Timestamp', 'Building', 'Rule', 'Title', 'Message', 'Status', 'Reason', 'Interaction'];
  visibleColumns: string[] = ['Timestamp', 'Building', 'Title', 'Message', 'Status'];
  dataSource: NotificationsDatasource;
  notificationsCount: number = 0;
  notifications: IPushNotification[];
  columnSelectionForm: FormGroup = new FormGroup({});

  constructor() {
    this.columnSelectionForm = new FormGroup({
      columnSelectionField: new FormControl<string[]>(this.visibleColumns),
    });
  }

  ngOnInit(): void {
    this.dataSource = new NotificationsDatasource(this.notificationsService);
    this.dataSource.length$.subscribe((notificationsCount: number) => {
      this.notificationsCount = notificationsCount;
    });
    this.dataSource.notifications$.subscribe((notifications: IPushNotification[]) => {
      this.notifications = notifications;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter || changes.paginator) {
      if (this.paginator && this.filter != null) {
        this.paginator.firstPage();
      }
      this.loadNotifications();
    }
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadNotifications())
      )
      .subscribe();
  }

  loadNotifications(): void {
    if (this.dataSource) {
      this.dataSource.clear();
      this.dataSource.loadNotifications(
        this.filter,
        this.sort?.active,
        this.sort?.direction,
        this.paginator?.pageIndex,
        this.paginator?.pageSize);
    }
  }

  setVisibleColumns(columns: string[]): void {
    this.visibleColumns = columns;
  }
}
