// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'dev',
  apiUrl: 'https://api.dev.moost.io',
  domain: 'admin.dev.moost.io',
  hotjarId: "4939552",
  gtmId: "G-RS78ZS9G9Q",
  auth0: {
    domain: 'auth.dev.moost.io',
    clientId: 'hNq8F72UlK0KyZLNSPgIydmVlwPVXx0f',
    cacheLocationType: 'localstorage',
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    authorizationParams: {
      audience: 'https://dev-moost-api',
      redirect_uri: 'https://admin.dev.moost.io/callback',
      scope: "openid profile email offline_access"
    },
  },
  apm: {
    serverUrl: 'https://rp-aws-elk-1d6b9f.apm.eu-central-1.aws.cloud.es.io',
    distributedTracingOrigins: ['https://admin.dev.moost.io/', 'https://api.dev.moost.io'],
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
