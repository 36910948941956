import { Injectable, inject } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {EventsFilter, EventTypeSourceFilter, IEvent} from './events.models';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private httpClient = inject(HttpClient);

  private static readonly EVENTS_URL: string = `${environment.apiUrl}/events`;

  getEventsForCustomerBuilding(customerBuildingId: string, startTimestamp: number, endTimestamp: number,
                               eventTypeFilter: EventTypeSourceFilter[] = []): Observable<IEvent[]> {
    if (!Number.isNaN(startTimestamp) && !Number.isNaN(endTimestamp) && customerBuildingId !== null) {
      let params: HttpParams = new HttpParams()
        .set('from', startTimestamp.toString())
        .set('to', endTimestamp.toString())
        .set('eventTypeFilter', JSON.stringify(eventTypeFilter));

      return this.httpClient.get<IEvent[]>(`${EventsService.EVENTS_URL}/${customerBuildingId}/v1`, {
        params
      });
    } else {
      return EMPTY;
    }
  }

  getEventTypes(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${EventsService.EVENTS_URL}/types/v1`, {});
  }

  getSourceTypes(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${EventsService.EVENTS_URL}/sources/v1`, {});
  }

  getEventsCount(filter: EventsFilter): Observable<number> {
    return this.httpClient.get<number>(`${EventsService.EVENTS_URL}/filter/count/v1`, {
      params: this.buildParams(filter)
    });
  }

  getEventsPage(filter: EventsFilter,
                sortField: string = 'timestamp',
                sortOrder: string = 'desc',
                pageNumber: number = 1,
                pageSize: number = 100): Observable<IEvent[]> {
    return this.httpClient.get<IEvent[]>(`${EventsService.EVENTS_URL}/filter/v1`, {
      params: this.buildParams(filter)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('sortField', sortField)
        .set('sortDirection', sortOrder)
    });
  }

  getCountByGroup(filter: EventsFilter, groupName: string): Observable<CountByGroup[]> {
    return this.httpClient.get<CountByGroup[]>(`${EventsService.EVENTS_URL}/filter/count/${groupName}/v1`, {
      params: this.buildParams(filter)
    });
  }

  private buildParams(filter: EventsFilter): HttpParams {
    let params: HttpParams = new HttpParams()
      .set('startTimestamp', Math.trunc(filter.startTimestampMillis / 1000))
      .set('endTimestamp', Math.trunc(filter.endTimestampMillis / 1000));
    if (filter.customerBuildingIds) {
      params = params.set('customerBuildingId', filter.customerBuildingIds.join(','));
    }
    if (filter.sources) {
      params = params.set('source', filter.sources.join(','));
    }
    if (filter.types) {
      params = params.set('type', filter.types.join(','));
    }
    if (filter.deviceIds) {
      params = params.set('deviceId', filter.deviceIds.join(','));
    }
    if (filter.deviceNames) {
      params = params.set('deviceName', filter.deviceNames.join(','));
    }
    return params;
  }
}

export class CountByGroup {
  name: string;
  count: number;
}
