import {TermType} from './term-type';

export class TermLiteral {

  static readonly LITERALS: TermLiteral[] = [
    new TermLiteral('StreakCounter', TermType.NUMBER),
    new TermLiteral('Building', TermType.BUILDING),
    new TermLiteral('true', TermType.BOOLEAN),
    new TermLiteral('false', TermType.BOOLEAN),
    new TermLiteral('now', TermType.TIME),
    new TermLiteral('lastHour', TermType.TIME),
    new TermLiteral('lastZeroHour', TermType.TIME),
    new TermLiteral('startOfLastMonth', TermType.TIME),
    new TermLiteral('endOfLastMonth', TermType.TIME),
    new TermLiteral('startOfCurrentMonth', TermType.TIME),
    new TermLiteral('endOfCurrentMonth', TermType.TIME),
  ];

  private constructor(public name: string, public termType: TermType) {
  }

  static byName(name: string): TermLiteral {
    return this.LITERALS.find((literal: TermLiteral) => literal.name === name);
  }
}

