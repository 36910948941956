import {Component, OnInit} from '@angular/core';
import {FulfillmentPreference} from '../fulfillment.model';
import {SettingsDatasource} from '../datasource/settings.datasource';
import {SettingsService} from '../settings.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {
  ConfirmDialogModel,
  MoostConfirmDialogComponent
} from '../../shared-module/moost-confirm-dialog/moost-confirm-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {take} from 'rxjs/operators';
import {Permission} from '../../auth-token-module/auth-token.models';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';

@Component({
  selector: 'app-moost-settings-card',
  templateUrl: './moost-settings-card.component.html',
  styleUrls: ['./moost-settings-card.component.scss']
})

export class MoostSettingsCardComponent implements OnInit {
  protected readonly FulfillmentPreference = FulfillmentPreference;

  dataSource: SettingsDatasource;
  fulfillmentPreferences: FulfillmentPreference[];
  updateForm: boolean = false;
  idToUpdate: string;
  result: boolean;
  columnWidth: number;
  protected readonly Permission = Permission;

  constructor(private settingsService: SettingsService, protected authTokenService: AuthTokenService,
              private clipboard: Clipboard, private _snackBar: MatSnackBar, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.dataSource = new SettingsDatasource(this.settingsService);

    this.dataSource.fulfillments$.subscribe(data => {
      this.fulfillmentPreferences = data;
      if (this.fulfillmentPreferences.length < 3) {
        this.columnWidth = 49
      } else {
        this.columnWidth = 32;
      }
    });

    this.loadFulfillmentPreferences();
  }

  loadFulfillmentPreferences(): void {
    if (this.dataSource) {
      this.dataSource.clear();
      this.dataSource.loadFulfillmentPreferences();
    }
  }

  showUpdateForm(id: string): void {
    this.idToUpdate = id;
    this.updateForm = true;
  }

  hideUpdateForm(): void {
    this.updateForm = false;
    this.loadFulfillmentPreferences();
  }

  copyToClipboard(value: string): void {
    const snackBarMessage: string = 'Copied to the clipboard.';
    const snackBarAction: string = '';
    const snackBarConfig: MatSnackBarConfig = {
      duration: 5000
    };

    this.clipboard.copy(value);
    this._snackBar.open(snackBarMessage, snackBarAction, snackBarConfig)
  }

  public confirm(id: string): void {
    const dialogConfig: MatDialogConfig<ConfirmDialogModel> = new MatDialogConfig();
    dialogConfig.data = new ConfirmDialogModel('Delete preference',
      'Should the preference with the id: ' + id + ' really be deleted?',
      'DELETE', 'CANCEL', 'delete'
    );

    const dialogRef: MatDialogRef<MoostConfirmDialogComponent> = this.dialog.open(MoostConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if (this.result) {
        this.settingsService.deleteFulfillmentPreference(id)
          .pipe(take(1))
          .subscribe((): void => {
            this.loadFulfillmentPreferences();
            this._snackBar.open('The preference was successfully deleted.', '', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          });
      }
    })
  }
}
