import {Component, Input} from '@angular/core';
import {DatasetsService} from '../datasets.service';

@Component({
  selector: 'app-moost-rules-error',
  templateUrl: './moost-rules-error.component.html',
  styleUrls: ['./moost-rules-error.component.scss'],
  providers: [DatasetsService]
})
export class MoostRulesErrorComponent {
  @Input()
  errorCode: string;
}
