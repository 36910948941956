import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoutButtonComponent} from './logout/logout-button.component';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from '@angular/material/menu';
import {AuthTokenRoutingModule} from './auth-token-routing.module';
import {LoginComponent} from './login/login.component';


@NgModule({
  declarations: [
    LogoutButtonComponent,
    LoginComponent
  ],
  exports: [
    LogoutButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    AuthTokenRoutingModule
  ]
})
export class AuthTokenModule {
}
