import { Component, inject } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {Permission} from '../../auth-token-module/auth-token.models';

@Component({
    selector: 'app-moost-settings-overview',
    templateUrl: './moost-settings-overview.component.html',
    styleUrls: ['./moost-settings-overview.component.scss'],
    standalone: false
})
export class MoostSettingsOverviewComponent {
  private headerService = inject(MoostHeaderService);
  protected authTokenService = inject(AuthTokenService);
  private router = inject(Router);
  dialog = inject(MatDialog);


  protected readonly Permission = Permission;

  constructor() {
    this.headerService.setTitle('Settings overview');
  }

  add(): void {
    this.router.navigate(['settings/add']);
  }
}
