<div class="chart">
  <h3>{{ title }}</h3>
  <div (chartInit)="initCharts($event)"
       *ngIf="!hasError"
       [loadingOpts]="loadingOptions"
       [loading]="isLoading"
       [options]="options"
       echarts
       style="height: 380px;"></div>

  <div *ngIf="hasError" class="error">
    <div>
      <mat-icon aria-hidden="false" aria-label="Icon showing a cross which indicates an error"
                color="warn" fontIcon="error">
      </mat-icon>
      <br/>
      Error loading chart
    </div>
  </div>
</div>
