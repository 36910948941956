import {Component} from '@angular/core';
import {AuthTokenService} from '../auth-token.service';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html'
})
export class LogoutButtonComponent {
  constructor(private authTokenService: AuthTokenService) {
  }

  handleLogout(): void {
    this.authTokenService.logout();
  }
}
