import {inject, Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../notifications-module/notifications.service';
import {NotificationLanguage} from '../notifications-module/notifications.models';

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnDestroy {
  private notificationsService: NotificationsService = inject(NotificationsService);

  availableNotificationLanguages: NotificationLanguage[];
  private notificationsSubscription: Subscription;

  constructor() {
    this.notificationsSubscription = this.notificationsService.getAvailableLanguages().subscribe({
      next: (notificationLanguages: NotificationLanguage[]): void => {
        this.availableNotificationLanguages = notificationLanguages;
      },
      error: (error): void => {
        console.error(error.message);
      }
    });
  }

  getLanguageName(code: string): string {
    return this.availableNotificationLanguages.find((it: NotificationLanguage): boolean => it.code === code).name;
  }

  getNotificationLanguages(codes: string[]): NotificationLanguage[] {
    return codes?.map((code: string): NotificationLanguage => this.getNotificationLanguage(code));
  }

  getNotificationLanguage(code: string): NotificationLanguage {
    return this.availableNotificationLanguages.find((it: NotificationLanguage): boolean => it.code === code);
  }

  ngOnDestroy(): void {
    this.notificationsSubscription?.unsubscribe();
  }
}
