<div>
  <div class="filter">
    <app-moost-events-filter
      (applyFilterChange)="applyFilter($event)"
      [filter]="initFilter"
    ></app-moost-events-filter>
  </div>

  <div class="page-content">
    <h2 class="title">Dashboard</h2>
    <div class="chart-container">
      <app-moost-events-group-by-chart
        [filter]="filter"
        class="chart-item"
        groupBy="source"
        maxGroupsWithoutOthers="10"
        title="Top 10 Event Sources">
      </app-moost-events-group-by-chart>

      <app-moost-events-group-by-chart
        [filter]="filter"
        class="chart-item"
        groupBy="customerBuildingId"
        maxGroupsWithoutOthers="10"
        title="Top 10 Buildings">
      </app-moost-events-group-by-chart>

      <app-moost-events-group-by-chart
        [filter]="filter"
        class="chart-item"
        groupBy="type"
        maxGroupsWithoutOthers="10"
        title="Top 10 Event Types">
      </app-moost-events-group-by-chart>
    </div>

    <h2 class="title" style="margin-top:24px;">Data</h2>
    <app-moost-events-table
      [filter]="filter"
    >
    </app-moost-events-table>
  </div>
</div>
