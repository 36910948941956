<app-moost-rules-error *ngIf="!isLoadingRule && errorCode" [errorCode]="errorCode">
</app-moost-rules-error>

<form (ngSubmit)="onSubmit()" *ngIf="!isLoadingRule && !errorCode && ruleForm" [formGroup]="ruleForm">

  <div class="toolbar">
    <app-moost-buildings-selector [(selectedCustomerBuildingId)]="selectedCustomerBuildingId"
                                  [datasets]="this.datasets">
    </app-moost-buildings-selector>

    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="selectedDateRange" [max]="maxDate" [rangePicker]="picker">
        <input formControlName="startDate" matStartDate
               placeholder="Start date">
        <input (dateChange)="dateChanged($event)" formControlName="endDate" matEndDate
               placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <button [matMenuTriggerFor]="ruleContextMenu" mat-icon-button type="button">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #ruleContextMenu="matMenu">
      <button (click)="deleteRule()" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
      <button *ngIf="ruleVersions"
              [disabled]="ruleVersions?.length <= 1"
              [matMenuTriggerFor]="ruleHistoryMenu" mat-menu-item>
        <mat-icon>history</mat-icon>
        <span>History</span>
      </button>
      <button *ngIf="libraryRule"
              [disabled]="isEqualToRuleInForm(libraryRule)"
              (click)="openComparisonView(libraryRule)" mat-menu-item>
        <mat-icon>difference</mat-icon>
        <span>Library</span>
      </button>
      <button (click)="exportRule()" mat-menu-item>
        <mat-icon>upload</mat-icon>
        <span>Export to Clipboard</span>
      </button>
      <button (click)="openImportRuleDialog()" mat-menu-item>
        <mat-icon>download</mat-icon>
        <span>Import from Clipboard</span>
      </button>
      <button
        [disabled]="!authTokenService.hasPermission(Permission.ADMIN_PUSHNOTIFICATION) || !selectedCustomerBuildingId"
        (click)="openSendTestNotificationDialog()" mat-menu-item>
        <mat-icon>outgoing_mail</mat-icon>
        <span>Send Test Notification</span>
      </button>
    </mat-menu>

    <mat-menu #ruleHistoryMenu="matMenu">
      @for (ruleVersion of ruleVersions; track ruleVersion.id) {
        <button (click)="openComparisonView(ruleVersion.rule)" mat-menu-item [value]="ruleVersion.id"
                matTooltipPosition="right"
                [matTooltip]="(ruleVersion.timestamp * 1000 | date: 'dd.MM.yyyy HH:mm:ss') + ' by ' + ruleVersion.username">
          <div>
            {{ getRuleVersionNumber(ruleVersion) }}:
            {{ getRuleVersionAge(ruleVersion) }} ago, {{ ruleVersion.timestamp * 1000 | date: 'dd MMM HH:mm' }}
          </div>
          @if (isEqualToRuleInForm(ruleVersion.rule)) {
            <div style="color: gray; font-size: smaller;">{{ getRuleVersionNumber(ruleVersion) }} is equal to current
              rule
            </div>
          }
        </button>
      }
    </mat-menu>
  </div>

  <div class="rules-data-graph-container">
    <app-moost-rules-data-graph [customerBuildingId]="selectedCustomerBuildingId"
                                [datasets]="datasets"
                                [startTimeRangeMillis]="this.startTimestampMillis"
                                [endTimeRangeMillis]="this.endTimestampMillis"
                                [isSimulationRunning]="this.isSimulationRunning"
                                [ruleId]="rule?.id"
                                [simulatedNotifications]="simulatedNotifications"
                                class="rules-data-graph">
    </app-moost-rules-data-graph>
  </div>

  <div class="page-content">
    <div class="form-container">
      <div class="dataset-list-container">
        <app-moost-dataset-list [from]="this.startTimestampMillis"
                                [to]=this.endTimestampMillis formControlName="datasets">
        </app-moost-dataset-list>
        <mat-error *ngIf="ruleForm.controls.datasets.touched && ruleForm.controls.datasets.invalid
                   && ruleForm.controls.datasets.errors?.['required']">
          Please add at least one dataset.
        </mat-error>
      </div>

      <div class="state-toggle-row">
        <mat-slide-toggle color="primary"
                          formControlName="isActiveState">
          Active
        </mat-slide-toggle>

        <mat-slide-toggle color="primary"
                          formControlName="resetStateWhenMatched">
          Reset state when matched
        </mat-slide-toggle>

        <mat-slide-toggle color="primary"
                          formControlName="isRestrictedToEarlyAdopters">
          Restricted to Early Adopters
        </mat-slide-toggle>

        <mat-slide-toggle (change)="isTimeBasedChanged($event)"
                          color="primary"
                          formControlName="isTimeBased">
          Time-based rule
        </mat-slide-toggle>

        <mat-slide-toggle (change)="isStreakChanged($event)"
                          color="primary"
                          formControlName="isStreak">
          Streak
        </mat-slide-toggle>
      </div>


      <mat-form-field [@toggleFormVisibility]="ruleForm.controls.isTimeBased.value ? 'open' : 'closed'"
                      appearance="outline">
        <mat-label>Time-based trigger (Cron notation)</mat-label>
        <input formControlName="timeBasedCron" matInput>
        @if (ruleForm.controls.timeBasedCron.touched && ruleForm.controls.timeBasedCron.invalid) {
          <mat-error>
            {{ ruleForm.controls.timeBasedCron.errors.cronFormat }}
          </mat-error>
        }
        @if (ruleForm.controls.isTimeBased.value && ruleForm.controls.timeBasedCron.valid) {
          <mat-hint>
            Runs {{ cronstrue.toString(ruleForm.controls.timeBasedCron.value, {use24HourTimeFormat: true}).replace(RegExp('^At '), 'at ') }}
            (Central European Time)
          </mat-hint>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" class="monaco-form-field">
        <mat-label>Condition</mat-label>
        <!-- https://github.com/angular/angular/issues/14057 -->
        <!--suppress TypeScriptValidateTypes -->
        <app-rule-language-form-field
          [allowedResultStructures]="[TermStructure.SCALAR]"
          [allowedResultTypes]="[TermType.BOOLEAN]"
          (termChange)="ruleLanguageTermChanged($event)"
          [formControl]="ruleForm.get('condition')">
        </app-rule-language-form-field>
      </mat-form-field>

      <mat-form-field [@toggleFormVisibility]="ruleForm.controls.isStreak.value ? 'open' : 'closed'"
                      appearance="outline">
        <mat-label>Streak Condition</mat-label>
        <!-- https://github.com/angular/angular/issues/14057 -->
        <!--suppress TypeScriptValidateTypes -->
        <app-rule-language-form-field
          [allowedResultStructures]="[TermStructure.SCALAR]"
          [allowedResultTypes]="[TermType.BOOLEAN]"
          (termChange)="ruleLanguageTermChanged($event)"
          [formControl]="ruleForm.get('streakCondition')"
        >
        </app-rule-language-form-field>
      </mat-form-field>

      <input type="hidden" formControlName="templateRuleId">

      <div class="form-row-container">
        <mat-form-field appearance="outline" class="grow-fast">
          <mat-label>Name</mat-label>
          <input formControlName="name" matInput>
          <mat-error *ngIf="ruleForm.controls.name.touched && ruleForm.controls.name.invalid">
            Invalid name.
            <span *ngIf="ruleForm.controls?.name.errors?.forbiddenName">A rule with this name exists already.</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="icon-choice">
          <mat-label>Topic</mat-label>
          <mat-select formControlName="topicIcon">
            <mat-select-trigger>
              <mat-icon [matTooltip]="RuleIcon.getTopicIcon(ruleForm.controls.topicIcon.value)?.name"
                        class="icon-image-selected topic-color" matTooltipPosition="left">
                {{ RuleIcon.getTopicIcon(ruleForm.controls.topicIcon.value)?.materialIcon }}
              </mat-icon>
            </mat-select-trigger>
            @for (icon of RuleIcon.TOPICS; track icon.id) {
              <div>
                <mat-option [matTooltip]="icon.name" matTooltipPosition="left" [value]="icon.id">
                  <mat-icon class="icon-image-choice topic-color">{{ icon.materialIcon }}</mat-icon>
                </mat-option>
              </div>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="icon-choice">
          <mat-label>Impact</mat-label>
          <mat-select formControlName="impactIcon">
            <mat-select-trigger>
              <mat-icon [matTooltip]="RuleIcon.getImpactIcon(ruleForm.controls.impactIcon.value)?.name"
                        class="icon-image-selected impact-color" matTooltipPosition="left">
                {{ RuleIcon.getImpactIcon(ruleForm.controls.impactIcon.value)?.materialIcon }}
              </mat-icon>
            </mat-select-trigger>
            @for (icon of RuleIcon.IMPACTS; track icon.id) {
              <mat-option [matTooltip]="icon.name" matTooltipPosition="left" [value]="icon.id">
                <mat-icon class="icon-image-choice impact-color">{{ icon.materialIcon }}</mat-icon>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea cdkTextareaAutosize="true" formControlName="description" matInput
                  placeholder="Description"></textarea>
        <mat-error *ngIf="ruleForm.controls.description.touched && ruleForm.controls.description.invalid">
          Invalid description
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Match Threshold</mat-label>
        <input formControlName="match_threshold" matInput min="0" placeholder="0" type="number">
        <mat-error
          *ngIf="ruleForm.controls.match_threshold.touched && ruleForm.controls.match_threshold.invalid">
          Invalid value
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Time between Notifications</mat-label>
        <input formControlName="time_between_triggers_seconds" matInput
               placeholder="(Valid values are e.g. 1d, 15h, 50min or 0.)">
        <mat-error
          *ngIf="ruleForm.controls.time_between_triggers_seconds.touched && ruleForm.controls.time_between_triggers_seconds.invalid">
          The value has an invalid format. (Valid values are e.g. 1d, 15h, 50min, 0 etc.)
        </mat-error>
      </mat-form-field>

      <div formGroupName="notification">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Primary action</mat-label>
          <mat-select [formControlName]="'actionQualifier_primary'">
            <mat-option *ngFor="let actionQualifier of actionSelection" [value]="actionQualifier.id">
              {{ actionQualifier.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Secondary action</mat-label>
          <mat-select [formControlName]="'actionQualifier_secondary'">
            <mat-option *ngFor="let actionQualifier of actionSelection" [value]="actionQualifier.id">
              {{ actionQualifier.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-tab-group (selectedTabChange)="refreshView()" mat-align-tabs="start" mat-stretch-tabs="false">
          <mat-tab *ngFor="let language of notificationLanguages">
            <ng-template mat-tab-label>
              {{ languageService.getLanguageName(language) | uppercase }}
              @if (errorBadgeLangTab.has(language)) {
                <div matBadge="!" matBadgeSize="small" class="error-badge"></div>
              }
            </ng-template>
            <div class="tab-content">
              <div class="form-row-container">
                <mat-form-field appearance="outline" class="grow-fast">
                  <mat-label>Message Title</mat-label>
                  <input [formControlName]="'title_' + language" matInput placeholder="Title">
                </mat-form-field>
                <app-translate-form-button fieldNamePrefix="title"
                                           [sourceLanguage]="language"
                                           [notificationLanguages]="notificationLanguages"
                                           [ruleForm]="ruleForm"
                ></app-translate-form-button>
              </div>

              <div class="form-row-container">
                <mat-form-field appearance="outline" class="monaco-form-field grow-fast">
                  <mat-label>Message Text</mat-label>
                  <!-- https://github.com/angular/angular/issues/14057 -->
                  <!--suppress TypeScriptValidateTypes -->
                  <app-rule-language-form-field
                    [allowedResultStructures]="[TermStructure.SCALAR]"
                    [allowedResultTypes]="[TermType.TEXT]"
                    (termChange)="ruleLanguageTermChanged($event)"
                    [formControl]="ruleForm.controls.notification.get('message_' + language)">
                  </app-rule-language-form-field>
                </mat-form-field>
                <app-translate-form-button fieldNamePrefix="message"
                                           [sourceLanguage]="language"
                                           [notificationLanguages]="notificationLanguages"
                                           [ruleForm]="ruleForm"
                ></app-translate-form-button>
              </div>

              <div class="form-row-container">
                <mat-form-field appearance="outline" class="grow-slow">
                  <mat-label>Primary action text</mat-label>
                  <input [formControlName]="'actions_primary_text_' + language" matInput/>
                </mat-form-field>
                <app-translate-form-button fieldNamePrefix="actions_primary_text"
                                           [sourceLanguage]="language"
                                           [notificationLanguages]="notificationLanguages"
                                           [ruleForm]="ruleForm"
                ></app-translate-form-button>
                @if (isActionParameterVisible('primary')) {
                  <mat-form-field appearance="outline" class="grow-fast">
                    <mat-label>Primary action parameter</mat-label>
                    <input [formControlName]="'actions_primary_param_' + language" matInput/>
                  </mat-form-field>
                  <app-translate-form-button fieldNamePrefix="actions_primary_param"
                                             [sourceLanguage]="language"
                                             [notificationLanguages]="notificationLanguages"
                                             [ruleForm]="ruleForm"
                  ></app-translate-form-button>
                }
              </div>

              <div class="form-row-container">
                <mat-form-field appearance="outline" class="grow-slow">
                  <mat-label>Secondary action text</mat-label>
                  <input [formControlName]="'actions_secondary_text_' + language" matInput/>
                </mat-form-field>
                <app-translate-form-button fieldNamePrefix="actions_secondary_text"
                                           [sourceLanguage]="language"
                                           [notificationLanguages]="notificationLanguages"
                                           [ruleForm]="ruleForm"
                ></app-translate-form-button>
                @if (isActionParameterVisible('secondary')) {
                  <mat-form-field appearance="outline" class="grow-fast">
                    <mat-label>Secondary action parameter</mat-label>
                    <input [formControlName]="'actions_secondary_param_' + language" matInput/>
                  </mat-form-field>
                  <app-translate-form-button fieldNamePrefix="actions_secondary_param"
                                             [sourceLanguage]="language"
                                             [notificationLanguages]="notificationLanguages"
                                             [ruleForm]="ruleForm"
                  ></app-translate-form-button>
                }
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <div class="form-row-container">
          <mat-form-field appearance="outline" class="monaco-form-field grow-fast">
            <mat-label>Command</mat-label>
            <!-- https://github.com/angular/angular/issues/14057 -->
            <!--suppress TypeScriptValidateTypes -->
            <app-rule-language-form-field
              [allowedResultStructures]="[TermStructure.SCALAR]"
              [allowedResultTypes]="[TermType.TEXT]"
              (termChange)="ruleLanguageTermChanged($event)"
              [formControl]="ruleForm.controls.notification.get('command')">
            </app-rule-language-form-field>
          </mat-form-field>
        </div>
      </div>
    </div>

    @if (comparingRule) {
      <mat-divider [vertical]="true" class="comparison-divider"></mat-divider>
      <div class="form-container">
        <div class="form-row-container">
          @if (RuleComparator.haveSameDatasetsSettings(ruleForm.controls.datasets.value, comparingRule.datasets)) {
            <button [disabled]="true" class="comparison-button" mat-flat-button>=</button>
          } @else {
            <button
              (click)="ruleForm.controls.datasets.setValue(comparingRule.datasets); datasetsService.datasetsSource.next(comparingRule.datasets)"
              class="comparison-button"
              color="accent"
              mat-flat-button>
              <
            </button>
          }
          <div class="dataset-list-container">
            <div class="dataset-list">
              <mat-chip-listbox>
                <app-moost-dataset-chip *ngFor="let dataset of comparingRule.datasets"
                                        [color]="this.datasetsService.getHexColorStringForDataset(dataset, comparingRule.datasets)"
                                        [dataset]="dataset">
                </app-moost-dataset-chip>
              </mat-chip-listbox>
            </div>
          </div>
        </div>

        <div class="form-row-container">
          @if (ruleForm.controls.isActiveState.value === (comparingRule.ruleState === Rule.ACTIVE) &&
          ruleForm.controls.resetStateWhenMatched.value === comparingRule.resetStateWhenMatched &&
          ruleForm.controls.isRestrictedToEarlyAdopters.value === comparingRule.isRestrictedToEarlyAdopters &&
          ruleForm.controls.isTimeBased.value === comparingRule.isTimeBased) {
            <button [disabled]="true" class="comparison-button" mat-flat-button>=</button>
          } @else {
            <button (click)="
                             ruleForm.controls.isActiveState.setValue(comparingRule.ruleState === Rule.ACTIVE);
                             ruleForm.controls.resetStateWhenMatched.setValue(comparingRule.resetStateWhenMatched);
                             ruleForm.controls.isRestrictedToEarlyAdopters.setValue(comparingRule.isRestrictedToEarlyAdopters);
                             ruleForm.controls.isTimeBased.setValue(comparingRule.isTimeBased);
                            "
                    class="comparison-button"
                    color="accent"
                    mat-flat-button>
              <
            </button>
          }
          <div class="state-toggle-row">
            <mat-slide-toggle color="primary" disabled="true" [checked]="comparingRule.ruleState === Rule.ACTIVE">
              Active
            </mat-slide-toggle>

            <mat-slide-toggle color="primary" disabled="true" [checked]="comparingRule.resetStateWhenMatched">
              Reset state when matched
            </mat-slide-toggle>

            <mat-slide-toggle color="primary" disabled="true" [checked]="comparingRule.isRestrictedToEarlyAdopters">
              Restricted to Early Adopters
            </mat-slide-toggle>

            <mat-slide-toggle color="primary" disabled="true" [checked]="comparingRule.isTimeBased">
              Time-based rule
            </mat-slide-toggle>

            <mat-slide-toggle color="primary" disabled="true" [checked]="!!comparingRule.streakCondition">
              Streak
            </mat-slide-toggle>
          </div>
        </div>

        @if (comparingRule.isTimeBased) {
          <div class="form-row-container">
            <app-sync-form-field [target]="ruleForm.controls.timeBasedCron" [source]="comparingRule.timeBasedCron"/>
            <mat-form-field appearance="outline" class="grow-fast">
              <mat-label>Time-based trigger (Cron notation)</mat-label>
              <input readonly="true" [value]="comparingRule.timeBasedCron" matInput>
              <mat-hint>
                Runs {{ cronstrue.toString(comparingRule.timeBasedCron, {use24HourTimeFormat: true}).replace(RegExp('^At '), 'at ') }}
                (Central European Time)
              </mat-hint>
            </mat-form-field>
          </div>
        }

        <div class="form-row-container">
          <app-sync-form-field [target]="ruleForm.controls.condition" [source]="comparingRule.condition"/>
          <mat-form-field appearance="outline" class="grow-fast">
            <mat-label>Condition</mat-label>
            <textarea cdkTextareaAutosize="true" readonly="true" [value]="comparingRule.condition" matInput></textarea>
          </mat-form-field>
        </div>

        <div class="form-row-container" *ngIf="!!comparingRule.streakCondition">
          <app-sync-form-field [target]="ruleForm.controls.streakCondition" [source]="comparingRule.streakCondition"/>
          <mat-form-field appearance="outline" class="grow-fast">
            <mat-label>Streak Condition</mat-label>
            <textarea cdkTextareaAutosize="true" readonly="true" [value]="comparingRule.streakCondition"
                      matInput></textarea>
          </mat-form-field>
        </div>

        <div class="form-row-container">
          <app-sync-form-field [target]="ruleForm.controls.name" [source]="comparingRule.name"/>
          <mat-form-field appearance="outline" class="grow-fast">
            <mat-label>Name</mat-label>
            <input readonly="true" [value]="comparingRule.name" matInput>
          </mat-form-field>

          <mat-form-field appearance="outline" class="icon-choice">
            <mat-label>Topic</mat-label>
            <mat-select value="selectedItem">
              <mat-select-trigger>
                <mat-icon [matTooltip]="RuleIcon.getTopicIcon(comparingRule.topicIcon)?.name"
                          class="icon-image-selected topic-color" matTooltipPosition="left">
                  {{ RuleIcon.getTopicIcon(comparingRule.topicIcon)?.materialIcon }}
                </mat-icon>
              </mat-select-trigger>
              <mat-option value="selectedItem">
                <mat-icon [matTooltip]="RuleIcon.getTopicIcon(comparingRule.topicIcon)?.name"
                          class="icon-image-selected topic-color" matTooltipPosition="left">
                  {{ RuleIcon.getTopicIcon(comparingRule.topicIcon)?.materialIcon }}
                </mat-icon>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="icon-choice">
            <mat-label>Impact</mat-label>
            <mat-select value="selectedItem">
              <mat-select-trigger>
                <mat-icon [matTooltip]="RuleIcon.getImpactIcon(comparingRule.impactIcon)?.name"
                          class="icon-image-selected impact-color" matTooltipPosition="left">
                  {{ RuleIcon.getImpactIcon(comparingRule.impactIcon)?.materialIcon }}
                </mat-icon>
              </mat-select-trigger>
              <mat-option value="selectedItem">
                <mat-icon [matTooltip]="RuleIcon.getImpactIcon(comparingRule.impactIcon)?.name"
                          class="icon-image-selected impact-color" matTooltipPosition="left">
                  {{ RuleIcon.getImpactIcon(comparingRule.impactIcon)?.materialIcon }}
                </mat-icon>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row-container">
          <app-sync-form-field [target]="ruleForm.controls.description" [source]="comparingRule.description"/>
          <mat-form-field appearance="outline" class="grow-fast">
            <mat-label>Description</mat-label>
            <textarea cdkTextareaAutosize="true" readonly="true" [value]="comparingRule.description" matInput
                      placeholder="Description"></textarea>
          </mat-form-field>
        </div>

        <div class="form-row-container">
          <app-sync-form-field [target]="ruleForm.controls.match_threshold" [source]="comparingRule.match_threshold"/>
          <mat-form-field appearance="outline" class="grow-fast">
            <mat-label>Match Threshold</mat-label>
            <input readonly="true" [value]="comparingRule.match_threshold" matInput min="0" placeholder="0"
                   type="number">
          </mat-form-field>
        </div>

        <div class="form-row-container">
          <app-sync-form-field [target]="ruleForm.controls.time_between_triggers_seconds"
                               [source]="secondsToDurationString(comparingRule.time_between_triggers_seconds)"/>
          <mat-form-field appearance="outline" class="grow-fast">
            <mat-label>Time between Notifications</mat-label>
            <input readonly="true" [value]="secondsToDurationString(comparingRule.time_between_triggers_seconds)"
                   matInput
                   placeholder="(Valid values are e.g. 1d, 15h, 50min or 0.)">
          </mat-form-field>
        </div>

        <div>
          <div class="form-row-container">
            <app-sync-form-field
              [target]="ruleForm['controls'].notification['controls'].actionQualifier_primary"
              [source]="comparingRule.notification.actionQualifier.primary"/>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Primary action</mat-label>
              <mat-select value="selectedItem">
                <mat-option value="selectedItem">
                  {{ getActionText(comparingRule.notification.actionQualifier.primary) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row-container">
            <app-sync-form-field
              [target]="ruleForm['controls'].notification['controls'].actionQualifier_secondary"
              [source]="comparingRule.notification.actionQualifier.secondary"/>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Secondary action</mat-label>
              <mat-select value="selectedItem">
                <mat-option value="selectedItem">
                  {{ getActionText(comparingRule.notification.actionQualifier.secondary) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false">
            <mat-tab *ngFor="let language of notificationLanguages">
              <ng-template mat-tab-label>
                {{ languageService.getLanguageName(language) | uppercase }}
              </ng-template>
              <div class="tab-content">
                <div class="form-row-container">
                  <app-sync-form-field [target]="ruleForm['controls'].notification['controls']['title_' + language]"
                                       [source]="comparingRule.notification.texts[language]?.title"/>
                  <mat-form-field appearance="outline" class="grow-fast">
                    <mat-label>Message Title</mat-label>
                    <input readonly="true" [value]="comparingRule.notification.texts[language]?.title" matInput
                           placeholder="Title">
                  </mat-form-field>
                </div>

                <div class="form-row-container">
                  <app-sync-form-field
                    [target]="ruleForm['controls'].notification['controls']['message_' + language]"
                    [source]="comparingRule.notification.texts[language]?.message"/>
                  <mat-form-field appearance="outline" class="monaco-form-field grow-fast">
                    <mat-label>Message Text</mat-label>
                    <textarea cdkTextareaAutosize="true" readonly="true"
                              [value]="comparingRule.notification.texts[language]?.message" matInput>
                    </textarea>
                  </mat-form-field>
                </div>

                <div class="form-row-container">
                  <app-sync-form-field
                    [target]="ruleForm['controls'].notification['controls']['actions_primary_text_' + language]"
                    [source]="comparingRule.notification.texts[language]?.actions?.primary?.text"/>
                  <mat-form-field appearance="outline" class="grow-slow">
                    <mat-label>Primary action text</mat-label>
                    <input readonly="true"
                           [value]="comparingRule.notification.texts[language]?.actions?.primary?.text"
                           matInput/>
                  </mat-form-field>
                  <mat-form-field *ngIf="isActionParameterVisible('primary')" appearance="outline" class="grow-fast">
                    <mat-label>Primary action parameter</mat-label>
                    <input readonly="true"
                           [value]="comparingRule.notification.texts[language]?.actions?.primary?.parameter"
                           matInput/>
                  </mat-form-field>
                </div>

                <div class="form-row-container">
                  <app-sync-form-field
                    [target]="ruleForm['controls'].notification['controls']['actions_secondary_text_' + language]"
                    [source]="comparingRule.notification.texts[language]?.actions?.secondary?.text"/>
                  <mat-form-field appearance="outline" class="grow-slow">
                    <mat-label>Secondary action text</mat-label>
                    <input readonly="true"
                           [value]="comparingRule.notification.texts[language]?.actions?.secondary?.text"
                           matInput/>
                  </mat-form-field>
                  <mat-form-field *ngIf="isActionParameterVisible('secondary')" appearance="outline" class="grow-fast">
                    <mat-label>Secondary action parameter</mat-label>
                    <input readonly="true"
                           [value]="comparingRule.notification.texts[language]?.actions?.secondary?.parameter"
                           matInput/>
                  </mat-form-field>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="form-row-container">
          <app-sync-form-field
            [target]="ruleForm['controls'].notification['controls']['command']"
            [source]="comparingRule.notification.command"/>
          <mat-form-field appearance="outline" class="monaco-form-field grow-fast">
            <mat-label>Command</mat-label>
            <textarea cdkTextareaAutosize="true" readonly="true"
                      [value]="comparingRule.notification.command" matInput>
                    </textarea>
          </mat-form-field>
        </div>
      </div>
    }
  </div>

  <div class="rules-footer-sticky mat-elevation-z3">
    <div class="button-container">
      @if (comparingRule) {

        <button
          (click)="closeComparisonView('Apply changes to form.')"
          [disabled]="isEqualToRuleInForm(originalRule)"
          mat-flat-button color="primary">
          APPLY LEFT FORM
        </button>

        <button
          (click)="importRule(comparingRule); closeComparisonView('Apply all changes to form.')"
          [disabled]="isEqualToRuleInForm(comparingRule)"
          mat-flat-button color="primary">
          APPLY RIGHT FORM
        </button>

        <button
          (click)="importRule(originalRule); closeComparisonView('Revert changes.')"
          mat-stroked-button>
          CANCEL
        </button>

        @if (isEqualToRuleInForm(comparingRule) && isEqualToRuleInForm(originalRule)) {
          <div style="margin-left: 24px;">
            Nothing to apply because rules are equal.
          </div>
        }

      } @else {

        <button
          [disabled]="!authTokenService.hasPermission(Permission.WRITE_RULES) || !(this.ruleForm.valid && this.ruleForm.dirty)"
          class="mat-flat-button"
          color="primary" mat-flat-button type="submit">
          SAVE
        </button>

        <a class="mat-flat-button" color="accent" mat-flat-button queryParamsHandling="preserve"
           routerLink="../..">CANCEL</a>
        <div class="grow-slow">
          <mat-error *ngIf="createOrUpdateError">{{ createOrUpdateError }}</mat-error>
        </div>
        <button (click)="runSimulation()"
                [disabled]="!authTokenService.hasPermission(Permission.READ_RULES_SIMULATION) || !this.ruleForm.valid || isSimulationRunning"
                class="mat-flat-button"
                color="primary"
                mat-flat-button
                matTooltip="Simulation for selected building
              CTRL + Enter"
                matTooltipClass="multiline"
                type="button">
          RUN SIMULATION
        </button>

      }
    </div>
  </div>
</form>
