export class MoostEvent {
  timestamp?: number = 0;
  forecastTimestamp?: number = 0;
  customerId?: string = '';
  customerBuildingId?: string = '';
  deviceId?: string = '';
  deviceName?: string = '';
  value?: number = 0;
  type?: string = '';
  source?: string = '';
}

export class DataUnit {
  static readonly ID: DataUnit = new DataUnit('', 'Id', 'ID');
  static readonly NUMBER: DataUnit = new DataUnit('', 'Number', 'Value');
  static readonly BOOLEAN: DataUnit = new DataUnit('', 'Boolean', '0/1');
  static readonly PERCENT: DataUnit = new DataUnit('%', 'Percent', '%');
  static readonly CELSIUS: DataUnit = new DataUnit('°C', 'Celsius', '°C');
  static readonly WATT: DataUnit = new DataUnit('W', 'Watt', 'W');
  static readonly WATT_HOUR: DataUnit = new DataUnit('Wh', 'WattHour', 'Wh');
  static readonly values: DataUnit[] = [
    DataUnit.WATT,
    DataUnit.WATT_HOUR,
    DataUnit.PERCENT,
    DataUnit.ID,
    DataUnit.NUMBER,
    DataUnit.BOOLEAN,
    DataUnit.CELSIUS,
  ];
  readonly name: string;
  readonly symbol: string;
  readonly display: string;

  constructor(symbol: string, name: string, display: string) {
    this.name = name;
    this.symbol = symbol;
    this.display = display;
  }

  static ofEventType(eventType: string): DataUnit {
    if (eventType.indexOf("ANOMALY_SCORE") > -1) {
      return DataUnit.NUMBER;
    } else if (eventType.indexOf("RATE") > -1) {
      return DataUnit.PERCENT;
    } else if (eventType.indexOf("TEMPERATURE") > -1) {
      return DataUnit.CELSIUS;
    } else if (eventType.indexOf("POWER") > -1) {
      return DataUnit.WATT;
    } else if (eventType.indexOf("ENERGY") > -1) {
      return DataUnit.WATT_HOUR;
    } else if (eventType.indexOf("IS_") > -1) {
      return DataUnit.BOOLEAN;
    } else if (eventType.indexOf("_STATE") > -1) {
      return DataUnit.ID;
    } else if (eventType.indexOf("_STATUS") > -1) {
      return DataUnit.ID;
    } else if (eventType.indexOf("_MODE") > -1) {
      return DataUnit.ID;
    } else if (eventType.indexOf("_PRICE") > -1) {
      return DataUnit.NUMBER;
    } else {
      return DataUnit.NUMBER;
    }
  }
}

export class EventTypeSourceFilter {
  types?: string[];
  sources?: string[];
}

export class EventsFilter {
  readonly startTimestampMillis: number;
  readonly endTimestampMillis: number;
  readonly customerBuildingIds: string[];
  readonly sources: string[];
  readonly types: string[];
  readonly deviceIds: string[];
  readonly deviceNames: string[];

  constructor(startTimestampMillis: number, endTimestampMillis: number,
              customerBuildingIds?: string[],
              sources?: string[], types?: string[],
              deviceIds?: string[], deviceNames?: string[]) {
    this.startTimestampMillis = startTimestampMillis;
    this.endTimestampMillis = endTimestampMillis;
    this.customerBuildingIds = customerBuildingIds;
    this.sources = sources;
    this.types = types;
    this.deviceIds = deviceIds;
    this.deviceNames = deviceNames;
  }
}

export interface IEvent extends MoostEvent {
}
