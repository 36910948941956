import {Component, Input} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {finalize} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {TranslationService} from '../../translation.service';
import {MatIconButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-translate-form-button',
    standalone: true,
    imports: [
        MatIcon,
        MatProgressSpinner,
        MatIconButton,
        MatTooltip,
        NgIf
    ],
    templateUrl: './translate-form-button.component.html',
    styleUrl: './translate-form-button.component.scss'
})
export class TranslateFormButtonComponent {

    @Input() fieldNamePrefix: string;
    @Input() sourceLanguage: string;
    @Input() notificationLanguages: string[] = [];
    @Input() ruleForm: FormGroup;
    protected loadingTranslations: number;

    constructor(private translationService: TranslationService) {
    }

    translateNotificationField(): void {
        const sourceText: string = this.ruleForm.get(['notification', `${this.fieldNamePrefix}_${this.sourceLanguage}`]).value;
        const targetLanguages: string[] = this.notificationLanguages.filter((lang: string) => lang !== this.sourceLanguage);
        this.loadingTranslations = 0;
        for (let targetLang of targetLanguages) {
            this.loadingTranslations++;
            this.translationService.translate(sourceText, this.sourceLanguage, targetLang)
                .pipe(finalize(() => this.loadingTranslations--))
                .subscribe((translation: string) => {
                    this.ruleForm.get(['notification', `${this.fieldNamePrefix}_${targetLang}`]).setValue(translation);
                    this.ruleForm.get(['notification', `${this.fieldNamePrefix}_${targetLang}`]).markAsDirty();
                });
        }
        this.ruleForm.markAsDirty();
    }
}
