import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {EChartsOption} from 'echarts/types/dist/echarts';
import {IBuilding} from '../buildings.models';
import {CallbackDataParams} from 'echarts/types/dist/shared';
import {ColorPalette} from '../../shared-module/color-palette';

@Component({
  selector: 'app-moost-building-motivation-chart',
  templateUrl: './moost-building-motivation-chart.component.html'
})
export class MoostBuildingMotivationChartComponent implements OnInit {
  @Input()
  building: IBuilding;

  private optionsSubject: BehaviorSubject<EChartsOption> = new BehaviorSubject<EChartsOption>({});
  options: Observable<EChartsOption> = this.optionsSubject.asObservable();

  ngOnInit(): void {
    this.updateChart();
  }

  private updateChart(): void {
    this.optionsSubject.next({
      radar: {
        indicator: [
          {name: 'Ecological', max: 1, color: "#000000"},
          {name: 'Economical', max: 1, color: "#000000"},
          {name: 'Self Sufficiency', max: 1, color: "#000000"},
        ],
        splitArea: {
          areaStyle: {
            color: ['rgba(250,250,250,0.3)', 'rgba(45, 190, 96, 0.2)']
          }
        }
      },
      series: [{
        type: 'radar',
        color: ColorPalette.PRIMARY,
        data: [
          {
            label: {
              show: true,
              formatter: function (params: CallbackDataParams): string {
                return params.value ? Number(params.value).toFixed(2) : '';
              }
            },
            value: [
              this.building?.profile?.ecologicalScore,
              this.building?.profile?.economicalScore,
              this.building?.profile?.selfSufficiencyScore
            ],
            name: 'Motivation Profile'
          }
        ]
      }]
    });
  }
}
