import { Injectable, inject } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {FulfillmentPreference, IFulfillmentPreference} from './fulfillment.model';
import {AuthTokenService} from '../auth-token-module/auth-token.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private httpClient = inject(HttpClient);
  private authTokenService = inject(AuthTokenService);

  fulfillmentPreferenceUrl: string = `${environment.apiUrl}/customer/fulfillmentPreferences`;

  getFulfillmentPreference(): Observable<IFulfillmentPreference> {
    return this.httpClient.get<IFulfillmentPreference>(`${this.fulfillmentPreferenceUrl}/v1`);
  }

  createFulfillmentPreference(customerId: string, restApiToken: string, restBearerToken: string, restBasicAuthUser: string,
                              restBasicAuthPassword: string, restHostname: string, restPath: string,
                              restIsSecure: boolean, restAuthStrategy: string, type: string,
                              recipientEmailAddress: string, customerBuildingId: string, fcmToken: string
  ): Observable<IFulfillmentPreference> {
    return this.httpClient.post<IFulfillmentPreference>(`${this.fulfillmentPreferenceUrl}/v1`,
      this.sanitizeFulfillmentPreference(customerId, restApiToken, restBearerToken, restBasicAuthUser, restBasicAuthPassword, restHostname, restPath, restIsSecure, restAuthStrategy, type, recipientEmailAddress, customerBuildingId, fcmToken)
    ).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  updateFulfillmentPreference(id: string, restApiToken: string, restBearerToken: string, restBasicAuthUser: string,
                              restBasicAuthPassword: string, restHostname: string, restPath: string,
                              restIsSecure: boolean, restAuthStrategy: string, type: string,
                              recipientEmailAddress: string, customerBuildingId: string, fcmToken: string
  ): Observable<IFulfillmentPreference> {
    const customerId = this.authTokenService.getCustomerId();
    return this.httpClient.put<IFulfillmentPreference>(`${this.fulfillmentPreferenceUrl}/${id}/v1`,
      this.sanitizeFulfillmentPreference(customerId, restApiToken, restBearerToken, restBasicAuthUser, restBasicAuthPassword, restHostname, restPath, restIsSecure, restAuthStrategy, type, recipientEmailAddress, customerBuildingId, fcmToken)
    )
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      )
  }

  deleteFulfillmentPreference(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.fulfillmentPreferenceUrl}/${id}/v1`);
  }

  private sanitizeFulfillmentPreference(
    customerId: string,
    restApiToken: string, restBearerToken: string, restBasicAuthUser: string, restBasicAuthPassword: string,
    restHostname: string, restPath: string, restIsSecure: boolean, restAuthStrategy: string,
    type: string, recipientEmailAddress: string, customerBuildingId: string, fcmToken: string
  ): FulfillmentPreference {
    restAuthStrategy = (type === FulfillmentPreference.TYPE_REST) ? restAuthStrategy : null;
    restHostname = (type === FulfillmentPreference.TYPE_REST) ? restHostname : null;
    restPath = (type === FulfillmentPreference.TYPE_REST) ? restPath : null;
    restIsSecure = (type === FulfillmentPreference.TYPE_REST) ? restIsSecure : null;
    restApiToken = (restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_APITOKEN) ? restApiToken : null;
    restBearerToken = (restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BEARER) ? restBearerToken : null;
    restBasicAuthUser = (restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BASIC) ? restBasicAuthUser : null;
    restBasicAuthPassword = (restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BASIC) ? restBasicAuthPassword : null;
    recipientEmailAddress = (type === FulfillmentPreference.TYPE_EMAIL) ? recipientEmailAddress : null;
    fcmToken = (type === FulfillmentPreference.TYPE_FIREBASE_CLOUD_MESSAGING) ? fcmToken : null;
    customerBuildingId = (type === FulfillmentPreference.TYPE_EMAIL || type === FulfillmentPreference.TYPE_FIREBASE_CLOUD_MESSAGING) ? customerBuildingId : null;
    return {
      customerId,
      restApiToken,
      restBearerToken,
      restBasicAuthUser,
      restBasicAuthPassword,
      restHostname,
      restPath,
      restIsSecure,
      restAuthStrategy,
      type,
      recipientEmailAddress,
      customerBuildingId,
      fcmToken
    };
  }

}
