import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {Building} from '../buildings.models';
import {catchError, finalize} from 'rxjs/operators';
import {BuildingsService} from '../buildings.service';
import {EMPTY, Subscription} from 'rxjs';
import {
  BUILDING_FILTER_STATUS_ACTIVE,
  BUILDING_FILTER_STATUS_INACTIVE,
  BuildingsFormFilter
} from '../moost-buildings-filter/buildings-form-filter';
import {BuildingsTableFilter} from './buildings-table-filter';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {ActivatedRoute, Params} from '@angular/router';
import {CustomerService} from '../../shared-module/customer.service';
import {UtilityService} from '../../shared-module/utility.service';
import {Customer} from '../../shared-module/customer.model';

@Component({
  selector: 'app-moost-buildings-overview',
  templateUrl: './moost-buildings-overview.component.html',
  styleUrls: ['./moost-buildings-overview.component.scss'],
  standalone: false
})
export class MoostBuildingsOverviewComponent implements OnInit, OnDestroy {
  private headerService: MoostHeaderService = inject(MoostHeaderService);
  private buildingsService: BuildingsService = inject(BuildingsService);
  private authTokenService: AuthTokenService = inject(AuthTokenService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private customerService: CustomerService = inject(CustomerService);
  private utilityService: UtilityService = inject(UtilityService);

  readonly displayedColumns: string[] = ['customerBuildingId', 'location', 'activationDate',
    'deactivationDate', 'status', 'early-adopter', 'favorite'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  initFilter: BuildingsFormFilter;
  dataSource: MatTableDataSource<Building>;
  filterEventTypes: string[];
  filterEventSources: string[];
  filteredCustomerBuildingIds: string[];
  favoriteCustomerBuildingIds: string[];
  customerBuildingIdsBasedOnEventTypes: string[] = undefined;
  customerBuildingIdsBasedOnEventSources: string[] = undefined;
  isLoadingBuildings: boolean;
  isLoadingCustomerBuildingIdsBasedOnEventTypes: boolean;
  isLoadingCustomerBuildingIdsBasedOnEventSources: boolean;
  private loadBuildingsSubscription: Subscription;
  private loadCustomerBuildingIdsBasedOnEventTypesSubscription: Subscription;
  private loadCustomerBuildingIdsBasedOnEventSourcesSubscription: Subscription;

  constructor() {
    this.headerService.setTitle('Buildings');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams: Params): void => {
      this.initFilter = new BuildingsFormFilter(
        (queryParams['id'] ? queryParams['id'] : null),
        (queryParams['location'] ? queryParams['location'] : null),
        (queryParams['status'] ? queryParams['status'].split(",") : [BUILDING_FILTER_STATUS_ACTIVE]),
        (queryParams['types'] ? queryParams['types'].split(",") : null),
        (queryParams['sources'] ? queryParams['sources'].split(",") : null)
      );

      this.loadBuildings();
    });

    this.favoriteCustomerBuildingIds = Array.from(
      this.buildingsService.loadFavoriteCustomerBuildingIds(this.authTokenService.getCustomerId())
    );
  }

  ngOnDestroy(): void {
    this.loadBuildingsSubscription?.unsubscribe();
    this.loadCustomerBuildingIdsBasedOnEventTypesSubscription?.unsubscribe();
    this.loadCustomerBuildingIdsBasedOnEventSourcesSubscription?.unsubscribe();
  }

  applyFilter(formFilter: BuildingsFormFilter): void {
    this.customerService.getCustomer().subscribe((customer: Customer) => {
      const eventFrom: number = this.utilityService.getCustomerSpecificMoment(customer.id).subtract({days: 1}).toDate().getTime();
      const eventTo: number = this.utilityService.getCustomerSpecificMoment(customer.id).toDate().getTime();

      const changedEventTypes: boolean = !arraysEqual(this.filterEventTypes, formFilter.eventTypes);
      const changedEventSources: boolean = !arraysEqual(this.filterEventSources, formFilter.eventSources);
      if (changedEventTypes) {
        this.filterEventTypes = formFilter.eventTypes;
        this.loadCustomerBuildingIdsBasedOnEventTypeAndUpdateFilter(formFilter.eventTypes, eventFrom,
          eventTo, formFilter);
      }
      if (changedEventSources) {
        this.filterEventSources = formFilter.eventSources;
        this.loadCustomerBuildingIdsBasedOnEventSourceAndUpdateFilter(formFilter.eventSources, eventFrom,
          eventTo, formFilter);
      }
      if (!changedEventTypes && !changedEventSources) {
        this.updateFilter(formFilter);
      }
    });
  }

  saveFavorites(favoriteCustomerBuildingIds: string[]): void {
    this.favoriteCustomerBuildingIds = favoriteCustomerBuildingIds;
    this.buildingsService.saveFavoriteCustomerBuildingIds(
      this.authTokenService.getCustomerId(), new Set(favoriteCustomerBuildingIds));
  }

  isFavoriteBuilding(customerBuildingId: string): boolean {
    return this.favoriteCustomerBuildingIds?.includes(customerBuildingId);
  }

  toggleFavoriteBuilding(customerBuildingId: string): void {
    const favorites: Set<string> = new Set(this.favoriteCustomerBuildingIds);
    if (this.favoriteCustomerBuildingIds?.includes(customerBuildingId)) {
      favorites.delete(customerBuildingId);
    } else {
      favorites.add(customerBuildingId);
    }
    this.saveFavorites(Array.from(favorites))
  }

  getLocation(building: Building): string {
    return `${building.countryCode || '??'}-${building.zip} ${building.city}`;
  }

  sortData(sort: Sort): void {
    const buildings: Building[] = this.dataSource.data.slice();
    this.dataSource.data = buildings.sort((a: Building, b: Building) => {
      const order: number = (sort.direction === "asc" ? -1 : 1);
      switch (sort.active) {
        case "customerBuildingId":
          return a.customerBuildingId.localeCompare(b.customerBuildingId) * order;
        case "location":
          return this.getLocation(a).localeCompare(this.getLocation(b)) * order;
        case "status":
          return (a.deactivatedTimestamp - b.deactivatedTimestamp) * order;
        case "activationDate":
          return (a.activationTimestamp - b.activationTimestamp) * order;
        case "deactivationDate":
          return (a.deactivatedTimestamp - b.deactivatedTimestamp) * order;
        case "early-adopter":
          return (Number(a.isEarlyAdopter) - Number(b.isEarlyAdopter)) * order;
        case "favorite":
          return (Number(this.isFavoriteBuilding(a.customerBuildingId)) - Number(this.isFavoriteBuilding(b.customerBuildingId))) * order;
        default:
          return 0;
      }
    });
  }

  private loadBuildings(): void {
    this.isLoadingBuildings = true;
    this.loadBuildingsSubscription = this.buildingsService.getBuildings()
      .pipe(
        catchError(() => EMPTY),
        finalize((): boolean => this.isLoadingBuildings = false))
      .subscribe((buildings: Building[]): void => {
        this.filteredCustomerBuildingIds = buildings.map((it: Building) => it.customerBuildingId);
        this.dataSource = new MatTableDataSource<Building>(buildings);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.filterPredicate();
        this.updateFilter(this.initFilter);
        this.sortData(this.sort);
      });
  }

  private loadCustomerBuildingIdsBasedOnEventTypeAndUpdateFilter(eventTypes: string[], eventDateFrom: number, eventDateTo: number, formFilter: BuildingsFormFilter): void {
    this.isLoadingCustomerBuildingIdsBasedOnEventTypes = true;
    this.loadCustomerBuildingIdsBasedOnEventTypesSubscription = this.buildingsService.getCustomerBuildingIdsBasedOnEventTypes(
      eventTypes, eventDateFrom, eventDateTo)
      .pipe(
        catchError(() => EMPTY),
        finalize(() => this.isLoadingCustomerBuildingIdsBasedOnEventTypes = false))
      .subscribe((buildingIds: string[]): void => {
        this.customerBuildingIdsBasedOnEventTypes = buildingIds;
        this.updateFilter(formFilter);
      });
  }

  private loadCustomerBuildingIdsBasedOnEventSourceAndUpdateFilter(eventSources: string[], eventDateFrom: number, eventDateTo: number, formFilter: BuildingsFormFilter): void {
    this.isLoadingCustomerBuildingIdsBasedOnEventSources = true;
    this.loadCustomerBuildingIdsBasedOnEventSourcesSubscription = this.buildingsService.getCustomerBuildingIdsBasedOnEventSources(
      eventSources, eventDateFrom, eventDateTo)
      .pipe(
        catchError(() => EMPTY),
        finalize(() => this.isLoadingCustomerBuildingIdsBasedOnEventSources = false))
      .subscribe((buildingIds: string[]): void => {
        this.customerBuildingIdsBasedOnEventSources = buildingIds;
        this.updateFilter(formFilter);
      });
  }

  private updateFilter(formFilter: BuildingsFormFilter): void {
    let buildingIds: string[] = splitParameter((formFilter.id));
    if (Array.isArray(this.customerBuildingIdsBasedOnEventTypes)) {
      buildingIds = joinCommonEntries(buildingIds, this.customerBuildingIdsBasedOnEventTypes);
    }
    if (Array.isArray(this.customerBuildingIdsBasedOnEventSources)) {
      buildingIds = joinCommonEntries(buildingIds, this.customerBuildingIdsBasedOnEventSources);
    }
    this.dataSource.filter = JSON.stringify({
      ids: buildingIds,
      locations: splitParameter(formFilter.location),
      status: formFilter.status?.length ? formFilter.status : undefined,
    });
    this.filteredCustomerBuildingIds = this.dataSource.filteredData.map((it: Building) => it.customerBuildingId);
  }

  private filterPredicate(): (building: Building, filter: string) => boolean {
    return (building: Building, filter: string): boolean => {
      const tableFilter: BuildingsTableFilter = JSON.parse(filter);
      let match: boolean = true;
      if (Array.isArray(tableFilter.ids)) {
        const filteredBuildings: string = tableFilter.ids.find((it: string) =>
          building.customerBuildingId.toLowerCase().trim().includes(it.toLowerCase().trim()));
        match = match && !!filteredBuildings;
      }
      if (Array.isArray(tableFilter.locations)) {
        match = match && !!tableFilter.locations.find((it: string) =>
          this.getLocation(building).toLowerCase().trim().includes(it.toLowerCase().trim()));
      }
      if (Array.isArray(tableFilter.status)) {
        match = match && tableFilter.status.includes(building.deactivatedTimestamp ? BUILDING_FILTER_STATUS_INACTIVE : BUILDING_FILTER_STATUS_ACTIVE);
      }
      return match;
    };
  }
}

export function splitParameter(param: string): string[] {
  if (param) {
    return param.split(',').map((it: string) => it.trim()).filter((it: string) => it.length > 0);
  } else {
    return undefined;
  }
}

export function joinCommonEntries(list1: string[], list2: string[]): string[] {
  if (list1 === undefined) {
    return list2;
  }
  if (list2 === undefined) {
    return list1;
  }
  return list1.filter((it: string) => list2.includes(it));
}

export function arraysEqual(list1: string[], list2: string[]): boolean {
  if (list1 == list2) {
    return true;
  } else if (list1 && list2 && list1.length === list2.length) {
    list1.sort((a: string, b: string) => a.localeCompare(b));
    list2.sort((a: string, b: string) => a.localeCompare(b));
    return list1.every((val: string, index: number) => val === list2[index]);
  } else {
    return false;
  }
}
