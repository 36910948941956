import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MoostRulesOverviewComponent} from './moost-rules-overview/moost-rules-overview.component';
import {MoostRulesConfigurationComponent} from './moost-rules-configuration/moost-rules-configuration.component';
import {AuthGuard} from '@auth0/auth0-angular';


const routes: Routes = [
  {
    path: 'rules',
    component: MoostRulesOverviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rules/add',
    component: MoostRulesConfigurationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rules/:id',
    component: MoostRulesConfigurationComponent,
    canActivate: [AuthGuard]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RulesRoutingModule {
}
