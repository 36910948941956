import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective, NgControl, NgForm} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Subject, Subscription} from "rxjs";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {RuleLanguage} from '../rule-language/rule-language';
import {IDataset} from '../../rules.models';
import {editor} from 'monaco-editor';
import {DatasetsService} from '../../datasets.service';
import {TermType} from '../validator/term-type';
import {TermStructure} from '../validator/term-structure';
import ICodeEditor = editor.ICodeEditor;

declare const monaco: any;

@Component({
  selector: 'app-rule-language-form-field',
  templateUrl: './rule-language-form-field.component.html',
  styleUrls: ['./rule-language-form-field.component.scss'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: RuleLanguageFormFieldComponent
  }]
})
export class RuleLanguageFormFieldComponent implements MatFormFieldControl<string>, ControlValueAccessor,
  OnInit, OnDestroy {
  static nextId: number = 0;
  static readonly EDITOR_OPTIONS = {
    theme: RuleLanguage.THEME_NAME,
    language: RuleLanguage.LANGUAGE_NAME,
    lineNumbers: "off",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "400",
    fontSize: "15px",
    automaticLayout: true,
    scrollBeyondLastLine: false,
    wordWrap: 'on',
    wrappingStrategy: 'advanced',
    renderIndentGuides: false,
    minimap: {
      enabled: false
    },
    overviewRulerLanes: 0,
    hideCursorInOverviewRuler: true,
    scrollbar: {
      vertical: 'hidden'
    },
    overviewRulerBorder: false,
    glyphMargin: false,
    folding: false,
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 0,
    renderLineHighlight: "none",
    tabFocusMode: true,
    cursorWidth: 1
  };

  @HostBinding()
  id: string = `condition-form-control-${RuleLanguageFormFieldComponent.nextId++}`;
  @Input()
  'aria-describedby': string;
  options = RuleLanguageFormFieldComponent.EDITOR_OPTIONS;
  @Input() allowedResultTypes: TermType[];
  @Input() allowedResultStructures: TermStructure[];
  @Output() termChange: EventEmitter<RuleLanguageFormFieldComponent> = new EventEmitter<RuleLanguageFormFieldComponent>();
  touched: boolean = false;
  controlType: string = 'moost-condition-form-control';
  stateChanges: Subject<void> = new Subject<void>();
  focused: boolean = false;
  errorState: boolean = false;
  value: string | null;
  dataSourceSubscription: Subscription;
  private editor: any; // monaco-editor-core: class "CodeEditorWidget"

  constructor(@Optional() @Self() public ngControl: NgControl, @Optional() private _parentForm: NgForm,
              @Optional() private _parentFormGroup: FormGroupDirective, private elementRef: ElementRef,
              private datasetsService: DatasetsService) {
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  private _required: boolean = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get empty(): boolean {
    return !this.value && this.value == '';
  }

  private _disabled: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  ngOnInit(): void {
    this.dataSourceSubscription = this.datasetsService.datasetsSource.subscribe((datasets: IDataset[]): void => {
      RuleLanguage.EDITOR_CONFIG.updateVariables(datasets);
    });
  }

  onInit(editor: ICodeEditor): void {
    this.editor = editor;
    this.editor.onDidContentSizeChange(() => this.updateEditorHeight());
    RuleLanguage.EDITOR_CONFIG.setAllowedTypesAndStructures(editor.getModel().uri, this.allowedResultTypes, this.allowedResultStructures);
    // force re-validation, because can only be validated when Model-URI is set (which is not the case in 'ngOnInit()')
    RuleLanguage.EDITOR_CONFIG.validate(editor.getModel().uri);
    // if invalid, make sure that form field is marked as such
    this.markAsTouched();
    this.updateErrorState();
    this.termChange.emit(this);
  }

  ngOnDestroy(): void {
    this.dataSourceSubscription?.unsubscribe();
    this.stateChanges.complete();
    RuleLanguageFormFieldComponent.nextId--
  }

  onFocusIn(): void {
    if (!this.focused) {
      this.markAsTouched()
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.markAsTouched()
      this.focused = false;
      this.stateChanges.next();
    }
  }

  valueChanged(value: string): void {
    if (value && this.value != value) {
      this.markAsTouched();
      this.onChange(value);
      this.stateChanges.next();
      this.value = value;
      this.updateErrorState();
      this.termChange.emit(this);
    }
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('textarea').focus();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elementRef.nativeElement.querySelector('.rule-term-editor')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  private updateEditorHeight(): void {
    const contentHeight: number = Math.min(1000, this.editor.getContentHeight());
    this.editor.layout({width: this.editor.getLayoutInfo().width, height: contentHeight});
  };

  private updateErrorState(): void {
    let errorMarkers: editor.IMarker[] = [];
    if (this.editor) {
      errorMarkers = monaco.editor.getModelMarkers({resource: this.editor.getModel().uri})
        .filter(it => it.severity = monaco.MarkerSeverity.Error);
    }
    const parent: NgForm | FormGroupDirective = this._parentFormGroup || this._parentForm;
    const oldState: boolean = this.errorState;
    const newState: boolean = (errorMarkers.length > 0) && (this.touched || parent.submitted);

    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
    this.ngControl.control.setErrors(this.errorState ? {invalidTerm: true} : null);
  }

  private onChange = (value: string): void => {
  };

  private onTouched = (): void => {
  }
}
