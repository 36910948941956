<div class="chart-container">
  <app-moost-notifications-per-rule-chart
    *ngIf="showPerRuleChart"
    [filter]="filter"
    class="chart-item"
    title="Notifications by Rule">
  </app-moost-notifications-per-rule-chart>

  <app-moost-notifications-per-delivery-status-chart
    [filter]="filter"
    class="chart-item"
    title="Notifications by Delivery Status">
  </app-moost-notifications-per-delivery-status-chart>

  <app-moost-notifications-per-interaction-chart
    [filter]="filter"
    class="chart-item"
    title="Notifications by Interaction Type">
  </app-moost-notifications-per-interaction-chart>
</div>

<div class="chart-container">
  <app-moost-notifications-heatmap
    [filter]="filter"
    class="chart-item">
  </app-moost-notifications-heatmap>
</div>

<div class="chart-container">
  <app-moost-notifications-interaction-rate-chart
    [filter]="filter"
    class="chart-item"
    title="Interactions">
  </app-moost-notifications-interaction-rate-chart>
</div>
