<div>
  <div class="filter">
    <app-moost-buildings-filter
      [filter]="initFilter"
      (applyFilterChange)="applyFilter($event)"
      (saveFavoritesChange)="saveFavorites($event)"
      [favoriteCustomerBuildingIds]="favoriteCustomerBuildingIds"
      [filteredCustomerBuildingIds]="filteredCustomerBuildingIds"
    ></app-moost-buildings-filter>
  </div>

  <div class="page-content">
    <div
      *ngIf="isLoadingBuildings || isLoadingCustomerBuildingIdsBasedOnEventTypes || isLoadingCustomerBuildingIdsBasedOnEventTypes"
      class="table-spinner">
      <mat-spinner></mat-spinner>
    </div>

    <table (matSortChange)="sortData($event)" [dataSource]="dataSource" mat-table
           matSort matSortActive="registrationDate" matSortDirection="asc" matSortDisableClear="true">

      <ng-container matColumnDef="customerBuildingId">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Building ID</th>
        <td *matCellDef="let building" mat-cell> {{ building.customerBuildingId }}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Location</th>
        <td *matCellDef="let building" mat-cell>{{ getLocation(building) }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
        <td *matCellDef="let building" mat-cell>{{ (building.deactivatedTimestamp ? "Inactive" : "Active") }}</td>
      </ng-container>

      <ng-container matColumnDef="registrationDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Activated</th>
        <td *matCellDef="let building" mat-cell>{{ building.registrationTimestamp * 1000 | date: 'dd.MM.yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="deactivationDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Deactivated</th>
        <td *matCellDef="let building"
            mat-cell>{{ (building.deactivatedTimestamp ? (building.deactivatedTimestamp * 1000 | date: 'dd.MM.yyyy') : '') }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef class="no-padding" mat-header-cell></th>
        <td *matCellDef="let building" class="no-padding" mat-cell>
          <a [routerLink]="['/buildings/', building.customerBuildingId]" aria-label="Statistics"
             class="more-button" mat-icon-button>
            <mat-icon class="material-icons-outlined" color="primary">data_usage</mat-icon>
          </a>
          <a [routerLink]="['/buildings/', building.customerBuildingId, 'edit']"
             aria-label="Edit"
             class="more-button" mat-icon-button>
            <mat-icon color="primary">edit</mat-icon>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="early-adopter">
        <th *matHeaderCellDef class="no-padding" mat-header-cell mat-sort-header>
          <mat-icon matTooltip="Early Adopter">school</mat-icon>
        </th>
        <td *matCellDef="let building" class="no-padding" mat-cell>
          @if (building.isEarlyAdopter) {
            <mat-icon matTooltip="Early Adopter">school</mat-icon>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="favorite">
        <th *matHeaderCellDef class="no-padding" mat-header-cell mat-sort-header>
          <mat-icon style="padding-left: 12px;">star</mat-icon>
        </th>
        <td *matCellDef="let building" class="no-padding" mat-cell>
          <button (click)="toggleFavoriteBuilding(building.customerBuildingId)"
                  color="gray"
                  mat-icon-button
                  matTooltip="{{isFavoriteBuilding(building.customerBuildingId) ? 'Remove from favorites' : 'Add to favorites'}}">
            <mat-icon [class]="isFavoriteBuilding(building.customerBuildingId) ? 'material-icons-outlined' : ''">star
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let building; columns: displayedColumns" mat-row></tr>

      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell" colspan="9999">
          No building found
        </td>
      </tr>
    </table>

    <mat-paginator
      [hidden]="isLoadingBuildings || isLoadingCustomerBuildingIdsBasedOnEventTypes || isLoadingCustomerBuildingIdsBasedOnEventSources"
      [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </div>
</div>
