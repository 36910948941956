<div class="mat-elevation-z2 table-component">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input (keyup)="applyFilter($event)" matInput>
  </mat-form-field>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Device ID</mat-header-cell>
      <mat-cell *matCellDef="let device">{{ device.id }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
      <mat-cell *matCellDef="let device">{{ device.type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="product_name">
      <mat-header-cell *matHeaderCellDef>Product</mat-header-cell>
      <mat-cell *matCellDef="let device">{{ device.product_name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef>Discovered</mat-header-cell>
      <mat-cell *matCellDef="let device">{{ device.createdAt * 1000 | date: 'dd.MM.yyyy':'CET' }}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons>
  </mat-paginator>
</div>
