import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NotificationsService} from '../../notifications-module/notifications.service';
import {catchError, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-moost-send-test-notification-dialog',
    templateUrl: './moost-send-test-notification.component.html',
    styleUrls: ['./moost-send-test-notification.component.scss'],
    animations: [
        trigger('toggleNotificationForm', [
            state('open', style({ height: '*', opacity: 1 })),
            state('closed', style({ height: '0', opacity: 0 })),
            transition('open <=> closed', [animate('0.5s')]),
        ])
    ],
    standalone: false
})
export class MoostSendTestNotificationComponent implements OnInit, OnDestroy {
  dialogRef = inject<MatDialogRef<MoostSendTestNotificationComponent>>(MatDialogRef);
  private notificationsService = inject(NotificationsService);
  data = inject<{
    customerBuildingId: string;
    ruleId: string;
    notificationJsonAsString: string;
}>(MAT_DIALOG_DATA);


  notificationFormControl: FormControl<string>;
  isNotificationFormVisible: boolean = false;
  sendNotificationError: string;
  private sendNotificationSubscription: Subscription;

  ngOnInit(): void {
    this.notificationFormControl = new FormControl(this.data.notificationJsonAsString, {
      updateOn: 'change',
      validators: [Validators.required, this.jsonValidator()]
    });
  }

  ngOnDestroy(): void {
    this.sendNotificationSubscription?.unsubscribe();
  }

  sendTestNotification(): void {
    this.sendNotificationError = undefined;
    this.sendNotificationSubscription = this.notificationsService.sendTestNotificationFulfillment(this.data.customerBuildingId, this.data.ruleId, JSON.parse(this.notificationFormControl.value))
      .pipe(
        map(() => {
          this.dialogRef.close(true)
        }),
        catchError(() => this.sendNotificationError = "Failed to send notification.")
      )
      .subscribe();
  }

  private jsonValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      try {
        JSON.parse(control.value);
        return null;
      } catch (e) {
        return {isInvalid: true};
      }
    };
  }
}

