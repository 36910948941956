import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@auth0/auth0-angular';
import {MoostUsersProfileComponent} from '../users-module/moost-users-profile/moost-users-profile.component';


const routes: Routes = [
  {
    path: 'profile',
    component: MoostUsersProfileComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule {
}
