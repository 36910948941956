import {INotification, IRule} from '../rules-module/rules.models';

export class PushNotification {
  createdAtTimeMillis?: number = 0;
  customerId?: string = '';
  customerBuildingId?: string = '';
  ruleId?: string = '';
  pushNotificationId?: string = '';
  priority?: string;
  notification: INotification;
  interaction: {
    actionQualifier: ActionQualifier;
  }
  delivery_status: {
    reason: string;
    status: string;
  }
}

export enum ActionQualifier {
  OPENAPP = "OPENAPP",
  VIEWED = "VIEWED",
  DISMISS = "DISMISS",
  STOPDELIVERY = "STOPDELIVERY",
}

export enum DeliveryStatuses {
  DELIVERED = "DELIVERED",
  DROPPED = "DROPPED",
}

export class PushNotificationAction {
  text: IMessage;
  actionQualifier: string;
}

export class NotificationLanguage {
  code: string;
  name: string;
}

class Message {
  de?: string = '';
  en?: string = '';
  fr?: string = '';
}

export class MatColumnName {
  displayName: string;
  columnDefName: string;
}

export class NotificationsFilter {
  readonly startTimestampMillis: number;
  readonly endTimestampMillis: number;
  readonly customerBuildingIds: string[];
  readonly ruleIds: string[];
  readonly deliveryStatuses: string[];
  readonly interactions: string[];
  readonly rulesInventory: IRule[];

  constructor(startTimestampMillis: number, endTimestampMillis: number,
              customerBuildingId: string[],
              ruleIds: string[], rulesInventory: IRule[],
              deliveryStatuses: string[], interactions: string[]) {
    this.startTimestampMillis = startTimestampMillis;
    this.endTimestampMillis = endTimestampMillis;
    this.customerBuildingIds = customerBuildingId;
    this.ruleIds = ruleIds;
    this.rulesInventory = rulesInventory;
    this.deliveryStatuses = deliveryStatuses;
    this.interactions = interactions;
  }

  public getRuleNameIfKnown(ruleId: string): string {
    return this.rulesInventory?.find(rule => rule.id === ruleId)?.name || ruleId;
  }
}

export interface IPushNotification extends PushNotification {
}

export interface IPushNotificationAction extends PushNotificationAction {
}

export interface IMessage extends Message {
}
