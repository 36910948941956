import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {CustomerService} from '../customer.service';
import {Customer} from '../customer.model';
import {Subscription} from 'rxjs';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';

@Component({
    selector: 'app-moost-user-menu',
    templateUrl: './moost-user-menu.component.html',
    styleUrls: ['./moost-user-menu.component.scss'],
    standalone: false
})
export class MoostUserMenuComponent implements OnInit, OnDestroy {
  protected authTokenService = inject(AuthTokenService);
  private customerService = inject(CustomerService);

  public customer: Customer;
  private customerSubscription: Subscription;

  ngOnInit(): void {
    this.customerSubscription = this.customerService.getCustomer().subscribe(it => {
      this.customer = it
    });
  }

  ngOnDestroy(): void {
    this.customerSubscription?.unsubscribe();
  }
}
