import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxEchartsModule} from 'ngx-echarts';
import {MoostBaseChartComponent} from './moost-base-chart/moost-base-chart.component';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [
    MoostBaseChartComponent,
  ],
  exports: [
    MoostBaseChartComponent,
  ],
  imports: [
    CommonModule,
    NgxEchartsModule,
    MatIconModule
  ]
})
export class MoostChartsModule {
}
