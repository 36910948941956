import {Component, inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BuildingsService} from '../buildings.service';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {NotificationsFilter} from '../../notifications-module/notifications.models';
import {StreaksService} from '../../rules-module/streaks.service';
import {RulesService} from '../../rules-module/rules.service';
import {Rule, Streak} from '../../rules-module/rules.models';
import {EMPTY, forkJoin, Observable, ObservedValueOf, Subscription} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {
  DualTariffSetting,
  DynamicTariff,
  HighTariffEntry,
  IBuilding,
  LowTariffSetting,
  TariffSettings,
  WeekTariff
} from '../buildings.models';
import {BuildingDetailFormFilter} from '../moost-buildings-detail-filter/building-detail-form-filter';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
  ConfirmDialogModel,
  MoostConfirmDialogComponent
} from '../../shared-module/moost-confirm-dialog/moost-confirm-dialog.component';
import {Permission} from '../../auth-token-module/auth-token.models';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {formatDate} from '@angular/common';
import {EChartsOption} from 'echarts/types/dist/echarts';
import {ColorPalette, toRgba} from '../../shared-module/color-palette';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-moost-buildings-detail',
  templateUrl: './moost-buildings-detail.component.html',
  styleUrls: ['./moost-buildings-detail.component.scss'],
  standalone: false
})
export class MoostBuildingsDetailComponent implements OnInit, OnDestroy {
  private headerService: MoostHeaderService = inject(MoostHeaderService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private buildingsService: BuildingsService = inject(BuildingsService);
  private streaksService: StreaksService = inject(StreaksService);
  private rulesService: RulesService = inject(RulesService);
  private router: Router = inject(Router);
  dialog: MatDialog = inject(MatDialog);
  protected authTokenService: AuthTokenService = inject(AuthTokenService);
  private locale: string = inject(LOCALE_ID);

  protected readonly Permission = Permission;
  filter: BuildingDetailFormFilter;
  isLoadingBuilding: boolean;
  loadBuildingSubscription: Subscription;
  building: IBuilding;
  notificationFilter: NotificationsFilter;
  streaks: Streak[];
  rules: Rule[];
  isLoadingStreaksAndRules: boolean;
  loadStreaksAndRulesSubscription: Subscription;
  displayedColumnsStreaks: string[] = ["badge", "ruleId", "ruleName"]
  displayedColumnsInactiveRules: string[] = ["ruleId", "ruleName", "action"]
  displayColumnsDualTariffs: string[] = ["day", "value"]
  dualHighTariffs: HighTariffEntry[] = [];
  tariffChartsOptions: any = {};

  constructor() {
    this.headerService.setHeader('Building Detail', '/buildings');
  }

  ngOnInit(): void {
    this.isLoadingBuilding = true;
    this.route.params.subscribe((params) => {
      const customerBuildingId: string = params['id'];
      this.loadBuildingSubscription = this.buildingsService.getBuilding(customerBuildingId).subscribe({
        next: (building: IBuilding): void => {
          this.building = building;
          this.populateDualTariffTable(building.settings?.tariff?.dual);
          this.tariffChartsOptions = this.buildTariffChartsOptions();
          this.notificationFilter = this.buildNotificationFilter(this.filter);
          this.isLoadingBuilding = false;
        },
        error: (error): void => {
          console.error(error)
          this.isLoadingBuilding = false;
        }
      });
      this.isLoadingStreaksAndRules = true;
      this.loadStreaksAndRulesSubscription = forkJoin({
        loadedStreaks: this.streaksService.getStreaksByCustomerBuildingId(customerBuildingId),
        loadedRules: this.rulesService.getAllRules()
      }).pipe(
        catchError(() => EMPTY),
        finalize(() => this.isLoadingStreaksAndRules = false)
      ).subscribe((results: {
        loadedStreaks: ObservedValueOf<Observable<Streak[]>>
        loadedRules: ObservedValueOf<Observable<Rule[]>>;
      }): void => {
        this.streaks = results.loadedStreaks;
        this.rules = results.loadedRules;
        this.notificationFilter = this.buildNotificationFilter(this.filter);
      });
    });
  }

  ngOnDestroy(): void {
    this.loadBuildingSubscription?.unsubscribe();
    this.loadStreaksAndRulesSubscription?.unsubscribe();
  }

  applyFilter($event: BuildingDetailFormFilter): void {
    this.filter = $event;
    this.notificationFilter = this.buildNotificationFilter(this.filter);
  }

  getRuleName(ruleId: string): string {
    if (ruleId && this.rules) {
      return this.rules.find(it => it.id === ruleId)?.name || ruleId;
    }
  }

  deleteBuilding(): void {
    const dialogConfig: MatDialogConfig<ConfirmDialogModel> = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.data = {
      title: "Delete Building",
      message: `Do you really want to delete the building and all related event data?`,
      confirm: "YES",
      dismiss: "NO",
      icon: "warning_amber",
      confirmColor: "warn"
    };

    this.dialog.open(MoostConfirmDialogComponent, dialogConfig).afterClosed()
      .subscribe((confirmed: boolean): void => {
        if (confirmed) {
          this.buildingsService.deleteBuilding(this.building.customerBuildingId).subscribe({
            next: (): void => {
              this.router.navigate(["buildings"], {queryParamsHandling: 'merge'});
            },
            error: (error): void => {
              console.error(error.message);
            }
          })
        }
      });
  }

  private buildNotificationFilter(filter: BuildingDetailFormFilter): NotificationsFilter {
    return new NotificationsFilter(
      filter?.startTimestampMillis,
      filter?.endTimestampMillis,
      [this.building?.customerBuildingId],
      null,
      this.rules,
      filter?.deliveryStatuses,
      null);
  }

  private populateDualTariffTable(dualTariff: DualTariffSetting): void {
    const highTariffs: HighTariffEntry[] = [];
    const lowTariff: LowTariffSetting = dualTariff?.lowTariff;
    highTariffs.push({
      day: "Monday",
      value: lowTariff?.mondayEndTime + " - " + lowTariff?.mondayStartTime
    });
    highTariffs.push({
      day: "Tuesday",
      value: lowTariff?.tuesdayEndTime + " - " + lowTariff?.tuesdayStartTime
    });
    highTariffs.push({
      day: "Wednesday",
      value: lowTariff?.wednesdayEndTime + " - " + lowTariff?.wednesdayStartTime
    });
    highTariffs.push({
      day: "Thursday",
      value: lowTariff?.thursdayEndTime + " - " + lowTariff?.thursdayStartTime
    });
    highTariffs.push({
      day: "Friday",
      value: lowTariff?.fridayEndTime + " - " + lowTariff?.fridayStartTime
    });
    highTariffs.push({
      day: "Saturday",
      value: lowTariff?.saturdayEndTime + " - " + lowTariff?.saturdayStartTime
    });
    highTariffs.push({
      day: "Sunday",
      value: lowTariff?.sundayEndTime + " - " + lowTariff?.sundayStartTime
    });

    this.dualHighTariffs = highTariffs;
  }

  buildTariffChartsOptions(): EChartsOption {
    const tariffSettings: TariffSettings = this.building.settings.tariff;
    if (tariffSettings != null) {
      const isDynamicTariff: boolean = tariffSettings.type === 'DYNAMIC';
      const tariffs: DynamicTariff[] | WeekTariff[] = (isDynamicTariff ? tariffSettings.dynamic.tariffs : this.buildWeekdayTariffs(tariffSettings));
      if (!tariffs) {
        return null;
      }
      const lowTariffThreshold: number = tariffSettings.minPrice + 0.25 * (tariffSettings.maxPrice - tariffSettings.minPrice);
      const highTariffThreshold: number = tariffSettings.minPrice + 0.75 * (tariffSettings.maxPrice - tariffSettings.minPrice);
      const priceSuffix: string = (tariffSettings.currency || '') + " / kWh";
      const tariffLegend: string[] = tariffs.map((it: DynamicTariff | WeekTariff) =>
        isDynamicTariff ? formatDate(it.from * 1000, "HH:mm", this.locale) : formatDate(it.from * 1000, "E HH:mm", this.locale, "UTC")
      );
      const tariffData: any[] = tariffs.map((it: DynamicTariff | WeekTariff) => {
        return {
          value: it.price,
          itemStyle: {
            color: it.price < lowTariffThreshold ? toRgba(ColorPalette.PRIMARY, 0.4) :
              it.price < highTariffThreshold ? toRgba(ColorPalette.PRIMARY, 0.7) :
                ColorPalette.PRIMARY
          }
        }
      });
      return {
        title: {
          text: this.buildTariffChartsTitle(tariffSettings, priceSuffix, tariffs),
          textStyle: {
            fontStyle: 'normal',
            fontWeight: 'lighter',
            fontSize: 13,
            lineHeight: 20,
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params: any): string => {
            const tariff: DynamicTariff | WeekTariff = tariffs[params[0].dataIndex];
            const priceClass: string = (
              tariff.price < lowTariffThreshold ? 'Low tariff' :
                tariff.price > highTariffThreshold ? 'High tariff' :
                  'Tariff');
            const timeRange: string = isDynamicTariff ? formatDate(tariff.from * 1000, "dd.MM.yyyy HH:mm", this.locale) + " - " + formatDate(tariff.to * 1000, "HH:mm", this.locale) :
              formatDate(tariff.from * 1000, "E HH:mm", this.locale, "UTC") + " - " + formatDate(tariff.to * 1000, "HH:mm", this.locale, "UTC");
            return timeRange + "<br/>" +
              `${priceClass}: ${tariff.price} ${priceSuffix}`;
          },
        },
        xAxis: {
          type: 'category',
          data: tariffLegend,
          axisLabel: {
            rotate: 60,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: `{value} ${priceSuffix}`
          }
        },
        series: [
          {
            data: tariffData,
            type: 'bar',
            barWidth: '95%',
          }
        ]
      }
    }
  }

  private buildTariffChartsTitle(tariffSettings: TariffSettings, priceSuffix: string, tariffs: DynamicTariff[] | WeekTariff[]): string {
    if (tariffSettings.type === 'SINGLE') {
      return `Tariff: ${tariffSettings.minPrice} ${priceSuffix}`;
    } else if (tariffSettings.type === 'DUAL') {
      return `Low Tariff: ${tariffSettings.minPrice} ${priceSuffix}\nHigh Tariff: ${tariffSettings.maxPrice} ${priceSuffix}`;
    } else if (tariffSettings.type === 'DYNAMIC') {
      return `Tariff Range: ${tariffSettings.minPrice} - ${tariffSettings.maxPrice} ${priceSuffix}\n` +
        'Time Range: ' + formatDate(tariffs[0].from * 1000, "dd.MM.yyyy HH:mm", this.locale) + " - " + formatDate(tariffs[tariffs.length - 1].to * 1000, "dd.MM.yyyy HH:mm", this.locale);
    }
    return '';
  }

  private buildWeekdayTariffs(tariffSettings: TariffSettings): WeekTariff[] {
    const type: string = tariffSettings.type;
    if (tariffSettings.minPrice == null || tariffSettings.maxPrice === null || (['SINGLE', 'DUAL'].indexOf(type) < 0)) {
      return null;
    }
    const tariffs: WeekTariff[] = [];
    const isSingleTariff: boolean = type === 'SINGLE';
    const timeSpan = 3600;
    const MONDAY_00_00: number = 345600;
    for (let time = 0; time < 7 * 24 * 3600; time = time + timeSpan) {
      const tariff: WeekTariff = {
        price: isSingleTariff ? tariffSettings.minPrice : this.getDualTariffPrice(time, tariffSettings),
        from: MONDAY_00_00 + time,
        to: MONDAY_00_00 + time + timeSpan
      }
      tariffs.push(tariff);
    }
    return tariffs;
  }

  private getDualTariffPrice(time: number, tariffSettings: TariffSettings): number {
    const lowTariff: LowTariffSetting = tariffSettings.dual.lowTariff;
    if (time < this.toWeekTime(0, lowTariff.mondayEndTime)) {
      return tariffSettings.minPrice;
    } else if (time < this.toWeekTime(0, lowTariff.mondayStartTime)) {
      return tariffSettings.maxPrice;
    } else if (time < this.toWeekTime(1, lowTariff.tuesdayEndTime)) {
      return tariffSettings.minPrice;
    } else if (time < this.toWeekTime(1, lowTariff.tuesdayStartTime)) {
      return tariffSettings.maxPrice;
    } else if (time < this.toWeekTime(2, lowTariff.wednesdayEndTime)) {
      return tariffSettings.minPrice;
    } else if (time < this.toWeekTime(2, lowTariff.wednesdayStartTime)) {
      return tariffSettings.maxPrice;
    } else if (time < this.toWeekTime(3, lowTariff.thursdayEndTime)) {
      return tariffSettings.minPrice;
    } else if (time < this.toWeekTime(3, lowTariff.thursdayStartTime)) {
      return tariffSettings.maxPrice;
    } else if (time < this.toWeekTime(4, lowTariff.fridayEndTime)) {
      return tariffSettings.minPrice;
    } else if (time < this.toWeekTime(4, lowTariff.fridayStartTime)) {
      return tariffSettings.maxPrice;
    } else if (time < this.toWeekTime(5, lowTariff.saturdayEndTime)) {
      return tariffSettings.minPrice;
    } else if (time < this.toWeekTime(5, lowTariff.saturdayStartTime)) {
      return tariffSettings.maxPrice;
    } else if (time < this.toWeekTime(6, lowTariff.sundayEndTime)) {
      return tariffSettings.minPrice;
    } else if (time < this.toWeekTime(6, lowTariff.sundayEndTime)) {
      return tariffSettings.maxPrice;
    } else {
      return tariffSettings.minPrice;
    }
  }

  private toWeekTime(dayOfWeek: number, timeHHMM: string): number {
    const hour: number = Number(timeHHMM.substring(0, 2));
    const minute: number = Number(timeHHMM.substring(3));
    return dayOfWeek * 24 * 3600 + hour * 3600 + minute * 60;
  }

  toggleEarlyAdopterState($event: MatSlideToggleChange): void {
    this.building.isEarlyAdopter = $event.checked;
    this.updateBuilding(this.building);
  }

  deactivateRule($event: MatSelectChange): void {
    const ruleId: string = $event.value;
    if (ruleId && !this.building.inactiveRules.includes(ruleId)) {
      this.building.inactiveRules.push(ruleId);
      this.updateBuilding(this.building);
    }
  }

  reactivateRule(inactiveRuleId: string): void {
    this.removeItemFromArray(this.building.inactiveRules, inactiveRuleId);
    this.updateBuilding(this.building);
  }

  getDeactivatableRules(): Rule[] {
    if (this.rules && this.building) {
      return this.rules
        .filter((rule: Rule) => !this.building.inactiveRules.includes(rule.id))
        .sort((r1: Rule, r2: Rule) => r1.name.localeCompare(r2.name))
    }
    return [];
  }

  removeItemFromArray(values: any[], value: any): void {
    values.forEach((item: any, index: number) => {
      if (item === value) {
        values.splice(index, 1);
      }
    });
  }

  updateBuilding(building: IBuilding): void {
    this.buildingsService.updateBuilding(building).subscribe({
      next: (building: IBuilding): void => {
        this.building = building;
      },
      error: (error): void => {
        console.error(error.message);
      }
    })
  }
}
