import {Component, inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DataPoint, Dataset, IDataset} from '../rules.models';
import {EventsService} from '../../events-module/events.service';
import {NotificationsService} from '../../notifications-module/notifications.service';
import {EChartsOption, LegendComponentOption} from 'echarts/types/dist/echarts';
import {IPushNotification} from '../../notifications-module/notifications.models';
import {DataUnit, EventTypeSourceFilter, IEvent} from '../../events-module/events.models';
import {LineSeriesOption, ScatterSeriesOption, SeriesOption} from 'echarts';
import {DatasetsService} from '../datasets.service';
import {YAXisOption} from 'echarts/types/dist/shared';
import {MoostRulesDataGraphAxis} from './moost-rules-data-graph.axis';
import {BuildingsService} from '../../buildings-module/buildings.service';
import {OptionDataValue} from 'echarts/types/src/util/types';
import {formatNumber} from '@angular/common';
import {RulesService} from '../rules.service';
import {TermType} from '../monaco-rule-language-editor/validator/term-type';
import {TermStructure} from '../monaco-rule-language-editor/validator/term-structure';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {IBuilding} from '../../buildings-module/buildings.models';
import {ColorPalette} from '../../shared-module/color-palette';
import {LanguageService} from '../../shared-module/language.service';
import {MinMax} from './min-max';
import {GraphScaleAligner} from './graph-scale-aligner';

@Component({
  selector: 'app-moost-rules-data-graph',
  templateUrl: './moost-rules-data-graph.component.html',
  styleUrls: ['./moost-rules-data-graph.component.scss'],
  animations: [
    trigger('toggleHelpLineForm', [
      state('open', style({height: '*', opacity: 1})),
      state('closed', style({height: '0', opacity: 0})),
      transition('open <=> closed', [animate('0.2s')]),
    ])
  ],
  standalone: false
})
export class MoostRulesDataGraphComponent implements OnInit, OnChanges, OnDestroy {
  private eventService = inject(EventsService);
  private buildingsService = inject(BuildingsService);
  private notificationsService = inject(NotificationsService);
  private datasetsService = inject(DatasetsService);
  private rulesService = inject(RulesService);
  private languageService = inject(LanguageService);
  private locale = inject(LOCALE_ID);

  private static readonly DELIVERED_NOTIFICATIONS_SERIES_ID: string = "DeliveredNotifications";
  private static readonly DROPPED_NOTIFICATIONS_SERIES_ID: string = "DroppedNotifications";
  private static readonly SIMULATED_DELIVERED_NOTIFICATIONS_SERIES_ID: string = "SimulatedDeliveredNotifications";
  private static readonly SIMULATED_DROPPED_NOTIFICATIONS_SERIES_ID: string = "SimulatedDroppedNotifications";
  private static readonly NOTIFICATIONS_SERIES_NAME: string = "Notifications";
  private static readonly DELIVERY_STATUS_DROPPED: string = "DROPPED";
  private static readonly DELIVERY_STATUS_DELIVERED: string = "DELIVERED";
  private static readonly KEY_SEPARATOR: string = '/';
  @Input() title: string;
  @Input() ruleId: string;
  @Input() customerBuildingId: string;
  @Input() startTimeRangeMillis: number;
  @Input() endTimeRangeMillis: number;
  @Input() simulatedNotifications: IPushNotification[];
  @Input() isSimulationRunning: boolean;
  @Input() isHelplineVisible: boolean = true;
  @Input() datasets: Dataset[];
  hasError: boolean = false;
  isLoadingEvents: boolean;
  isLoadingNotifications: boolean;
  seriesEvents: SeriesOption[] = [];
  seriesHelpLine: SeriesOption[] = [];
  seriesNotifications: SeriesOption[] = [];
  seriesSimulatedNotifications: SeriesOption[] = [];
  seriesEventsMetaInfo: SeriesMetaInfo[] = [];
  seriesHelpLineMetaInfo: SeriesMetaInfo = undefined;
  isRuleInactive: boolean;
  helpLineForm: FormGroup;
  helpLineTerm: string = '';
  helpLineUnit: DataUnit = DataUnit.NUMBER;
  helpLineError: string;
  isHelpLineLoading: boolean;
  isHelpLineFormVisible: boolean = false;
  protected readonly TermType = TermType;
  protected readonly TermStructure = TermStructure;
  private svgPathLetterIcon: string = "path://M441.088,63.154H14.774C6.615,63.154,0,69.77,0,77.93v300.003c0,8.16,6.615,14.775,14.774,14.775h426.313 " +
    "c8.16,0,14.775-6.614,14.775-14.775V77.93C455.862,69.77,449.248,63.154,441.088,63.154z M403.394,316.659 " +
    "c6.256,5.43,6.926,14.903,1.497,21.16c-5.43,6.254-14.901,6.928-21.161,1.496c-3.876-3.364-101.683-88.252-105.452-91.523 " +
    "l-40.515,35.164c-2.82,2.448-6.326,3.672-9.832,3.672s-7.012-1.224-9.832-3.672l-40.515-35.164 " +
    "c-3.77,3.272-101.576,88.159-105.452,91.523c-6.257,5.43-15.731,4.761-21.161-1.496c-5.43-6.257-4.76-15.73,1.497-21.16 " +
    "L154.7,227.93L52.468,139.203c-6.256-5.43-6.926-14.903-1.497-21.16c5.431-6.256,14.904-6.928,21.161-1.496 " +
    "c5.07,4.4,146.594,127.231,155.799,135.22c7.972-6.919,150.305-130.451,155.799-135.22c6.256-5.431,15.731-4.762,21.161,1.496 " +
    "c5.43,6.257,4.76,15.731-1.497,21.16L301.162,227.93L403.394,316.659z"
  private notificationsSubscription: Subscription;
  private eventsSubscription: Subscription;
  private buildingSubscription: Subscription;
  private simulationTermSubscription: Subscription;
  private optionsSubject: BehaviorSubject<EChartsOption> = new BehaviorSubject<EChartsOption>({});
  options: Observable<EChartsOption> = this.optionsSubject.asObservable();
  private readonly helpLineNamePrefix: string = 'Help Line';
  private readonly helpLineColor: string = '#966011';

  ngOnInit(): void {
    this.helpLineUnit = this.getDefaultHelpLineUnit(this.datasets);
    this.helpLineForm = this.buildHelpLineForm();
    this.drawChart();
  }

  private getDefaultHelpLineUnit(datasets: IDataset[]): DataUnit {
    if (datasets && datasets.length > 0 && datasets[0].event_types && datasets[0].event_types.length > 0) {
      return DataUnit.ofEventType(datasets[0].event_types[0]);
    } else {
      return DataUnit.NUMBER;
    }
  }

  private buildHelpLineForm(): FormGroup {
    return new FormGroup({
      helpLineTermFormField: new FormControl<string>(this.helpLineTerm),
      helpLineUnitFormField: new FormControl<DataUnit>(this.helpLineUnit),
    });
  }

  ngOnDestroy(): void {
    this.notificationsSubscription?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
    this.buildingSubscription?.unsubscribe();
    this.simulationTermSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasets) {
      this.drawChart();
    } else if (changes.customerBuildingId && !changes.customerBuildingId.firstChange) {
      this.buildingSubscription = this.buildingsService.getBuilding(changes.customerBuildingId.currentValue).subscribe(
        (building: IBuilding): void => {
          this.isRuleInactive = building.inactiveRules.includes(this.ruleId);
        }
      )
      this.drawChart();
    } else if (changes.startTimeRangeMillis || changes.endTimeRangeMillis) {
      this.drawChart();
    } else if (changes.simulatedNotifications) {
      this.drawSimulatedNotifications();
    }
  }

  helpLineTermChanged(event: any): void {
    const helpLineTermControl: AbstractControl = this.helpLineForm.controls.helpLineTermFormField;
    const helpLineUnitControl: AbstractControl = this.helpLineForm.controls.helpLineUnitFormField;
    const termChanged: boolean = helpLineTermControl?.value && this.helpLineTerm.trim() !== helpLineTermControl.value.trim();
    const unitChanged: boolean = helpLineTermControl?.value && this.helpLineUnit !== helpLineTermControl?.value.trim();
    if (!helpLineTermControl?.errors && (termChanged || unitChanged)) {
      this.helpLineTerm = helpLineTermControl.value;
      this.helpLineUnit = helpLineUnitControl.value;
      this.drawHelpLine();
    }
  }

  notificationsToScatterSeriesOptions(notifications: IPushNotification[], isSimulation: boolean): ScatterSeriesOption[] {
    const notificationOptions: ScatterSeriesOption[] = [];
    if (notifications) {
      const droppedNotifications: IPushNotification[] = notifications
        .sort((a: IPushNotification, b: IPushNotification): number => {
          return a.createdAtTimeMillis - b.createdAtTimeMillis
        }).filter((notification: IPushNotification): boolean => {
          return notification.delivery_status?.status === MoostRulesDataGraphComponent.DELIVERY_STATUS_DROPPED
        });
      const deliveredNotifications: IPushNotification[] = notifications
        .sort((a: IPushNotification, b: IPushNotification): number => {
          return a.createdAtTimeMillis - b.createdAtTimeMillis
        }).filter((notification: IPushNotification): boolean => {
          return notification.delivery_status?.status === MoostRulesDataGraphComponent.DELIVERY_STATUS_DELIVERED
        });
      if (deliveredNotifications.length > 0) {
        notificationOptions.push(this.getNotificationScatterSeriesOption("DELIVERED", deliveredNotifications, isSimulation));
      }
      if (droppedNotifications.length > 0) {
        notificationOptions.push(this.getNotificationScatterSeriesOption("DROPPED", droppedNotifications, isSimulation));
      }
    }
    return notificationOptions
  }

  buildLineSeriesOptions(type: string, source: string, deviceId: string, events: IEvent[]): LineSeriesOption {
    const dataset: Dataset = this.datasets?.find(it => it.event_types.includes(type));
    return {
      name: `${dataset.name} - ${source}${deviceId ? " - " + deviceId : ""}`,
      color: this.datasetsService.getHexColorStringForDataset(dataset, this.datasets),
      type: 'line',
      lineStyle: {
        type: this.datasetsService.getLineStyle(dataset),
      },
      yAxisId: MoostRulesDataGraphAxis.getYAXisId(DataUnit.ofEventType(type)),
      data: events
        .filter((e: IEvent) => !e.forecastTimestamp || e.forecastTimestamp <= Math.round(this.endTimeRangeMillis / 1000))
        .sort((a: IEvent, b: IEvent) => a.timestamp - b.timestamp)
        .map((event: IEvent): [Date, number] => {
          if (event.forecastTimestamp) {
            return [new Date(event.forecastTimestamp * 1000), event.value]
          } else if (event.type.includes("YESTERDAY")) {
            return [new Date((event.timestamp - 86400) * 1000), event.value]
          } else {
            return [new Date(event.timestamp * 1000), event.value]
          }
        })
    };
  }

  buildOptionYAxis(): YAXisOption[] {
    const seriesMetaInfo: SeriesMetaInfo[] = [...this.seriesEventsMetaInfo];
    if (this.seriesHelpLineMetaInfo) {
      seriesMetaInfo.push(this.seriesHelpLineMetaInfo);
    }
    const unitsWithMinMax: Map<DataUnit, MinMax> = new Map();
    seriesMetaInfo.forEach((seriesMetaInfo: SeriesMetaInfo) => {
      const entry: MinMax = unitsWithMinMax.get(seriesMetaInfo.unit);
      if (entry) {
        entry.min = Math.min(entry.min, seriesMetaInfo.min);
        entry.max = Math.max(entry.max, seriesMetaInfo.max);
      } else {
        unitsWithMinMax.set(seriesMetaInfo.unit, {min: seriesMetaInfo.min, max: seriesMetaInfo.max});
      }
    });
    const alignedUnitsWithMinMax: Map<DataUnit, MinMax> = GraphScaleAligner.alignedUnitsWithMinMax(unitsWithMinMax);
    const yaxis: YAXisOption[] = [];
    let offset: number = 0;
    alignedUnitsWithMinMax.forEach((minMax: MinMax, unit: DataUnit) => {
      const showSplitLine: boolean = (offset === 0);
      yaxis.push(MoostRulesDataGraphAxis.getYAxis(unit, minMax.min, minMax.max, offset, showSplitLine));
      offset += `${(minMax.max - minMax.min).toFixed(2)}`.length * 12;
    });
    // notifications
    yaxis.push(MoostRulesDataGraphAxis.getNotificationYAxis())
    return yaxis;
  }

  formatTooltipValue(value: OptionDataValue): string {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number') {
      return formatNumber(value, this.locale, '1.0-2');
    } else if (value instanceof Date) {
      return value.toString();
    }
  }

  isLoading(): boolean {
    return this.isLoadingEvents || this.isLoadingNotifications;
  }

  private drawDatasetBasedEvents(): void {
    this.eventsSubscription?.unsubscribe();
    if (this.datasets && this.customerBuildingId) {
      const filter: EventTypeSourceFilter[] = this.datasets
        .map((dataset: Dataset) => {
          return {
            types: dataset.event_types,
            sources: dataset.source_types
          };
        });
      this.isLoadingEvents = true;
      this.eventsSubscription = this.eventService.getEventsForCustomerBuilding(
        this.customerBuildingId,
        Math.round(this.startTimeRangeMillis / 1000),
        Math.round(this.endTimeRangeMillis / 1000),
        filter
      ).subscribe({
        next: (events: IEvent[]): void => {
          const groupedEvents = events.reduce((result: any, event: any) => {
            const key: string = event.type + MoostRulesDataGraphComponent.KEY_SEPARATOR + event.source + MoostRulesDataGraphComponent.KEY_SEPARATOR + event.deviceId;
            (result[key] = result[key] || []).push(event);
            return result;
          }, {});
          this.seriesEventsMetaInfo = Object.entries(groupedEvents).map(([key, events]: [string, IEvent[]]) => {
            const keyItems = key.split(':');
            const type: string = keyItems[0];
            const values: number[] = events.map(it => it.value);
            return {
              unit: DataUnit.ofEventType(type),
              id: key,
              min: Math.min(...values) || 0,
              max: Math.max(...values) || 0
            }
          });
          this.seriesEvents = Object.entries(groupedEvents).map(([key, events]: [string, IEvent[]]) => {
            const keyItems = key.split(MoostRulesDataGraphComponent.KEY_SEPARATOR);
            const type: string = keyItems[0];
            const source = keyItems[1];
            const deviceId = keyItems[2];
            return this.buildLineSeriesOptions(type, source, deviceId, events);
          });
          this.updateChart();
        },
        error: (error): void => {
          console.error(error);
          this.hasError = true;
          this.isLoadingEvents = false;
        },
        complete: (): void => {
          this.isLoadingEvents = false;
        }
      });
    }
  }

  private drawSimulatedNotifications(): void {
    this.seriesSimulatedNotifications = this.notificationsToScatterSeriesOptions(this.simulatedNotifications, true);
    this.updateChart();
  }

  private drawHelpLine(): void {
    this.simulationTermSubscription?.unsubscribe();
    this.helpLineError = "";
    if (this.helpLineTerm) {
      this.isHelpLineLoading = true;
      this.simulationTermSubscription = this.rulesService.evaluateSimulationTerm(
        this.helpLineTerm,
        this.datasets,
        this.customerBuildingId,
        Math.round(this.startTimeRangeMillis / 1000),
        Math.round(this.endTimeRangeMillis / 1000)
      ).subscribe({
        next: (dataSeries: Map<string, DataPoint[]>): void => {
          let helpLineMin: number = 0;
          let helpLineMax: number = 0;
          Object.values(dataSeries)
            .forEach((dataPoints: DataPoint[]) => {
              const values: number[] = dataPoints.map(it => it.value);
              if (values.length > 0) {
                helpLineMin = Math.min(helpLineMin, ...values);
                helpLineMax = Math.max(helpLineMin, ...values);
              }
            });
          this.seriesHelpLineMetaInfo = {
            unit: this.helpLineUnit,
            id: 'HelpLine',
            min: helpLineMin,
            max: helpLineMax,
          };
          this.seriesHelpLine = Object.keys(dataSeries)
            .sort()
            .map((seriesName: string) => {
              return {
                name: this.buildSeriesNameForHelpLine(seriesName),
                color: this.helpLineColor,
                type: 'line',
                symbolSize: 1,
                lineStyle: {
                  type: 'dotted',
                },
                yAxisId: MoostRulesDataGraphAxis.getYAXisId(this.helpLineUnit),
                data: dataSeries[seriesName]
                  .sort((a: DataPoint, b: DataPoint) => a.timestamp - b.timestamp)
                  .map((dataPoint: DataPoint) => {
                    return [new Date(dataPoint.timestamp * 1000), dataPoint.value]
                  })
              };
            });
          this.updateChart();
        },
        error: (error): void => {
          this.helpLineError = "The evaluation of the help term failed.";
          console.error(error);
          this.isHelpLineLoading = false;
        },
        complete: (): void => {
          this.isHelpLineLoading = false;
        }
      })
    }
  }

  private buildSeriesNameForHelpLine(seriesName: string): string {
    seriesName = seriesName.charAt(0).toUpperCase() + seriesName.substring(1);
    return this.helpLineNamePrefix + ' - ' + seriesName.replace(':', ' - ');
  }

  private drawNotifications(): void {
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }

    if (this.ruleId && this.customerBuildingId) {
      this.isLoadingNotifications = true;
      this.notificationsSubscription = this.notificationsService.getNotifications(
        this.startTimeRangeMillis, this.endTimeRangeMillis, this.ruleId, this.customerBuildingId
      ).subscribe({
        next: (pushNotifications: IPushNotification[]): void => {
          this.seriesNotifications = this.notificationsToScatterSeriesOptions(pushNotifications, false);
          this.updateChart();
        },
        error: (error): void => {
          console.error(error);
          this.hasError = true;
          this.isLoadingNotifications = false;
        },
        complete: (): void => {
          this.isLoadingNotifications = false;
        }
      });
    }
  }

  private buildOptionLegend(series: SeriesOption[]): LegendComponentOption {
    return {
      type: "scroll",
      left: "7%",
      right: "2%",
      width: "90%",
      textStyle: {
        color: "#666666",
        rich: {
          title: {
            fontWeight: "bold",
          },
          subtitle: {
            fontSize: 11,
            padding: [4, 0, 0, 0]
          }
        }
      },
      data: series.map((series: SeriesOption): string => {
        return series.name.toString();
      }),
      formatter: (seriesName: string): string => {
        const splitSeriesName: string[] = seriesName.split(" - ");
        if (splitSeriesName.length == 2) {
          return `{title|${splitSeriesName[0]}} \n{subtitle|${splitSeriesName[1]}}`;
        } else {
          return `{title|${splitSeriesName[0]}} \n{subtitle|${splitSeriesName[1]}} \n{subtitle|${splitSeriesName[2]}}`;
        }
      }
    };
  }

  private updateChart(): void {
    const series: SeriesOption[] = [
      ...this.seriesSimulatedNotifications,
      ...this.seriesNotifications,
      ...this.seriesHelpLine,
      ...this.seriesEvents,
    ];
    this.optionsSubject.next({
      legend: this.buildOptionLegend(series),
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          zlevel: -1
        }
      ],
      grid: {
        left: '5%',
        right: '2%',
        bottom: '2%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value: OptionDataValue | OptionDataValue[]): string => {
          if (Array.isArray(value)) {
            return value.map((it: OptionDataValue) => this.formatTooltipValue(it)).join(', ');
          } else {
            return this.formatTooltipValue(value);
          }
        }
      },
      xAxis: {
        type: 'time'
      },
      yAxis: this.buildOptionYAxis(),
      series: series
    });
  }

  private drawChart(): void {
    this.hasError = false;
    this.simulatedNotifications = [];
    this.drawSimulatedNotifications();
    this.drawNotifications();
    this.drawHelpLine();
    this.drawDatasetBasedEvents();
  }

  private composeNotificationToolTipHtml(pushNotification: IPushNotification, isSimulation: boolean): string {
    if (pushNotification) {
      let messagesHtml: string = '';
      let languages: string[] = Object.keys(pushNotification.notification.texts);
      languages.forEach((lang: string) => {
        const texts = pushNotification.notification.texts[lang];
        messagesHtml += `
            <hr>
            <p style="display: flex; justify-content: space-between; margin: 4px 0;">
              <span style="font-weight:500;">
                  ${texts.title}
              </span>
              <span style="text-align: right; font-weight: 300; margin: 0; font-size: 12px;">
                  ${this.languageService.getLanguageName(lang).toUpperCase()}
              </span>
            </p>
            <p style="width: 500px; margin: 4px 0;">
                ${texts.message}<br/>
            </p>
            <p>
                <span style="margin-right:24px;">${texts.actions.primary.text}</span>
                <span>${texts.actions.secondary.text}</span>
            </p>`;
      });
      if (pushNotification.notification.command) {
        messagesHtml += `
            <hr>
            <p style="display: flex; justify-content: space-between; margin: 4px 0;">
              <span style="font-weight:500;">
                  Command
              </span>
            </p>
            <p style="width: 500px; margin: 4px 0;">
                ${pushNotification.notification.command}
            </p>`;
      }
      return `<div style="width: 500px;word-break: break-word;white-space: normal; margin:16px;">
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span style="font-size:20px; font-weight:lighter;">
                          ${isSimulation ? "Simulated Notification" : "Notification"}
                      </span>
                      <span style="text-align: right; font-weight: 300; margin: 0; font-size: 12px;">
                          ${new Date(pushNotification.createdAtTimeMillis).toLocaleString()}<br/>
                          ${pushNotification?.delivery_status?.reason ? pushNotification?.delivery_status?.reason : ""}
                      </span>
                  </div>
                  ${messagesHtml}
              </div>`;
    } else {
      console.error("The notification object to display in the tooltip was undefined");
      return "";
    }
  }

  private getNotificationScatterSeriesOption(type: "DELIVERED" | "DROPPED",
                                             notifications: IPushNotification[],
                                             isSimulation: boolean): ScatterSeriesOption {
    let seriesId: string;
    let seriesHexColor: string = ColorPalette.getNotificationDeliveryBasedColor(type, undefined, isSimulation);
    let seriesName: string = `${MoostRulesDataGraphComponent.NOTIFICATIONS_SERIES_NAME} - `
    let yAxisValue: number = isSimulation ? 1.85 : 2;

    if (type === "DELIVERED" && !isSimulation) {
      seriesId = MoostRulesDataGraphComponent.DELIVERED_NOTIFICATIONS_SERIES_ID;
      seriesName += `${notifications.length} ${MoostRulesDataGraphComponent.DELIVERY_STATUS_DELIVERED}`;
    } else if (type === "DROPPED" && !isSimulation) {
      seriesId = MoostRulesDataGraphComponent.DROPPED_NOTIFICATIONS_SERIES_ID;
      seriesName += `${notifications.length} ${MoostRulesDataGraphComponent.DELIVERY_STATUS_DROPPED}`;
    } else if (type === "DELIVERED" && isSimulation) {
      seriesId = MoostRulesDataGraphComponent.SIMULATED_DELIVERED_NOTIFICATIONS_SERIES_ID;
      seriesName += `${notifications.length} ${MoostRulesDataGraphComponent.DELIVERY_STATUS_DELIVERED} - Simulated`;
    } else if (type === "DROPPED" && isSimulation) {
      seriesId = MoostRulesDataGraphComponent.SIMULATED_DROPPED_NOTIFICATIONS_SERIES_ID;
      seriesName += `${notifications.length} ${MoostRulesDataGraphComponent.DELIVERY_STATUS_DROPPED} - Simulated`;
    }

    return {
      id: seriesId,
      name: seriesName,
      color: seriesHexColor,
      type: 'scatter',
      symbol: this.svgPathLetterIcon,
      symbolSize: 20,
      symbolKeepAspect: true,
      itemStyle: {
        borderWidth: 0
      },
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (d) => this.composeNotificationToolTipHtml(d.data.notification, isSimulation)
      },
      yAxisId: MoostRulesDataGraphAxis.getNotificationYAXisId(),
      data: notifications.map((notification: IPushNotification): any => {
        return {
          name: notification.pushNotificationId,
          value: [new Date(notification.createdAtTimeMillis), yAxisValue],
          notification: notification
        }
      })
    }
  }

  protected readonly DataUnit = DataUnit;
}

class SeriesMetaInfo {
  unit: DataUnit;
  id: string;
  min: number;
  max: number;
}

