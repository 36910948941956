import {TermItem} from "./term-item";

export class EvaluationContext {
  variables: Map<string, TermItem> = new Map();

  setVariable(name: string, value: TermItem): EvaluationContext {
    this.variables.set(name, value);
    return this;
  }
}
