import { Component, Input, OnChanges, OnDestroy, SimpleChanges, inject } from '@angular/core';
import {NotificationsFilter} from '../notifications.models';
import {BehaviorSubject, EMPTY, Observable, Subscription} from 'rxjs';
import {CountByRule, NotificationsService} from '../notifications.service';
import {catchError, finalize} from 'rxjs/operators';
import {ColorPalette} from '../../shared-module/color-palette';

@Component({
    selector: 'app-moost-notifications-per-rule-chart',
    templateUrl: './moost-notifications-per-rule-chart.component.html',
    standalone: false
})
export class MoostNotificationsPerRuleChartComponent implements OnChanges, OnDestroy {
  private notificationsService = inject(NotificationsService);

  @Input() filter: NotificationsFilter;
  @Input() title: string;
  isLoading: boolean;
  subscription: Subscription;
  private chartOptionsSubject: BehaviorSubject<{}> = new BehaviorSubject({});
  chartOptions$: Observable<{}> = this.chartOptionsSubject.asObservable();

  ngOnChanges(changes: SimpleChanges): void {
    this.loadCountByRules();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  applyOnChart(counts: CountByRule[]): void {
    if (counts) {
      const chartData: any[] = [];
      counts.forEach((entry: CountByRule) => {
        chartData.push({name: this.filter.getRuleNameIfKnown(entry.ruleId), value: entry.count});
      });
      const chartColor: string[] = ColorPalette.getStandardColorPalette(chartData.length);
      this.chartOptionsSubject.next({
        tooltip: {
          trigger: 'item'
        },
        series: [{
          type: 'pie',
          avoidLabelOverlap: true,
          padAngle: 2,
          minAngle: 4,
          radius: ['30%', '60%'],
          data: chartData,
          color: chartColor,
          label: {
            show: true,
            position: 'outside',
            overflow: 'truncate',
            bleedMargin: '10',
            formatter: '{b} \n {d}%'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      });
    }
  }

  private loadCountByRules(): void {
    if (this.filter) {
      this.isLoading = true;
      this.subscription = this.notificationsService.getCountByRules(this.filter)
        .pipe(
          catchError(() => EMPTY),
          finalize(() => this.isLoading = false)
        )
        .subscribe((counts: CountByRule[]) => this.applyOnChart(this.reduceRuleSet(counts, 8)));
    }
  }

  private reduceRuleSet(counts: CountByRule[], maxRules: number): CountByRule[] {
    if (counts.length > maxRules) {
      const sortedCounts: CountByRule[] = counts.sort((a: CountByRule, b: CountByRule): number => {
        return b.count - a.count
      });
      const reducedCounts: CountByRule[] = sortedCounts.slice(0, maxRules);
      reducedCounts.push({
        ruleId: "Others",
        count: sortedCounts.slice(maxRules)
          .map((it: CountByRule) => it.count)
          .reduce((sum: number, p: number) => sum + p)
      });
      return reducedCounts;
    } else {
      return counts;
    }
  }
}
