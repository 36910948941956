<form (ngSubmit)="onSubmit()" [formGroup]="settingsForm" autocomplete="off">

  <p>Edit fulfillment settings</p>

  <div *ngIf="type === 'REST'">
    <input formControlName="type"
           hidden="hidden"
           matInput
           type="text"
           value="REST">
    <div class="form-container">
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Choose an auth strategy</mat-label>
        <mat-select formControlName="restAuthStrategy">
          <mat-option
            (onSelectionChange)="changeAuthStrategy($event)"
            *ngFor="let restAuthStrategy of FulfillmentPreference.REST_AUTH_STRATEGY_CHOICE"
            [value]="restAuthStrategy">
            {{ restAuthStrategy }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_APITOKEN"
                      appearance="outline" class="form-item">
        <mat-label>Enter an API token</mat-label>
        <input formControlName="restApiToken" matInput type="text">
        <mat-icon class="material-icons-outlined" matSuffix>settings</mat-icon>
      </mat-form-field>
      <mat-form-field *ngIf="restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BEARER" appearance="outline"
                      class="form-item">
        <mat-label>Enter a bearer token</mat-label>
        <input formControlName="restBearerToken" matInput type="text">
        <mat-icon class="material-icons-outlined" matSuffix>settings</mat-icon>
      </mat-form-field>
    </div>
    <div *ngIf="restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BASIC" class="form-container">
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Enter a basic auth user</mat-label>
        <input formControlName="restBasicAuthUser" matInput type="text">
        <mat-icon class="material-icons-outlined" matSuffix>person</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Enter a basic auth password</mat-label>
        <input formControlName="restBasicAuthPassword" matInput type="text">
        <mat-icon class="material-icons-outlined" matSuffix>password</mat-icon>
      </mat-form-field>
    </div>
    <div class="form-container">
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Enter a hostname</mat-label>
        <input formControlName="restHostname" matInput type="text">
        <mat-icon class="material-icons-outlined" matSuffix>display_settings</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Enter a path</mat-label>
        <input formControlName="restPath" matInput type="text">
        <mat-icon class="material-icons-outlined" matSuffix>network_ping</mat-icon>
      </mat-form-field>
    </div>
    <div class="form-container">
      <mat-slide-toggle (change)="isSecureToggle($event)"
                        [checked]="restIsSecure"
                        class="form-item"
                        color="primary"
                        formControlName="restIsSecure">is secure
      </mat-slide-toggle>
    </div>
  </div>

  <div *ngIf="type === 'EMAIL'">
    <div class="form-container">
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Enter an email address</mat-label>
        <input autocomplete="false"
               formControlName="recipientEmailAddress"
               matInput
               placeholder="hans.muster@moost.io"
               type="email">
        <mat-icon class="material-icons-outlined" matSuffix>email</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Enter a building Id</mat-label>
        <input autocomplete="false"
               formControlName="customerBuildingId"
               matInput
               type="text">
        <mat-icon class="material-icons-outlined" matSuffix>apartment</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="type === 'FIREBASE_CLOUD_MESSAGING'">
    <input formControlName="type"
           hidden="hidden"
           matInput
           type="text"
           value="FIREBASE_CLOUD_MESSAGING">
    <div class="form-container">
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Enter a valid FCM Token</mat-label>
        <input formControlName="fcmToken" matInput type="text">
        <mat-icon class="material-icons-outlined" matSuffix>token</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-item">
        <mat-label>Enter a building Id</mat-label>
        <input formControlName="customerBuildingId" matInput type="text">
        <mat-icon class="material-icons-outlined" matSuffix>apartment</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div class="button-container">
    <button (click)="hideUpdateForm()" mat-button>CANCEL</button>
    <button [disabled]="!isValidForm()" class="save"
            mat-button>
      SAVE CHANGES
    </button>
  </div>
</form>
