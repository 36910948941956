import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EMPTY, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {EventsFilter} from '../events.models';
import moment from 'moment/moment';
import {EventsService} from '../events.service';
import {catchError, finalize} from 'rxjs/operators';

@Component({
  selector: 'app-moost-events-filter',
  templateUrl: './moost-events-filter.component.html',
  styleUrls: ['./moost-events-filter.component.scss'],
  standalone: false
})

export class MoostEventsFilterComponent implements OnInit, OnDestroy {
  private eventsService = inject(EventsService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  @Input() filter: EventsFilter;
  @Output() applyFilterChange: EventEmitter<EventsFilter> = new EventEmitter<EventsFilter>()
  filterForm: FormGroup;
  sourcesChoice: string[] = [];
  typesChoice: string[] = [];
  maxDate: Date = moment().endOf('day').toDate();
  isLoadingEventTypes: boolean = false;
  isLoadingSourceTypes: boolean = false;
  private loadEventTypesSubscription: Subscription;
  private loadSourceTypesSubscription: Subscription;
  private valueChangesSubscription: Subscription;

  ngOnInit(): void {
    this.buildForm(this.filter);
    this.loadTypes();
    this.loadSources();
  }

  ngOnDestroy(): void {
    this.loadEventTypesSubscription?.unsubscribe();
    this.loadSourceTypesSubscription?.unsubscribe();
    this.valueChangesSubscription?.unsubscribe();
  }

  clearFilter(): void {
    this.resetForm(this.filterForm);
  }

  private setFilter(filter: EventsFilter): void {
    this.applyFilterChange.emit(filter);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          s: filter.startTimestampMillis,
          e: filter.endTimestampMillis,
          building: filter.customerBuildingIds?.join(","),
          source: filter.sources?.join(","),
          type: filter.types?.join(","),
          deviceName: filter.deviceNames?.join(","),
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private loadTypes(): void {
    this.filterForm.get("types").disable();
    this.isLoadingEventTypes = true;
    this.loadEventTypesSubscription = this.eventsService.getEventTypes().pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoadingEventTypes = false)
    ).subscribe((types: string[]): void => {
      this.typesChoice = types;
      this.filterForm.get("types").enable();
    });
  }

  private loadSources(): void {
    this.filterForm.get("sources").disable();
    this.isLoadingSourceTypes = true;
    this.loadSourceTypesSubscription = this.eventsService.getSourceTypes().pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoadingSourceTypes = false)
    ).subscribe((sources: string[]): void => {
      this.sourcesChoice = sources;
      this.filterForm.get("sources").enable();
    });
  }

  private buildForm(filter: EventsFilter): void {
    this.filterForm = new FormGroup({
      customerBuildingIds: new FormControl<string>(filter?.customerBuildingIds?.join(',')),
      sources: new FormControl<string[]>(filter?.sources),
      types: new FormControl<string[]>(filter?.types),
      deviceNames: new FormControl<string>(filter?.deviceNames?.join(',')),
      startTimestampMillis: new FormControl(new Date(Number(filter?.startTimestampMillis)), [Validators.required]),
      endTimestampMillis: new FormControl(new Date(Number(filter?.endTimestampMillis)), [Validators.required]),
    });
    this.filterForm.get("types").disable();
    this.filterForm.get("sources").disable();
    this.valueChangesSubscription = this.filterForm.valueChanges.subscribe(() => {
      if (this.filterForm.valid && this.filterForm.get("sources").enabled && this.filterForm.get("types")) {
        this.setFilter(this.buildEventsFilter(this.filterForm));
      }
    });
  }

  private resetForm(filterForm: FormGroup): void {
    filterForm.get("customerBuildingIds").reset();
    filterForm.get("sources").reset();
    filterForm.get("types").reset();
    filterForm.get("deviceNames").reset();
  }

  private buildEventsFilter(filterForm: FormGroup): EventsFilter {
    return new EventsFilter(
      filterForm.get("startTimestampMillis").value.valueOf(),
      moment(filterForm.get("endTimestampMillis").value.valueOf()).endOf('day').toDate().getTime(),
      filterForm.get("customerBuildingIds").value?.split(","),
      filterForm.get("sources").value,
      filterForm.get("types").value,
      null,
      filterForm.get("deviceNames").value?.split(",")
    );
  }
}
