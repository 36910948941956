import CompletionItemKind = languages.CompletionItemKind;
import CompletionItemProvider = languages.CompletionItemProvider;
import CompletionContext = languages.CompletionContext;
import {CancellationToken, editor, languages, Position} from 'monaco-editor';
import {AttributeAccessor} from '../validator/attribute-accessor';
import {TermFunction} from '../validator/term-function';
import {TermOperator} from '../validator/term-operator';
import {IDataset} from '../../rules.models';
import {RuleLanguage} from './rule-language';
import {TermLiteral} from '../validator/term-literal';

declare const monaco: any;


export class RuleLanguageCompletionItemProvider implements CompletionItemProvider {
  static readonly VARIABLE_PATTERN: RegExp = /[$]$/;
  static readonly ATTRIBUTE_ACCESSOR_PATTERN: RegExp = /[\S]::$/;
  static readonly FUNCTION_OR_OPERATOR_OR_LITERAL_PATTERN: RegExp = /[\w]+$/;

  // hint: no need to add alphanumerical characters, as they always trigger.
  triggerCharacters: string[] | undefined = [':', '$']

  provideCompletionItems(model: editor.ITextModel, position: Position, context: CompletionContext,
                         token: CancellationToken): languages.ProviderResult<languages.CompletionList> {
    const textUntilPosition: string = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    const suggestions: languages.CompletionItem[] = [];

    if (textUntilPosition.match(RuleLanguageCompletionItemProvider.ATTRIBUTE_ACCESSOR_PATTERN)) {
      AttributeAccessor.NAMES.forEach((name: string): void => {
        suggestions.push(
          this.getCompletionItem(name, name,
            monaco.languages.CompletionItemKind.Field, 'Attribute')
        )
      });
    } else if (textUntilPosition.match(RuleLanguageCompletionItemProvider.FUNCTION_OR_OPERATOR_OR_LITERAL_PATTERN)) {
      TermFunction.getNames().forEach((name: string): void => {
        suggestions.push(
          this.getCompletionItem(name, name,
            monaco.languages.CompletionItemKind.Function, 'Function')
        )
      });
      TermOperator.getLogicalOperators().forEach((operator: TermOperator): void => {
        suggestions.push(
          this.getCompletionItem(operator.symbol, operator.symbol,
            monaco.languages.CompletionItemKind.Operator, 'Operator')
        );
      });
      TermLiteral.LITERALS.forEach((literal: TermLiteral): void => {
        suggestions.push(
          this.getCompletionItem(literal.name, literal.name,
            monaco.languages.CompletionItemKind.Keyword, 'Keyword')
        );
      });
    } else if (textUntilPosition.match(RuleLanguageCompletionItemProvider.VARIABLE_PATTERN)) {
      RuleLanguage.EDITOR_CONFIG.variables.forEach((dataset: IDataset): void => {
        suggestions.push(
          this.getCompletionItem(dataset.name, dataset.name,
            monaco.languages.CompletionItemKind.Variable, dataset.type.valueOf())
        );
      });
    }
    return {suggestions: suggestions};
  }

  private getCompletionItem(label: string, insertText: string, kind: CompletionItemKind,
                            detailText: string): languages.CompletionItem {
    return {
      label: label,
      insertText: insertText,
      kind: kind,
      range: null,
      detail: detailText
    }
  }

}
