import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, inject } from '@angular/core';
import {EventsDatasource} from './events.datasource';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {EventsService} from '../events.service';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DataUnit, EventsFilter, IEvent} from '../events.models';

@Component({
    selector: 'app-moost-events-table',
    templateUrl: './moost-events-table.component.html',
    styleUrls: ['./moost-events-table.component.scss'],
    standalone: false
})
export class MoostEventsTableComponent implements OnInit, OnChanges, AfterViewInit {
  private eventsService = inject(EventsService);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() filter: EventsFilter;
  visibleColumns: string[] = ['Timestamp', 'Building', 'Type', 'Source', 'Value', 'Unit', 'Forecast', 'DeviceName'];
  dataSource: EventsDatasource;
  eventsCount: number = 0;
  events: IEvent[];
  protected readonly DataUnit = DataUnit;

  ngOnInit(): void {
    this.dataSource = new EventsDatasource(this.eventsService);
    this.dataSource.length$.subscribe(eventsCount => {
      this.eventsCount = eventsCount;
    });
    this.dataSource.events$.subscribe((events: IEvent[]) => {
      this.events = events;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter || changes.paginator) {
      if (this.paginator && this.filter != null) {
        this.paginator.firstPage();
      }
      this.loadEvents();
    }
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadEvents())
      )
      .subscribe();
  }

  loadEvents(): void {
    if (this.dataSource) {
      this.dataSource.clear();
      this.dataSource.loadEvents(
        this.filter,
        this.sort?.active,
        this.sort?.direction,
        this.paginator?.pageIndex,
        this.paginator?.pageSize);
    }
  }
}
