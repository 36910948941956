import {Component, OnDestroy, OnInit} from '@angular/core';
import {Building, IBuilding} from '../buildings.models';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BuildingsService} from '../buildings.service';
import {Subscription} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {RulesService} from '../../rules-module/rules.service';
import {Rule} from '../../rules-module/rules.models';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {Permission} from '../../auth-token-module/auth-token.models';

@Component({
  selector: 'app-moost-buildings-configuration',
  templateUrl: './moost-buildings-configuration.component.html',
  styleUrls: ['./moost-buildings-configuration.component.scss']
})
export class MoostBuildingsConfigurationComponent implements OnInit, OnDestroy {

  building: Building;
  buildingForm: FormGroup;
  isLoadingBuilding: boolean;
  rules: Rule[];
  isLoadingRules: boolean;
  error: string;
  buildingSubscription: Subscription;
  ruleSubscription: Subscription;
  routeSubscription: Subscription;
  protected readonly Permission = Permission;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private headerService: MoostHeaderService,
              private buildingsService: BuildingsService,
              private rulesService: RulesService,
              protected authTokenService: AuthTokenService) {
    this.headerService.setHeader('Building Configuration', '/buildings');
  }

  ngOnInit(): void {
    this.error = null;
    this.routeSubscription = this.route.params.subscribe({
      next: (params: Params): void => {
        const customerBuildingId: string = params.id;
        if (customerBuildingId) {
          this.loadBuilding(customerBuildingId);
          this.loadRules();
        } else {
          this.error = "No building selected";
        }
      },
      error: (error): void => {
        this.error = "Failed to load building";
      }
    });
  }

  ngOnDestroy(): void {
    this.buildingSubscription?.unsubscribe();
    this.ruleSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
  }

  onSubmit(): void {
    this.error = null;
    const updatedBuilding: IBuilding = JSON.parse(JSON.stringify(this.building));
    updatedBuilding.inactiveRules = this.buildingForm.get("inactiveRulesForm").value;
    updatedBuilding.isEarlyAdopter = this.buildingForm.get("isEarlyAdopterForm").value;
    this.buildingsService.updateBuilding(updatedBuilding).subscribe({
      next: (): void => {
        this.buildingForm.reset();
        this.router.navigate(['/buildings']);
      },
      error: (error): void => {
        this.error = `Cannot save building. Please try later.`;
      }
    });
  }

  private buildBuildingForm(building: IBuilding): FormGroup {
    return new FormGroup({
      inactiveRulesForm: new FormControl<string[]>(building?.inactiveRules),
      isEarlyAdopterForm: new FormControl<boolean>(building?.isEarlyAdopter)
    })
  }

  private loadBuilding(customerBuildingId: string): void {
    this.isLoadingBuilding = true;
    this.buildingSubscription = this.buildingsService.getBuilding(customerBuildingId).subscribe((building: Building): void => {
      this.building = building;
      this.buildingForm = this.buildBuildingForm(building);
      this.isLoadingBuilding = false;
    })
  }

  private loadRules(): void {
    this.isLoadingRules = true;
    this.ruleSubscription = this.rulesService.getAllRules().pipe(
      catchError(() => this.error = "Failed to load rules"),
      finalize(() => this.isLoadingRules = false)
    ).subscribe((rules: Rule[]): void => {
      this.rules = rules;
    });
  }
}
