import {Component, inject} from '@angular/core';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {NotificationsFilter} from '../notifications.models';

@Component({
  selector: 'app-moost-notifications-overview',
  templateUrl: './moost-notifications-overview.component.html',
  styleUrls: ['./moost-notifications-overview.component.scss'],
  standalone: false
})
export class MoostNotificationsOverviewComponent {
  private headerService: MoostHeaderService = inject(MoostHeaderService);

  filter: NotificationsFilter;

  constructor() {
    this.headerService.setTitle('Notifications');
  }

  applyFilter($event: NotificationsFilter): void {
    this.filter = $event;
  }
}
