<div class="page-content">
  <h2 class="title">Fulfillment preferences</h2>
  <div class="add-button-container">
    <button (click)="add()" [disabled]="!authTokenService.hasPermission(Permission.WRITE_CUSTOMERS)" color="primary"
            mat-flat-button>
      ADD PREFERENCE
    </button>
  </div>
  <app-moost-settings-card></app-moost-settings-card>
</div>
