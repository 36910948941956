import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MoostNotificationsOverviewComponent
} from './moost-notifications-overview/moost-notifications-overview.component';
import {NotificationsRoutingModule} from './notifications-routing.module';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {SharedModule} from '../shared-module/shared.module';
import {MoostNotificationsTableComponent} from './moost-notifications-table/moost-notifications-table.component';
import {BrowserModule} from '@angular/platform-browser';
import {MatSortModule} from '@angular/material/sort';
import {NgxEchartsModule} from 'ngx-echarts';
import {MoostNotificationsChartsComponent} from './moost-notifications-charts/moost-notifications-charts.component';
import {MoostChartsModule} from '../moost-charts/moost-charts.module';
import {MoostNotificationsHeatmapComponent} from './moost-notifications-heatmap/moost-notifications-heatmap.component';
import {MoostNotificationsFilterComponent} from './moost-notifications-filter/moost-notifications-filter.component';
import {ReactiveFormsModule} from "@angular/forms";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from '@angular/material/datepicker';
import {
  MoostNotificationsInteractionRateComponent
} from './moost-notifications-interaction-rate-chart/moost-notifications-interaction-rate.component';
import {
  MoostNotificationsPerRuleChartComponent
} from './moost-notifications-per-rule-chart/moost-notifications-per-rule-chart.component';
import {
  MoostNotificationsPerInteractionChartComponent
} from './moost-notifications-per-interaction-chart/moost-notifications-per-interaction-chart.component';
import {
  MoostNotificationsPerDeliveryStatusChartComponent
} from './moost-notifications-per-delivery-status-chart/moost-notifications-per-delivery-status-chart.component';

@NgModule({
  declarations: [
    MoostNotificationsOverviewComponent,
    MoostNotificationsTableComponent,
    MoostNotificationsChartsComponent,
    MoostNotificationsInteractionRateComponent,
    MoostNotificationsPerRuleChartComponent,
    MoostNotificationsPerInteractionChartComponent,
    MoostNotificationsPerDeliveryStatusChartComponent,
    MoostNotificationsHeatmapComponent,
    MoostNotificationsFilterComponent,
  ],
  imports: [
    BrowserModule,
    MatMomentDateModule,
    NotificationsRoutingModule,
    CommonModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatCardModule,
    SharedModule,
    NgxEchartsModule,
    MoostChartsModule,
    ReactiveFormsModule,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatStartDate
  ],
  exports: [
    MoostNotificationsOverviewComponent,
    MoostNotificationsTableComponent,
    MoostNotificationsChartsComponent,
    MoostNotificationsHeatmapComponent
  ]
})
export class NotificationsModule {
}
