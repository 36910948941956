import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IEchartLoadingOptions} from '../moost-charts.models';
import {EChartsOption, EChartsType} from 'echarts/types/dist/echarts';
import {ColorPalette} from '../../shared-module/color-palette';

@Component({
    selector: 'app-moost-base-chart',
    templateUrl: './moost-base-chart.component.html',
    styleUrls: ['./moost-base-chart.component.scss'],
    standalone: false
})
export class MoostBaseChartComponent implements OnChanges {
  @Input() title: string;
  @Input() isLoading: boolean;
  @Input() hasError: boolean = false;
  @Input() options: EChartsOption;

  chart: EChartsType;

  public loadingOptions: IEchartLoadingOptions = {
    color: ColorPalette.PRIMARY,
    maskColor: '#FAFAFA',
    text: 'Loading...'
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading && !changes.isLoading.firstChange) {
      if (this.chart) {
        if (changes.isLoading.currentValue) {
          this.chart.showLoading('default', this.loadingOptions);
        } else {
          this.chart.hideLoading();
        }
      }
    }
  }

  initCharts(chart: any): void {
    this.chart = chart;
    if (this.isLoading) {
      this.chart.showLoading('default', this.loadingOptions);
    }
  }
}
