import {Component, inject} from '@angular/core';
import {AuthTokenService} from '../auth-token.service';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  standalone: false
})
export class LogoutButtonComponent {
  private authTokenService = inject(AuthTokenService);

  handleLogout(): void {
    this.authTokenService.logout();
  }
}
